import { useAppContext } from "PFApp/app_context";
import { Template } from "PFTypes";
import { useMemo } from "react";

export const useTemplateConfiguration = (template: Pick<Template, "key"> | undefined) => {
  const {
    store: { templatesConfiguration }
  } = useAppContext();

  return useMemo(
    // eslint-disable-next-line camelcase
    () => templatesConfiguration.find(({ template_key }) => template_key === template?.key),
    [template?.key, templatesConfiguration]
  );
};
