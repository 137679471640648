import classNames from "classnames";
import { useGrowl } from "PFApp/use_growl";
import { IconSize } from "PFComponents/icon";
import { ExperienceLevel } from "PFCore/components/experience_level";
import { fetchProfileCustomValue } from "PFCore/services/custom_values";
import { CustomType, CustomValue, Experience, ProfileCustomValue } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCustomValueInvalidate } from "../../hooks/queries/custom_fields/use_custom_value_invalidate";
import { useDictionaryConnectionCreate } from "../../hooks/queries/custom_values/use_dictionary_connection_create";
import { useCurrentProfile, useCurrentProfileSet } from "../../hooks/queries/profile";
import { useErrorsGrowl } from "../../hooks/use_errors_growl";
import { DropdownOption } from "../dropdown/dropdown";
import { DropdownButton } from "../dropdown_button";
import Tooltip from "../tooltip/tooltip";
import css from "./custom_values_list.module.scss";

type RatingIconProps = {
  isEditMode?: boolean;
  customType: CustomType;
  customValue: ProfileCustomValue;
  onRateChange?: (customValue?: CustomValue) => void;
  color?: string;
  size?: IconSize;
};

export const RatingIcon = ({
  customType,
  customValue,
  isEditMode,
  onRateChange,
  color,
  size
}: RatingIconProps) => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });
  const growl = useGrowl();
  const growlError = useErrorsGrowl();

  const [loading, setLoading] = useState(false);

  const { data: currentProfile } = useCurrentProfile();
  const setCurrentProfile = useCurrentProfileSet();
  const { invalidateCustomValueProfileScope } = useCustomValueInvalidate();
  const { mutateAsync: createDictionaryConnection } = useDictionaryConnectionCreate();

  const experience = customValue.experience || customValue.suggested_experience;

  const isSuggested = !experience && !customValue.top && !customValue.developmental && !customValue.rare;

  const updateCurrentProfileCustomValues = (fullCustomValue: CustomValue, newExperience: Experience) => {
    const customValueIndex = currentProfile[customType.name].findIndex(
      (profileCustomValue) => fullCustomValue.id === profileCustomValue.id
    );
    const profileCustomValues = [...currentProfile[customType.name]];
    if (customValueIndex >= 0) {
      profileCustomValues[customValueIndex].experience = newExperience;
    } else {
      profileCustomValues.push({ ...fullCustomValue, experience: newExperience });
    }

    setCurrentProfile({ ...currentProfile, [customType.name]: profileCustomValues });
  };

  const handleWeightChange = (experience: Experience) => {
    setLoading(true);
    createDictionaryConnection({
      experience,
      interest: true,
      source: isSuggested ? "skill_suggestion" : null,
      customValueId: customValue.id
    })
      .then(() => fetchProfileCustomValue(Number(customValue.id), currentProfile.id))
      .then((fullCustomValue) => {
        updateCurrentProfileCustomValues(fullCustomValue, experience);
        invalidateCustomValueProfileScope(customValue.id, currentProfile.id);
        onRateChange && onRateChange(fullCustomValue);
        setLoading(false);
        growl({ kind: "success", message: t("experienceEditSuccessMessage") });
      })
      .catch(({ response }) => growlError(response));
  };

  const updateExperience = (experience: Experience) => {
    if (isEditMode) {
      handleWeightChange(experience);
    }
  };

  if (isEditMode && isSuggested) {
    const options: DropdownOption[] = [
      { id: "basic", displayElement: t("basic"), item: Experience.Basic },
      { id: "intermediate", displayElement: t("intermediate"), item: Experience.Intermediate },
      { id: "advanced", displayElement: t("advanced"), item: Experience.Advanced }
    ];

    return (
      <Tooltip theme="pf-dark-blue" content={t("prompt")}>
        <DropdownButton
          options={options}
          aria-label={t("addCustomValueExperience", {
            name: String(customValue.text || customValue.value || "")
          })}
          small
          handleChange={updateExperience}
          disabled={loading}
          actionIcon="add"
        />
      </Tooltip>
    );
  }

  return experience ? (
    <ExperienceLevel
      customValue={customValue}
      className={classNames({ [css.disabled]: loading, [css.weightButton]: isEditMode })}
      onChange={isEditMode ? updateExperience : undefined}
      experience={experience}
      color={color}
      size={size}
    />
  ) : null;
};
