import { GenericActivityEvent } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useEventProfiles } from "../../../hooks/use_event_profiles";
import { useTranslatedActivityType } from "../../../hooks/use_translated_activity_type";
import { HistoryEntryProfilesAccordion } from "../components/histor_entry_profiles_accordion";
import { HistoryEntryTemplate } from "../components/history_entry_template";
import { HistoryEntrySubject } from "../components/history_event_subject";
import { HistoryUpdatedAttributes } from "../components/history_updated_attributes/history_updated_attributes";
import { HistoryEntryProps } from "../history_entry";

export const HistoryEntryGeneric = ({ entry }: HistoryEntryProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history" });
  const { targetProfiles, targetProfile, isLoading } = useEventProfiles(entry);
  const activityType = useTranslatedActivityType();

  const badge = t(`entry.type.${entry.event}` as unknown as TemplateStringsArray, { activityType });

  const renderHistoryContent = () => {
    if (targetProfiles && targetProfiles?.length > 1) {
      return (
        <HistoryEntryProfilesAccordion
          profiles={targetProfiles}
          badge={`${badge} (${targetProfiles.length})`}
        />
      );
    } else if (targetProfile) {
      return <HistoryEntrySubject badge={badge} profile={targetProfile} isLoading={isLoading} />;
    }

    return <HistoryUpdatedAttributes updatedAttributes={entry.diff as GenericActivityEvent["diff"]} />;
  };

  return <HistoryEntryTemplate entry={entry}>{renderHistoryContent()}</HistoryEntryTemplate>;
};
