import Tooltip from "PFComponents/tooltip/tooltip";
import WarningIcon from "PFIcons/warning.svg";
import { useTranslation } from "react-i18next";

import css from "./shortlist_item_buttons.module.scss";

const ExceptionsWarning = () => {
  const { t } = useTranslation("auditPlanner");
  return (
    <Tooltip content={t("modals.actions.undoMessage")}>
      <span className={css.exceptionIcon}>
        <WarningIcon fill="var(--paletteRed1)" width={25} height={25} viewBox="2 2 20 15" />
      </span>
    </Tooltip>
  );
};

export default ExceptionsWarning;
