import { decamelizeKeys } from "humps";
import { useActivityPageVacancies } from "PFApp/activities/show/hooks/use_activity_page_vacancies";
import { useGrowl } from "PFApp/use_growl";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import { Select } from "PFComponents/select/select";
import Toggle from "PFComponents/toggle/toggle";
import Tracking from "PFCore/helpers/tracking";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { closeActivity } from "PFCore/services/activities";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { humanize } from "underscore.string";

import css from "./close_confirm_modal.module.scss";

const CloseConfirmModal = ({ activity: activityData, handleClose, type, handleSuccess, t }) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const growl = useGrowl();
  const { mutateAsync: resetShortlist } = useShortlistReset({}, false);

  const [selectedReason, setSelectedReason] = useState(null);
  const [deleteBookings, setDeleteBookings] = useState(false);
  const activity = decamelizeKeys(activityData); // TODO: [SP-2511] activityData might come from different endpoints, camelized or not

  const filledOrBooked = activity.workflow_state === "booked" || activity.workflow_state === "filled";

  const template = currentProfile.templates.find(
    (template) => template.id === activity.template_id || template.key === type
  );

  const { vacancies, loaded } = useActivityPageVacancies({ template_id: template.id, ...activity });
  const { data: shortlists, isFetching: isShortlistsFetching } = useActivityShortlists(
    activity.id,
    { source: "close_modal" }, // to not trigger refreshing shortlists list when opening the modal
    {
      enabled: !!activity.id && filledOrBooked && isAuditRole
    }
  );

  const closeOptions = useMemo(
    () =>
      (vacancies.meta.availableClosureReasons || [])
        .filter(({ hidden, massClose }) => !hidden && massClose !== false)
        .map((option) => ({
          id: option.key,
          displayElement: option.label,
          item: option.key
        })),
    [vacancies.meta]
  );

  const closeReasons = useMemo(
    () =>
      vacancies?.meta?.availableClosureReasons?.reduce(
        (acc, val) => ({
          ...acc,
          [val.key]: val.label
        }),
        {}
      ) || {},
    [vacancies.meta]
  );

  const activityType = humanize(template.key).toLowerCase();
  const isRoleOrAuditRole = ["role", "audit_role"].includes(template.key);
  const isAuditRole = template.key === "audit_role";
  const openVacancies = vacancies.meta.totalOpened;
  const hasOpenVacancies = isRoleOrAuditRole && loaded && openVacancies > 0;

  const closeVacanciesAndActivity = async () => {
    try {
      if (deleteBookings) {
        await Promise.all(shortlists?.entries.map((shortlist) => resetShortlist(shortlist.id)));
      }
      await closeActivity(activity.id, selectedReason);

      growl({ message: t("closeActivity.success") });
      //add the tracking
      new Tracking(currentAccount, currentProfile.id).recordEvent("activity-list-close-task", {
        activity_id: activity.id
      });
      handleSuccess && handleSuccess();
    } catch {
      growl({ message: t("closeActivity.fail"), kind: "error" });
    }
  };

  const isSubmitDisabled =
    (isAuditRole && hasOpenVacancies && !closeReasons[selectedReason]) || isShortlistsFetching;

  return loaded || !isRoleOrAuditRole ? (
    <Modal
      title={t("closeActivity.close", { activityType })}
      onOK={isSubmitDisabled ? false : closeVacanciesAndActivity}
      onClose={handleClose}
      labelOK={
        hasOpenVacancies && isRoleOrAuditRole
          ? t("closeActivity.goToVacancies", {
              activityType,
              count: openVacancies
            })
          : t("closeActivity.close", { activityType })
      }
      kindOK="danger"
      showCancelButton={false}
      classes={{
        modal: css.modal
      }}
    >
      {hasOpenVacancies && (
        <div className={css.header}>
          {t(`closeActivity.with_vacancies.title.${template.key}`, {
            count: openVacancies
          })}
        </div>
      )}
      <div className={css.text}>
        {hasOpenVacancies ? (
          <Trans
            i18nKey={`closeActivity.with_vacancies.text.${template.key}`}
            t={t}
            components={[
              <Link key={0} onClick={() => handleClose()} to={`/activities/${activity.id}/vacancies`} />
            ]}
          />
        ) : (
          t("closeActivity.text", { activityType })
        )}
        {hasOpenVacancies && isRoleOrAuditRole && (
          <>
            <div className={css.actions}>
              <div className={css.reasons}>{t("closeActivity.reason")}</div>
            </div>
            <Select
              className={css.selectReason}
              options={closeOptions}
              value={closeReasons[selectedReason]}
              controlledValue
              onChange={(selected) => setSelectedReason(selected)}
            />
          </>
        )}
        {filledOrBooked && isAuditRole && (
          <div>
            {isShortlistsFetching && <LoadingDots />}
            {!isShortlistsFetching && !!shortlists && (
              <>
                <div className={css.bookedOrFilled}>
                  {t("closeActivity.bookedOrFilled", { activityType })}
                </div>
                <div className={css.toggleWrapper}>
                  <div>{t("no")}</div>
                  <Toggle inline checked={deleteBookings} onChange={setDeleteBookings} />
                  <div>{t("yes")}</div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  ) : null;
};

CloseConfirmModal.propTypes = {
  activity: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  handleSuccess: PropTypes.func,
  type: PropTypes.string,
  t: PropTypes.func
};

export default withTranslation("activities")(CloseConfirmModal);
