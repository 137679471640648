import { BookingRequirementData, RequirementType } from "PFTypes";

export type Requirement = {
  type: RequirementType;
  value?: number;
};

const toIntegerMinutes = (valueInHours: number) => Math.round(valueInHours * 60);

export const isSomeRequirementInvalid = (data: { requirement?: Requirement }[]) =>
  data.some(({ requirement }) => !requirement?.value);

export const mapRequirementToBookingData = ({ type, value }: Requirement): BookingRequirementData => ({
  requirement_type: type, //eslint-disable-line camelcase
  requirement_value: type !== RequirementType.Load ? toIntegerMinutes(value ?? 0) : value ?? 0 //eslint-disable-line camelcase
});
