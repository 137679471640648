import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAssignee } from "../hooks/use_assignee";
import { useOwners } from "../hooks/use_owners";
import { useReviewers } from "../hooks/use_reviewers";
import { AddActivityProfilesModal } from "./add_activity_profiles_modal";

export const AddActivityProfilesButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("activities");
  const { task: activity } = useActivityPageContext();

  const { canWrite: canWriteOwners } = useOwners(activity);
  const { canWrite: canWriteAssignee } = useAssignee(activity);
  const { canWrite: canWriteReviewers } = useReviewers(activity);

  const canAddProfiles = canWriteOwners || canWriteReviewers || canWriteAssignee;

  if (!canAddProfiles) {
    return null;
  }

  return (
    <>
      <Tooltip content={t("show.addProfiles.title_other")}>
        <div>
          <Button icon="owner" kind="secondary" onClick={() => setIsModalOpen(true)} />
        </div>
      </Tooltip>
      {isModalOpen && <AddActivityProfilesModal onClose={() => setIsModalOpen(false)} activity={activity} />}
    </>
  );
};
