import { orderBy, take } from "lodash";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { Button } from "PFComponents/button";
import { Inline } from "PFComponents/containers";
import { SkillPill } from "PFComponents/skill_pill";
import { isRankable } from "PFCore/helpers/custom_type";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { ActivityCustomValue, ActivityPureCustomValue, CustomValueScope, Id } from "PFTypes";
import { useTranslation } from "react-i18next";

import { CustomValuesListCommonProps, DEFAULT_LIMIT } from "./custom_values_list.consts";
import css from "./custom_values_list.module.scss";
import { CustomValuesDivider } from "./parts/custom_values_divider";
import { useSkillsVisibility } from "./use_skills_visibility";

type ActivityCustomValuesListProps = CustomValuesListCommonProps & {
  customValues: (ActivityCustomValue | ActivityPureCustomValue)[];
  activityId: Id;
};

export const ActivityCustomValuesList = ({
  customFieldName,
  customValues,
  activityId,
  limit = DEFAULT_LIMIT,
  canEdit = false
}: ActivityCustomValuesListProps) => {
  const { t } = useTranslation("core");
  const { getCustomTypeByName } = useCustomTypes();
  const { showPreview } = useCustomValuePreviewActions();

  const sortedCustomValues = orderBy(customValues, "value");

  // TODO: [SP-2674] Move shared parts to a wrapper component
  const { visibleAvailableSkillsCount, showAll, displayShowMoreButton, toggleShowAll } = useSkillsVisibility({
    availableSkillsCount: sortedCustomValues.length,
    missingSkillsCount: 0,
    limit
  });

  const customType = getCustomTypeByName(customFieldName);
  const isCustomTypeRankable = Boolean(customType && isRankable(customType));

  return (
    <Inline wrap="wrap" columnGap="spacingMd" rowGap="spacingLg">
      {take(sortedCustomValues, visibleAvailableSkillsCount).map((customValue, index) => {
        const isNotLastSkill = index < visibleAvailableSkillsCount - 1;

        return (
          <span className={css.skillWithDivider} key={customValue.id}>
            <SkillPill
              isRequirement
              experience={isCustomTypeRankable ? customValue.required_experience : undefined}
              name={String(customValue.value)}
              onClick={() => {
                showPreview({
                  scope: CustomValueScope.Activity,
                  customTypeId: customType!.id,
                  customValueId: customValue.id,
                  activityId,
                  canEdit
                });
              }}
            />
            {isNotLastSkill && <CustomValuesDivider />}
          </span>
        );
      })}
      {displayShowMoreButton && (
        <Button
          kind="text"
          onClick={(event) => {
            event.stopPropagation();
            toggleShowAll();
          }}
        >
          {showAll ? t("viewLess") : t("viewMore")}
        </Button>
      )}
    </Inline>
  );
};
