import classNames from "classnames";
import { camelizeKeys } from "humps";
import { isString, toString } from "lodash";
import { Flex } from "PFComponents/containers/flex";
import { IconSize, IconSizeTable } from "PFComponents/icon";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { Typography } from "PFComponents/typography";
import { getJobTitle, getProfileName } from "PFCore/helpers/profile";
import { BasicProfile, Profile, ProfileMinimized } from "PFTypes";

import css from "./profile_item.module.scss";

type ProfileItemProps = {
  className?: string;
  profile: BasicProfile | Profile;
  style?: React.CSSProperties;
  avatarSize?: IconSize;
  displayNameOnly?: boolean;
  displayAvatar?: boolean;
  displayJobTitle?: boolean;
};

const calculateJobTitle = (profile: BasicProfile | Profile): string => {
  const { jobTitle } = profile as BasicProfile;
  const { positions } = profile as Profile;

  if (isString(jobTitle)) {
    return jobTitle;
  }

  if (positions) {
    return toString(getJobTitle(profile as Profile));
  }

  return "";
};

export const ProfileItem = ({
  className,
  profile,
  style,
  avatarSize,
  displayNameOnly,
  displayAvatar = true,
  displayJobTitle = true
}: ProfileItemProps): JSX.Element => {
  const camelizedProfile = camelizeKeys(profile) as BasicProfile | Profile; // TODO: [PROF-5746] Remove "camelizeKeys" when all ProfileItem usages get camelized profile prop
  const { text } = camelizedProfile as BasicProfile;
  const name = text || getProfileName(camelizedProfile);
  const calculatedJobTitle = calculateJobTitle(camelizedProfile);
  const title = `${name} ${camelizedProfile.email}`;

  return (
    <Flex
      className={classNames(css.root, className)}
      alignItems="center"
      justifyContent="start"
      style={style}
      title={title}
    >
      {profile.avatar && displayAvatar && (
        <ProfileAvatar
          profile={camelizedProfile as ProfileMinimized}
          size={avatarSize ? IconSizeTable[avatarSize] : 40}
        />
      )}
      <div className={classNames(css.dropdownItem, { [css.noAvatar]: !profile.avatar || !displayAvatar })}>
        <Typography noMargin clipOverflow variant="bodyBold">
          {name}
        </Typography>
        {!displayNameOnly && (
          <Typography noMargin clipOverflow variant="labelRegular" tag="p">
            {displayJobTitle && <>{calculatedJobTitle}</>} {camelizedProfile.email}
          </Typography>
        )}
      </div>
    </Flex>
  );
};
