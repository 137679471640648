import { useTranslation } from "react-i18next";

import { isActivityMemberEvent } from "../../../helpers";
import { useEventProfiles } from "../../../hooks/use_event_profiles";
import { HistoryEntryTemplate } from "../components/history_entry_template";
import { HistoryEntrySubject } from "../components/history_event_subject";
import { HistoryEntryProps } from "../history_entry";

export const HistoryEntryOwner = ({ entry }: HistoryEntryProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history" });

  const { targetProfile, isLoading } = useEventProfiles(entry);

  if (!isActivityMemberEvent(entry)) {
    return null;
  }

  const isPrincipalOwnerUpdate = entry.event.includes("updated") && (entry.diff.principal?.length || 0) > 0;

  const hasCreatedPrincipalOwner = entry.diff.principal?.at(-1) === true;

  const badge = isPrincipalOwnerUpdate
    ? t(`entry.principalOwner.${hasCreatedPrincipalOwner ? "created" : "removed"}`)
    : t(`entry.type.${entry.event}`);

  return (
    <HistoryEntryTemplate entry={entry}>
      <HistoryEntrySubject badge={badge} profile={targetProfile} isLoading={isLoading} />
    </HistoryEntryTemplate>
  );
};
