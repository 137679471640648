import { useMutation } from "@tanstack/react-query";
import { useNotificationsInvalidate } from "PFCore/hooks/queries/notifications/use_notifications_invalidate";
import {
  markNotificationAsRead,
  MarkNotificationAsReadPayload
} from "PFCore/services/notifications/mark_notification_as_read";
import { MutationOptions } from "PFTypes/request_options";

export const useNotificationMarkAsRead = ({
  onSuccess,
  ...options
}: MutationOptions<MarkNotificationAsReadPayload> = {}) => {
  const { invalidate: invalidateNotifications } = useNotificationsInvalidate();

  return useMutation({
    mutationFn: (payload: MarkNotificationAsReadPayload) => markNotificationAsRead(payload),
    onSuccess: (...params) => {
      invalidateNotifications();
      onSuccess?.(...params);
    },
    ...options
  });
};
