import { useQuery } from "@tanstack/react-query";
import { fetchCustomValue } from "PFCore/services/custom_values";
import { Id, PureCustomValueCamelCased } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { customValueKeys } from "./query_keys";

export const useCustomValue = (id: Id, options?: QueryOptions<PureCustomValueCamelCased>) =>
  useQuery<PureCustomValueCamelCased>({
    queryKey: customValueKeys.singleUnscoped(id),
    queryFn: () => fetchCustomValue(id),
    retry: false,
    ...options
  });
