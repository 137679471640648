import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { BookingFormMode } from "PFApp/booking/components/booking_form/use_booking_form";
import { OnSuccessData } from "PFApp/booking/components/booking_form/use_handle_submit/use_handle_submit";
import { useBookingsOverviewContext } from "PFApp/booking/parts/overview/context/bookings_overview_context";
import { Button } from "PFComponents/button";
import ButtonGroup from "PFComponents/button_group/button_group";
import { DropdownOption } from "PFComponents/dropdown";
import { DropdownButton } from "PFComponents/dropdown_button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useTranslation } from "react-i18next";

import { useBookingDetailSidePanelContext } from "../booking_detail_side_panel_context";
import css from "./clone_button.module.scss";

type CloneButtonProps = {
  bookingId: number;
  onSuccess: (data: OnSuccessData) => void;
};

export const CloneButton = ({ bookingId, onSuccess }: CloneButtonProps) => {
  const { t } = useTranslation("bookingModule");
  const { modal } = useBookingFormContext();
  const {
    calendarData: { data }
  } = useBookingsOverviewContext();
  const { siblingBookings } = useBookingDetailSidePanelContext();
  const isCloneAllAvailable = (siblingBookings?.length ?? 0) > 1;

  const handleClone = (mode: BookingFormMode) =>
    modal.open(
      {
        bookingId,
        ...(data.meta.search_id
          ? {
              groupBookingData: {
                searchId: data.meta.search_id,
                total: data.meta.total
              }
            }
          : {})
      },
      { onSuccess, mode }
    );

  if (!isCloneAllAvailable) {
    return <CloneSingleButton onClick={() => handleClone(BookingFormMode.CloneSingle)} />;
  }

  const options: DropdownOption[] = [
    {
      id: BookingFormMode.CloneAll,
      displayElement: t("bookings.clone.cloneAllBookings"),
      item: BookingFormMode.CloneAll
    }
  ];

  return (
    <ButtonGroup compact autoWidth>
      <CloneSingleButton onClick={() => handleClone(BookingFormMode.CloneSingle)} />
      <DropdownButton
        buttonClassName={css.dropdownButton}
        icon="chevron-down"
        options={options}
        handleChange={(item) => handleClone(item)}
        popperOptions={{ placement: "bottom-end" }}
        buttonKind="secondary"
      />
    </ButtonGroup>
  );
};

type CloneSingleButtonProps = { onClick: VoidFunction };
const CloneSingleButton = ({ onClick }: CloneSingleButtonProps) => {
  const { t } = useTranslation("bookingModule");
  return (
    <Tooltip content={t("bookings.clone.cloneAction")}>
      <Button icon="duplicate" onClick={onClick} kind="secondary" title={t("bookings.clone.cloneBooking")} />
    </Tooltip>
  );
};
