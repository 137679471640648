import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Button } from "PFComponents/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AddActivityProfilesModal, AddProfilesTabs } from "../activity_profiles/add_activity_profiles_modal";

export const AddReviewersButton = () => {
  const [showAddReviewers, setShowAddReviewers] = useState(false);
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });
  const { task: activity } = useActivityPageContext();

  return (
    <>
      <Button
        text={t("addReviewer")}
        kind="primary"
        style={{ minWidth: 140 }}
        qaId="activity-add-reviewer"
        onClick={() => setShowAddReviewers(true)}
      />
      {showAddReviewers && (
        <AddActivityProfilesModal
          onClose={() => setShowAddReviewers(false)}
          activity={activity}
          tabId={AddProfilesTabs.Reviewers}
        />
      )}
    </>
  );
};
