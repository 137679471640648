import classNames from "classnames";
import jQuery from "jquery";
import { useAppContext } from "PFApp/app_context";
import useIsAuditPlannerPermitted from "PFApp/use_is_audit_planner_permitted";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import useIsWorkflowPermitted from "PFApp/use_is_workflow_permitted";
import { Button } from "PFComponents/button";
import ButtonLink from "PFComponents/button/button_link";
import { isMsTeams, openChatInMsTeams } from "PFCore/helpers/ms_teams";
import { isProfileActive } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ApiRoute } from "PFCore/utilities/routes";
import { CHAT_ACTIONS } from "PFReducers/chat_reducer";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import css from "./profile_preview_action_buttons.module.scss";

const ProfilePreviewActionButtons = () => {
  const {
    dispatch,
    store: {
      profilePreview: { id, profile }
    }
  } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();

  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation("profiles", { keyPrefix: "preview" });

  const isWorkflowPermitted = useIsWorkflowPermitted();
  const isAuditPlannerPermitted = useIsAuditPlannerPermitted();

  const isChatEnabled = useIsChatEnabled();

  const isActive = isProfileActive(profile);
  const canChat = isChatEnabled && profile && isActive && currentProfile.id !== profile.id;

  const onMatchingRolesClick = () => {
    // @ts-ignore
    const utmSource = location?.state?.utmSource;
    history.push({ pathname: `/profiles/${id}/matching_roles`, state: { utmSource } });
  };

  const handleChatClick = (event) => {
    if (!profile) {
      return;
    }
    event.preventDefault();
    if (isMsTeams()) {
      return openChatInMsTeams(profile.email);
    }
    const uniqueProfileTargetId = Math.min(profile.id, currentProfile.id);
    const url = ApiRoute(`/api/chat/conversations`);
    const target = `gid://profinda-api/Profile/${uniqueProfileTargetId}`;
    const createTarget = `gid://profinda-api/Profile/${profile.id}`;
    const data = { target, one_to_one_profile_id: profile.id }; // eslint-disable-line camelcase
    // eslint-disable-next-line camelcase
    // @ts-ignore
    jQuery.ajax({ url, data, api_version: 2 }).then(({ entries }) => {
      if (entries && entries.length > 0) {
        dispatch({ type: CHAT_ACTIONS.CHAT_RENDER, payload: { id: entries[0].id } });
      } else {
        // eslint-disable-next-line camelcase
        // @ts-ignore
        jQuery.post({ url, data: { target: createTarget }, api_version: 2 }).then((response) => {
          dispatch({ type: CHAT_ACTIONS.CHAT_RENDER, payload: { id: response.id } });
        });
      }
    });
  };

  return (
    <div className={classNames(css.buttons, css.bottomLine)}>
      {profile?.viewable && (
        <ButtonLink qaId="ProfilePreview.viewFull" href={`/profiles/${id}`} key="profile">
          {t("viewFullProfile")}
        </ButtonLink>
      )}
      {isActive && (isWorkflowPermitted || isAuditPlannerPermitted) && (
        <Button kind="secondary" onClick={onMatchingRolesClick} key="match">
          {t("matchingRoles")}
        </Button>
      )}
      {canChat && <Button onClick={handleChatClick} kind="secondary" icon="chat" />}
    </div>
  );
};

export default ProfilePreviewActionButtons;
