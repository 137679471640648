import { TemplateKey } from "PFApp/constants/templates";
import PlusIcon from "PFComponents/icons/plus_icon";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { PermissionRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import { isNavItem } from "../helpers";
import { CommonNavItemProps, NavItem } from "../parts/nav_item";
import { NavSwitchItem } from "../types";

export const NewActivitiesNavItem = (props: CommonNavItemProps) => {
  const { t } = useTranslation(["activities", "translation"]);
  const { data: currentProfile } = useCurrentProfile();
  const isPermittedToTaskList = useIsCurrentUserPermittedTo(PermissionRule.TaskList);

  const all = currentProfile.templates || [];
  const templates = all.filter(({ hidden }) => hidden === false);
  const canCreateTasksList = templates.filter(({ create_permitted }) => create_permitted);
  const canCreateAnyTask = canCreateTasksList.length > 0;
  const canReadAnyTaskList = templates.filter(({ read_permitted }) => read_permitted);
  const canReadAnyTask = canReadAnyTaskList.length > 0;
  const canTaskList = canReadAnyTask && isPermittedToTaskList;
  const createIds = canCreateTasksList.map(({ id }) => id);
  const readIds = canReadAnyTaskList.map(({ id }) => id);

  const menu: NavSwitchItem[] = [];
  if (canTaskList || canCreateAnyTask) {
    templates.forEach((template) => {
      const canCreate = createIds.includes(template.id);
      const canRead = readIds.includes(template.id);

      if (canTaskList && canCreate && canRead && createIds.indexOf(template.id) >= 0) {
        menu.push({
          name: t(`activities:templates.${template.key}`),
          id: template.key,
          href: `/activities/${template.key}/new`
        });
        if (template.key === "engagement") {
          menu.push({ sep: true });
        }
      }
    });
  }

  if (menu.length === 0) {
    return null; // no permision to create any activity
  }

  if (isNavItem(menu[0]) && menu.length === 1) {
    const menuItem = menu[0];

    return (
      <NavItem
        icon={<PlusIcon size={20} />}
        isActiveTest={(path) => path.match(/^\/activities/) && path.match(/new$/)}
        name={t(`activities:createTemplates.${menuItem.id as TemplateKey}`)}
        href={menuItem.href}
        id={menuItem.id}
        {...props}
      />
    );
  }

  if (isNavItem(menu[0])) {
    return (
      <NavItem
        icon={<PlusIcon size={20} />}
        isActiveTest={(path) => path.match(/^\/activities/) && path.match(/new$/)}
        name={t("translation:create")}
        items={menu}
        id="new_activities"
        {...props}
      />
    );
  }

  return null;
};
