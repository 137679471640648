import { Icon } from "PFComponents/icon";
import { useTranslation } from "react-i18next";

import { DetailsHeader } from "../details_header";

type MisalignmentsHeaderProps = {
  roleName: string;
};

export const MisalignmentsHeader = ({ roleName }: MisalignmentsHeaderProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.misalignments" });

  return (
    <DetailsHeader title={roleName} subtitle={t("title")}>
      <Icon name="role" />
    </DetailsHeader>
  );
};
