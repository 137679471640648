import classNames from "classnames";
import { Icon, IconSize } from "PFComponents/icon";
import { Experience } from "PFTypes";

import css from "./no_experience.module.scss";

type NoExperienceProps = { experience: Experience; size: IconSize };

export const NoExperience = ({ experience, size }: NoExperienceProps) => (
  <div
    className={classNames(css.noExperience, {
      [css.center]: experience === Experience.Intermediate,
      [css.end]: experience === Experience.Advanced
    })}
  >
    <Icon className={css.icon} name="cross" color="paletteRed0" size={size} />
  </div>
);
