import { useBookingApprovalsInvalidate } from "PFCore/hooks/queries/bookings/approvals/use_booking_approvals_invalidate";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile";
import { useCallback } from "react";

import { usePotentialWarningsQueryInvalidate } from "../components/booking_form/use_potential_warnings/use_potential_warnings_query_invalidate";
import useRowChildrenInvalidate from "../parts/overview/side_panel_row/queries/use_row_children_invalidate";

type ProfileAvailabilityUpdatedEvent = {
  profile_id: number;
};

type UseProfileAvailabilityUpdatedHandlerReturn = (e: ProfileAvailabilityUpdatedEvent) => void;

export const useProfileAvailabilityUpdatedHandler = (): UseProfileAvailabilityUpdatedHandlerReturn => {
  const { invalidateProfile, invalidateBookingsDataByProfileId } = useProfileInvalidate();
  const { invalidateByProfileIds: invalidateRowChildrenByProfileIds } = useRowChildrenInvalidate();
  const { invalidate: invalidateBookingApprovals } = useBookingApprovalsInvalidate();
  const { invalidateAll: invalidatePotentialWarnings } = usePotentialWarningsQueryInvalidate();

  return useCallback(
    async ({ profile_id: profileId }) => {
      await invalidateBookingsDataByProfileId(profileId);
      await invalidateProfile(profileId);
      await invalidateRowChildrenByProfileIds([profileId]);
      await invalidateBookingApprovals();
      await invalidatePotentialWarnings();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
