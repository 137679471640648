import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking, PermissionRule } from "PFTypes";

export const useBookingNotesPermissions = (booking?: Booking) => {
  const { data: currentProfile } = useCurrentProfile();
  const { activity } = useBookingActivityContext();
  const { getBookingCategory } = useBookingCategories();
  const isPermittedTo = useIsCurrentUserPermittedTo();

  const isOwnBooking = booking?.profile_id === currentProfile.id;
  const isBookingCategoryReadonly = getBookingCategory(booking?.booking_category_id)?.readonly;

  const isBookingResourcer = isPermittedTo(PermissionRule.BookingResourcer);
  const hasActivityWriteAccess =
    !activity ||
    activity.coowned ||
    !!activity.participants?.find(({ profile }) => profile.id === currentProfile.id);

  return {
    canCreate: !isBookingCategoryReadonly && (isOwnBooking || (isBookingResourcer && hasActivityWriteAccess))
  };
};
