import { useAppContext } from "PFApp/app_context";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import PropTypes from "prop-types";
import { useCallback } from "react";

import SmartSuggestionsList from "./smart_suggestions_list";

const SuggestedKeywords = ({ items }) => {
  const {
    dispatch,
    store: {
      search: { pendingKeywordSearch, suggestedKeywordsCyclePage }
    }
  } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();

  const pushChoosenKeyword = useCallback((payload) => {
    const type = SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD;
    dispatch({ type, payload });
  }, []);

  const cycle = useCallback((payload) => {
    const type = SEARCH_ACTIONS.SEARCH_SUGGESTED_KEYWORDS_CYCLCE_PAGE_INCREMENT;
    dispatch({ type, payload });
  }, []);

  return (
    <div style={{ opacity: pendingKeywordSearch ? 0.5 : 1 }}>
      <SmartSuggestionsList
        items={items}
        onAdd={pushChoosenKeyword}
        cycle={cycle}
        cycleOffset={suggestedKeywordsCyclePage - 1}
        usedOn="search"
        userId={currentProfile.id}
      />
    </div>
  );
};

SuggestedKeywords.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }))
};

export default SuggestedKeywords;
