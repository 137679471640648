import { useGrowl } from "PFApp/use_growl";
import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { getProfileName } from "PFCore/helpers/profile";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useReviewerCreate, useReviewerDelete } from "PFCore/hooks/queries/reviewers";
import { Activity, ActivityProfile, ActivityProfileType } from "PFTypes";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { UseAddActivityProfilesReturn } from "./activity_profiles.types";
import {
  AddActivityProfilesModalContent,
  AddActivityProfilesModalContentProps
} from "./add_activity_profiles_modal_content";

type UseAddActivityProfilesProps = {
  activity: Activity;
  isUnbiasedEnabled: boolean;
};

export const useAddReviewersModal = ({
  activity,
  isUnbiasedEnabled
}: UseAddActivityProfilesProps): UseAddActivityProfilesReturn => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });
  const { getFirstError } = useResponseErrors();
  const taskReviewers = getActiveActivityProfiles(activity?.reviewers || []);

  const { mutate: createReviewer } = useReviewerCreate();
  const { mutate: deleteReviewer } = useReviewerDelete(activity?.id || 0);

  const [newProfiles, setNewProfiles] = useState<AddActivityProfilesModalContentProps["newProfiles"]>([]);

  const description = (
    <div>
      <Trans i18nKey="addReviewersDescription" t={t} components={[<strong key="reviewer-strong-tag" />]} />
      {isUnbiasedEnabled && <div style={{ marginTop: 10 }}>{t("addReviewersDescriptionUnbiased")}</div>}
    </div>
  );

  const handleReset = () => {
    setNewProfiles([]);
  };

  const handleRemove = (activityProfile: ActivityProfile) => {
    const reviewer = taskReviewers.find(({ profile_id }) => activityProfile.profile_id === profile_id);
    if (!reviewer?.id) {
      return null;
    }

    deleteReviewer(Number(reviewer.id), {
      onSuccess: () =>
        growl({
          message: t("profileRemovedReviewer", {
            profileName: getProfileName(reviewer.profile)
          }),
          kind: "success"
        })
    });
  };

  const handleSubmit = () =>
    newProfiles.forEach((profile) =>
      createReviewer(
        {
          profileId: profile.id,
          activityId: activity.id,
          unbiased: profile.unbiased || false
        },
        {
          onSuccess: () =>
            growl({
              message: t("requestReviewSuccess", { profileName: getProfileName(profile) }),
              kind: "success"
            }),
          onError: (response) => {
            const error = getFirstError(response, {});
            growl({
              kind: "error",
              message: error || t("requestReviewError", { profileName: getProfileName(profile) })
            });
          }
        }
      )
    );

  const getModalContent = () => (
    <AddActivityProfilesModalContent
      activity={activity}
      profiles={taskReviewers}
      onRemove={handleRemove}
      description={description}
      showUnbiasedToggle
      newProfiles={newProfiles}
      onNewProfilesChange={setNewProfiles}
      activityProfileType={ActivityProfileType.Reviewer}
    />
  );

  return { getModalContent, handleSubmit, enableSubmit: newProfiles.length > 0, handleReset };
};
