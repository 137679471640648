import { InviteConfirmModal } from "PFApp/activities/parts/invite_confirm_modal";
import { useAppContext } from "PFApp/app_context";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import {
  useShortlistDecision,
  useShortlistReset
} from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import CloseIcon from "PFIcons/close.svg";
import OkIcon from "PFIcons/ok.svg";
import colors from "PFTheme/tokens/colors";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useActivityCurrentUserRole } from "../hooks";

// pending <-> declined
//         <-> accepted -> assigned ==[by the shortlisted]=> rejected
//                                  ==[by the shortlisted]=> filled

// use when "pending"
export const AcceptDeclineButton = ({ kind, action, activity, shortlist, onDone, qaPrefix, text, style }) => {
  const [pending, setPending] = useState(false);
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const {
    store: { themeVars }
  } = useAppContext();
  const growl = useGrowl();
  const { t } = useTranslation("activities");
  const { mutateAsync: makeShortlistDecision } = useShortlistDecision();
  const { mutate: resetShortlist } = useShortlistReset();

  const currentProfileIsReviewer = useActivityCurrentUserRole(activity);
  const reviewCompleted = activity.review?.state === "complete";
  const disabled = currentProfileIsReviewer && !activity.coowned && reviewCompleted;

  const handleDone = (resp) => {
    setPending(false);
    onDone && onDone(resp);
  };

  const handleError = () => {
    growl({
      kind: "error",
      message: t("parts.problemUpdatingShortlist")
    });
  };

  const handleClick = () => {
    setDisplayConfirm(true);
  };

  const performDecisionRequest = async ({ params, onError }) => {
    await makeShortlistDecision(params, {
      onSuccess: handleDone,
      onError
    });
  };

  const handleSubmit = async (reasonValues) => {
    setPending(true);

    const params = { shortlistId: shortlist.id, action, reasons: reasonValues };

    await performDecisionRequest({
      params,
      onError: (err) => {
        if (err?.status === 422) {
          resetShortlist(shortlist.id, {
            onSuccess: () => {
              performDecisionRequest({ params });
            },
            onError: () => handleError()
          });
        } else {
          handleError();
        }
      }
    });
  };

  const _style = { minWidth: 100, ...style };

  return (
    <>
      <Button
        kind={kind || "primary"}
        disabled={disabled}
        style={_style}
        onClick={handleClick}
        qaId={`${qaPrefix}-${action}`}
      >
        {pending && "..."}
        {!pending &&
          (action === "accept" ? (
            <>
              <OkIcon
                width={16}
                height={16}
                style={{ marginRight: 5, fill: themeVars["--Palette-action"] }}
              />
              {` ${text || (activity.coowned ? t("accept") : t("parts.approve"))}`}
            </>
          ) : (
            <>
              <CloseIcon width={16} height={16} style={{ marginRight: 5, fill: colors.paletteRed1 }} />
              {` ${t("decline")}`}
            </>
          ))}
      </Button>
      {displayConfirm && <InviteConfirmModal setDisplayConfirm={setDisplayConfirm} onSubmit={handleSubmit} />}
    </>
  );
};

const defaultProps = {
  qaPrefix: "Button"
};

AcceptDeclineButton.defaultProps = defaultProps;

const propTypes = {
  kind: PropTypes.string,
  activity: PropTypes.shape({
    id: PropTypes.number,
    profile: PropTypes.object,
    coowned: PropTypes.bool,
    review: PropTypes.object
  }).isRequired,
  action: PropTypes.string,
  text: PropTypes.string,
  shortlist: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.oneOf(["pending", "accepted", "declined", "assigned", "filled", "rejected"]),
    profile: PropTypes.object,
    activity_id: PropTypes.number
  }).isRequired,
  isTaskOwner: PropTypes.bool,
  isUnbiased: PropTypes.bool,
  onDone: PropTypes.func,

  style: PropTypes.object,
  qaPrefix: PropTypes.string
};

AcceptDeclineButton.propTypes = propTypes;
