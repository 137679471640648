import api from "PFCore/api";
import { Notification, PaginatedCollection } from "PFTypes";

export type FetchNotificationsPayload = {
  targetTypes?: string[];
  excludedTargetTypes?: string[];
  perPage?: number;
  page?: number;
  templateKey?: string;
  read?: boolean;
};

export type FetchNotificationsResponse = PaginatedCollection<
  Notification[],
  { totalUnread: number; targetTypes: string[] }
>;

export const fetchNotifications = ({
  targetTypes,
  page = 1,
  perPage = 0,
  read = undefined,
  templateKey = undefined,
  excludedTargetTypes
}: FetchNotificationsPayload) =>
  api<FetchNotificationsResponse>({
    url: "notifications",
    params: {
      targetTypes,
      excludedTargetTypes,
      page,
      perPage,
      ...(read !== undefined ? { read } : {}),
      ...(templateKey ? { templateKey } : {})
    }
  });
