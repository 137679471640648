import { isEmpty } from "lodash";
import Calendar from "PFComponents/calendar/calendar";
import { Modal } from "PFComponents/modal";
import { Select } from "PFComponents/select/select";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PostUntilChangeReason, PostUntilChangeReasonData, Template } from "PFTypes";
import { useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import css from "./expiry_date_modal.module.scss";

export const OTHER_REASON_KEY = "other";

export type PostUntilData = {
  date: string;
  reason?: PostUntilChangeReasonData;
};

type ReasonsForm = {
  date: string;
  reason: string;
  otherReason?: string;
};

type ExpiryDateModalProps = {
  title: string;
  reasonLabel: string;
  date?: string;
  reason?: PostUntilChangeReason | null;
  template: Template;
  onChange: (value: PostUntilData) => void;
  onClose: () => void;
};

export const ExpiryDateModal = ({
  title,
  reasonLabel,
  date,
  reason,
  template,
  onChange,
  onClose
}: ExpiryDateModalProps) => {
  const { t } = useTranslation("translation");
  const { formatISODate, utc } = useDateFormatter();
  const tomorrow = utc().add(1, "days");

  const { control, handleSubmit, watch } = useForm<ReasonsForm>({
    defaultValues: {
      date: formatISODate(date ?? tomorrow),
      reason: reason?.key ?? "",
      otherReason: reason?.key === OTHER_REASON_KEY ? reason?.text : ""
    }
  });
  const [watchedReason, watchedOtherReason] = watch(["reason", "otherReason"]);
  const isOtherReason = watchedReason === OTHER_REASON_KEY;
  const reasonsList = template?.localized_post_until_change_reasons ?? [];
  const isReasonSelectorEnabled = !isEmpty(reasonsList);

  const dropdownOptions = useMemo(
    () =>
      reasonsList.map(({ key, text }) => ({
        id: key,
        displayElement: key === OTHER_REASON_KEY ? t("other") : text,
        item: key
      })),
    [reasonsList]
  );
  const handleSave: SubmitHandler<ReasonsForm> = (formData) => {
    const { date, reason, otherReason } = formData;
    const text =
      reason !== OTHER_REASON_KEY ? reasonsList.find(({ key }) => key === reason)?.text : otherReason;

    const postUntilData: PostUntilData = {
      date,
      ...(isReasonSelectorEnabled
        ? {
            reason: {
              key: reason,
              text
            }
          }
        : {})
    };
    onChange(postUntilData);

    onClose();
  };

  const shouldEnableSave =
    !isReasonSelectorEnabled ||
    (watchedReason && watchedReason !== OTHER_REASON_KEY) ||
    (watchedReason === OTHER_REASON_KEY && watchedOtherReason);

  return (
    <Modal
      title={title}
      labelOK={t("save")}
      onOK={shouldEnableSave ? handleSubmit(handleSave) : undefined}
      onClose={onClose}
    >
      {isReasonSelectorEnabled && (
        <>
          <Controller
            control={control}
            name={"reason"}
            render={({ field: { onChange, value } }) => (
              <Select
                label={reasonLabel}
                required
                value={dropdownOptions.find(({ id }) => id === value)?.displayElement}
                controlledValue
                options={dropdownOptions}
                onChange={onChange}
                selectedIndex={dropdownOptions.findIndex((option) => option.id === value)}
              />
            )}
          />
          {isOtherReason && (
            <Controller
              control={control}
              name={"otherReason"}
              render={({ field: { onChange, value } }) => (
                <InputFieldSet
                  value={value}
                  inputType="textarea"
                  maxLength={200}
                  required={true}
                  qaId="otherReasonInput"
                  onChange={onChange}
                  className={css.otherInput}
                />
              )}
            />
          )}
        </>
      )}
      <Controller
        control={control}
        rules={{ required: true }}
        name={"date"}
        render={({ field: { onChange, value } }) => (
          <Calendar
            selectedDate={value}
            handleChange={onChange}
            minDate={formatISODate(tomorrow)}
            fixedWidth={false}
            style={{ padding: "var(--spacingXl) 0" }}
          />
        )}
      />
    </Modal>
  );
};
