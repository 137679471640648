import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useFirstLoad } from "PFCore/hooks/use_first_load";
import { useCallback, useEffect, useMemo, useState } from "react";

export type UseNumberInput = {
  onChange: (value: number | undefined) => void;
  min: number;
  max?: number;
  disabled?: boolean;
} & (
  | { defaultValue: number | undefined; controlledValue?: never }
  | { defaultValue?: never; controlledValue: number | undefined }
);

interface UseNumberInputReturn {
  value: string;
  handleChange: (value: string) => void;
}

const getLimitedValue = (value: number, min: number, max?: number): number => {
  if (value < min) {
    return min;
  }
  if (!isNil(max) && value > max) {
    return max;
  }
  return roundToDecimals(value);
};

const getLocaleDecimalSeparator = (locale?: string) => {
  const formattedNumber = new Intl.NumberFormat(locale ?? "en").format(1.1);
  return formattedNumber.replace(/\d/g, "")[0];
};

export const useNumberInput = ({
  defaultValue,
  controlledValue,
  onChange,
  min,
  max,
  disabled
}: UseNumberInput): UseNumberInputReturn => {
  const { data: currentProfile } = useCurrentProfile();
  const isFirstLoad = useFirstLoad();

  const { decimalPoint, numberWithDecimalsRegex } = useMemo(() => {
    const decimalPoint = getLocaleDecimalSeparator(currentProfile.locale);
    const decimalPointEscaped = decimalPoint.replace(".", "\\.");
    const numberWithDecimalsRegex = new RegExp(`^\\d+(${decimalPointEscaped}\\d{0,2})?$`);
    return { decimalPoint, numberWithDecimalsRegex };
  }, [currentProfile.locale]);

  const [value, setValue] = useState<string>(() => {
    if (isNumber(defaultValue ?? controlledValue)) {
      return String(defaultValue ?? controlledValue).replaceAll(".", decimalPoint);
    }
    return "";
  });

  const handleChange = useCallback(
    (inputValue: string): void => {
      if (disabled) {
        return;
      }
      if (!inputValue) {
        setValue("");
        onChange(undefined);
        return;
      }
      if (!numberWithDecimalsRegex.test(inputValue)) {
        return;
      }
      if (!inputValue?.endsWith(decimalPoint)) {
        const newValue = getLimitedValue(Number(inputValue.replaceAll(decimalPoint, ".")), min, max);
        setValue(String(newValue).replaceAll(".", decimalPoint));
        onChange(newValue);
        return;
      }
      const [integerPart] = inputValue.split(decimalPoint);
      const newIntegerValue = getLimitedValue(Number(integerPart), min, max);
      setValue(`${newIntegerValue}${decimalPoint}`);
      onChange(newIntegerValue);
    },
    [min, max, onChange, decimalPoint, numberWithDecimalsRegex, disabled]
  );

  useEffect(() => {
    if (isNil(value) || isFirstLoad || disabled) {
      return;
    }
    handleChange(value);
  }, [max]);

  useEffect(() => {
    if (isNil(controlledValue)) {
      return;
    }
    const newStringValue = String(controlledValue).replaceAll(".", decimalPoint);
    if (newStringValue !== value) {
      handleChange(newStringValue);
    }
  }, [controlledValue]);

  return {
    value,
    handleChange
  };
};
