import { Button } from "PFComponents/button";
import { useNotificationsMarkAsRead } from "PFCore/hooks/queries/notifications/use_notifications_mark_as_read";
import { useTranslation } from "react-i18next";

import { useNotificationsPanelContext } from "./contexts/notifications_panel_context";

const NotificationsPanelActions = () => {
  const { t } = useTranslation();

  const { target, showUnreadOnly, setShowUnreadOnly } = useNotificationsPanelContext();
  const { mutate: markNotificationsAsRead } = useNotificationsMarkAsRead();

  const markAllAsRead = () =>
    markNotificationsAsRead({
      ...(target ? { targetTypes: target } : { excludedTargetTypes: ["Chat::Conversation"] })
    });

  const toggleShowUnreadOnly = () => {
    setShowUnreadOnly((prev) => !prev);
  };

  return (
    <>
      <Button small kind="text" onClick={toggleShowUnreadOnly}>
        {showUnreadOnly ? t("notifications.showAll") : t("notifications.showUnreadOnly")}
      </Button>
      <Button text={t("notifications.markAllAsRead")} small kind="text" onClick={markAllAsRead} />
    </>
  );
};

export default NotificationsPanelActions;
