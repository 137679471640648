import { isNaN, isNil } from "lodash";

type ConvertToPercentageOptions = {
  decimalPlaces?: number;
  placeholder?: string;
  isNormalized?: boolean;
  skipTrailingZeros?: boolean;
};

type RawValue = number | string | null | undefined;

const getValueWithPercentage = (value: number | string) => `${value}%`;

export const convertToPercentage = (rawValue: RawValue, options?: ConvertToPercentageOptions): string => {
  const { decimalPlaces, placeholder = "-", isNormalized, skipTrailingZeros } = options || {};

  const convertedRawValue = Number(rawValue ?? undefined);

  if (isNil(convertedRawValue) || isNaN(convertedRawValue)) {
    return placeholder;
  }

  const normalizedValue = isNormalized ? convertedRawValue : convertedRawValue * 100;

  if (decimalPlaces) {
    const fixedValue = normalizedValue.toFixed(decimalPlaces);

    return getValueWithPercentage(skipTrailingZeros ? Number(fixedValue) : fixedValue);
  }

  return getValueWithPercentage(Math.round(normalizedValue));
};
