import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { Divider } from "PFComponents/divider";
import { IconSizeTable } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import colors from "PFTheme/tokens/colors";
import { Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

import { RatingDots } from "../rating_dots";
import css from "./experience_level_tooltip_content.module.scss";

type ExperienceLevelTooltipContentRequiredProps = {
  ariaLabel?: string;
  experience?: Experience | null;
  requiredExperience: Experience;
  label?: string;
};

export const ExperienceLevelTooltipContentRequired = ({
  ariaLabel,
  experience,
  requiredExperience,
  label
}: ExperienceLevelTooltipContentRequiredProps) => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });
  const experienceLabelMap = useExperienceLabelMap();

  return (
    <div className={css.requiredExperience} aria-label={ariaLabel}>
      <Typography aria-label={ariaLabel} tag="p" noMargin className={css.text} variant="labelRegular">
        {t("required")}
        <RatingDots
          shape="rect"
          experience={requiredExperience}
          color={colors.paletteWhite0}
          colorSecondary={colors.paletteBlue2}
          size={IconSizeTable.sm}
        />
        <Typography variant="labelSemibold">{experienceLabelMap[requiredExperience]}</Typography>
      </Typography>
      <Divider className={css.divider} type="vertical" color="paletteWhite0" />
      {experience ? (
        <Typography aria-label={ariaLabel} tag="p" noMargin className={css.text} variant="labelRegular">
          {t("profile")}
          <RatingDots
            experience={experience}
            color={colors.paletteWhite0}
            colorSecondary={colors.paletteBlue2}
            size={IconSizeTable.xs}
          />
          {label}
        </Typography>
      ) : (
        <Typography aria-label={ariaLabel} tag="p" noMargin className={css.text} variant="labelRegular">
          {t("experienceMissing")}
        </Typography>
      )}
    </div>
  );
};
