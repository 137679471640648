import { BookingCategory } from "PFTypes";

import { BookingDataItem } from "../use_booking_form";

export type QueryKeyParams = {
  bookingsData: Omit<BookingDataItem, "title" | "description">[];
  activityId: number | null;
  workforceMemberId: number | undefined;
  bookingType: string;
  globalValuesArray: [BookingCategory | null, boolean, boolean];
};

export const potentialWarningsKeys = {
  all: () => ["potentialWarnings"],
  single: (params: QueryKeyParams) => [...potentialWarningsKeys.all(), params]
};
