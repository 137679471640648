import colors from "PFTheme/tokens/colors";

export const RADIUS = 8;

const LINE_LEN = RADIUS / 3;
export const LINE_WT = RADIUS / 3;

const OFFSET_LEFT = 0;
const OFFSET_TOP = 0;
const VIEW_BOX_HT = 2 * RADIUS + 2 * OFFSET_TOP;
const VIEW_BOX_WT = 6 * RADIUS + 2 * OFFSET_LEFT + 2 * LINE_LEN;
export const HEIGHT_MIDDLE = Math.ceil(VIEW_BOX_HT / 2); //10
export const CIRC_1_CENTER = {
  x: OFFSET_LEFT + RADIUS,
  y: HEIGHT_MIDDLE
};
export const CIRC_2_CENTER = {
  x: CIRC_1_CENTER.x + LINE_LEN + 2 * RADIUS,
  y: HEIGHT_MIDDLE
};
export const CIRC_3_CENTER = {
  x: CIRC_2_CENTER.x + LINE_LEN + 2 * RADIUS,
  y: HEIGHT_MIDDLE
};
export const VIEW_BOX = `0 0 ${VIEW_BOX_WT} ${VIEW_BOX_HT}`;
// for circles plus rectangles icon we want to cut space from top and bottom of rectangles
export const REQUIREMENT_VIEW_BOX = `0 ${VIEW_BOX_HT / 6} ${VIEW_BOX_WT} ${VIEW_BOX_HT / 2}`;

export const LINE_1 = {
  start: CIRC_1_CENTER.x + RADIUS,
  end: CIRC_1_CENTER.x + RADIUS + LINE_LEN
};
export const LINE_2 = {
  start: CIRC_2_CENTER.x + RADIUS,
  end: CIRC_2_CENTER.x + RADIUS + LINE_LEN
};

export const RECT_WT = 2 * RADIUS;
export const RECT_HT = (2 * RADIUS) / 3;
export const RECT_BORDER_RADIUS = RECT_HT / 2;

export const THEMES = {
  light: {
    main: colors.paletteBlue1,
    secondary: colors.paletteNeutral0
  },
  dark: {
    main: colors.paletteWhite0,
    secondary: colors.paletteBlue2
  }
};
