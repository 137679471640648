import { TippyProps } from "@tippyjs/react";
import classNames from "classnames";
import { Profile } from "PFTypes";

import css from "./contact_information.module.scss";
import { ContactButton } from "./parts/contact_button";
import { ContactIcon } from "./parts/contact_icon";
import { ContactInfo, useContactInfoArray } from "./use_contact_info_array";

type ContactInformationProps = {
  profile: Profile;
  showLabels: boolean;
  tooltipAppendTo?: TippyProps["appendTo"];
};

export const ContactInformation = ({ profile, showLabels, tooltipAppendTo }: ContactInformationProps) => {
  const contacts = useContactInfoArray(profile);

  if (contacts.length === 0) {
    return null;
  }

  const ContactComponent = showLabels
    ? ContactButton
    : (props: ContactInfo) => <ContactIcon {...props} tooltipAppendTo={tooltipAppendTo} />;

  return (
    <ul className={classNames(css.list, { [css.vertical]: showLabels })} data-qa-id="ContactInformation">
      {contacts.map((contact) => (
        <li key={contact.value + contact.type}>
          <ContactComponent {...contact} />
        </li>
      ))}
    </ul>
  );
};
