import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Divider } from "PFComponents/divider";
import { Tile } from "PFComponents/tile";

import { MatchAttributes } from "../../../components/match_attributes";
import { RoleInformation } from "../../../components/role_information";
import { MatchesChart } from "../../components/matches_chart";
import css from "./matches_information.module.scss";

export const MatchesInformation = () => {
  const { task } = useActivityPageContext();

  return (
    <Tile className={css.root} paddingVariant="lg" selected>
      <RoleInformation role={task} />
      <Divider />
      <MatchesChart />
      <Divider />
      <MatchAttributes />
    </Tile>
  );
};
