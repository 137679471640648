import isNil from "lodash/isNil";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "./details_header.module.scss";

type DetailsHeaderProps = {
  subtitle: string | number | null;
  title?: string;
  linkUrl?: string;
};

export const DetailsHeader = ({
  children,
  title,
  subtitle,
  linkUrl
}: PropsWithChildren<DetailsHeaderProps>) => {
  const { t } = useTranslation("translation");

  return (
    <section className={css.header}>
      <div className={css.headerWithChildren}>
        <div className={css.children}>{children}</div>
        <Typography variant="h4" className={css.title} title={title || t("untitled")} noMargin clipOverflow>
          {title || t("untitled")}
        </Typography>
        <div>
          {linkUrl && (
            <Link to={linkUrl}>
              <ActionIcon name="open" size="sm" />
            </Link>
          )}
        </div>
        <div />
        {!isNil(subtitle) && (
          <Typography
            variant="bodyRegular"
            className={css.titleLabel}
            title={String(subtitle)}
            noMargin
            clipOverflow
          >
            {subtitle}
          </Typography>
        )}
        <div />
      </div>
    </section>
  );
};
