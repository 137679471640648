import { useTemplate } from "PFApp/hooks/use_template";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { Activity, FeatureFlag } from "PFTypes";

export const useIsUnbiasedEnabled = (activity: Activity) => {
  const isEnabled = useIsFeatureEnabled();
  const template = useTemplate(activity.template_id);

  return Boolean(template?.need_matches) && isEnabled(FeatureFlag.UnbiasedAllocation);
};
