import { Experience } from "PFTypes";

import { HEIGHT_MIDDLE, LINE_WT } from "../constants";
import { RatingDotsProps } from "./rating_dots/rating_dots";

type LineProps = {
  experience: Experience;
  x1: number;
  x2: number;
} & Pick<RatingDotsProps, "color">;

export const Line = ({ experience, x1, x2, color }: LineProps) => (
  <line
    data-level={experience}
    x1={x1}
    y1={HEIGHT_MIDDLE}
    x2={x2}
    y2={HEIGHT_MIDDLE}
    stroke={color}
    strokeWidth={LINE_WT}
  />
);
