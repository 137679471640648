import isNil from "lodash/isNil";
import { BookingDetailActivity } from "PFApp/booking/components/booking_detail_activity";
import { BookingDetailItem } from "PFApp/booking/components/booking_detail_item";
import { useDetailsPanelApiContext } from "PFApp/booking/components/details_panel";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import { Booking } from "PFTypes/booking";
import { useTranslation } from "react-i18next";

import css from "../../detail_view.module.scss";
import { BookingSiblings } from "./booking_siblings";

type BookingDetailContentProps = {
  booking?: Booking;
  loading: boolean;
  profile: Profile | undefined;
  goToDate?: (date: Date) => void;
  checkCanGoToDate?: (date: string) => boolean;
};

export const BookingDetailContent = ({
  booking,
  profile,
  loading,
  goToDate,
  checkCanGoToDate
}: BookingDetailContentProps): JSX.Element => {
  const { activity, parentActivity, isActivityFetching, isParentActivityFetching, isParentActivityError } =
    useBookingActivityContext();
  const { openDetailsPanel, canOpenDetailsPanelOfType } = useDetailsPanelApiContext();
  const { isEnabled: isBMEnabled } = useBookingModulePermissions();

  const { t } = useTranslation(["translation", "bookingModule"]);

  if (!booking || loading || (!!booking.activity_id && isActivityFetching)) {
    return <LoadingDots />;
  }

  const openProfileSidePanel = (id: number) => {
    openDetailsPanel({
      type: "profile",
      id
    });
  };

  if (isNil(profile)) {
    return <BookingDetailItem label={t("bookingModule:details.workforceMember")} value={<LoadingDots />} />;
  }

  const profileNameElement = (
    <Typography variant="bodyBold" noMargin>
      {getProfileName(profile)}
    </Typography>
  );

  return (
    <>
      <div className={css.root}>
        <BookingDetailItem
          label={t("bookingModule:details.workforceMember")}
          value={
            isBMEnabled && canOpenDetailsPanelOfType("profile") ? (
              <Button type="button" kind="text" onClick={() => openProfileSidePanel(profile.id)}>
                {profileNameElement}
              </Button>
            ) : (
              profileNameElement
            )
          }
        />
        {activity && (
          <>
            <BookingDetailActivity activity={activity} parentActivity={parentActivity} />
            {!isParentActivityError && (
              <BookingDetailActivity activity={parentActivity} isLoading={isParentActivityFetching} />
            )}
          </>
        )}
        <BookingSiblings goToDate={goToDate} checkCanGoToDate={checkCanGoToDate} />
      </div>
    </>
  );
};
