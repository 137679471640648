import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { isNil } from "lodash";
import { Collection, Id } from "PFTypes";

import { Option, SelectOptions, SelectV2Props } from "../select_v2.types";
import { optionsKeys } from "./query_keys";
import { filterOptions } from "./use_options.utils";

export type UseOptions<T> = {
  dropdownId: string;
  ids?: Id[];
  searchTerm?: string;
  enabled?: boolean;
  queryOptions?: Omit<UseQueryOptions<Collection<Option<T>[]>>, "queryKey" | "queryFn">;
} & Pick<SelectV2Props<T>, "options" | "fetchOptions">;
type UseOptionsReturn<T> = { data: SelectOptions<T>; total: number; isFetching: boolean };

export const useOptions = <T>({
  dropdownId,
  ids,
  searchTerm,
  options,
  fetchOptions,
  queryOptions = {}
}: UseOptions<T>): UseOptionsReturn<T> => {
  const isFetchingOptionsEnabled = !!fetchOptions;
  const enabled = isFetchingOptionsEnabled && (!!queryOptions.enabled || isNil(queryOptions.enabled));
  const { data: fetchedOptions, isFetching } = useQuery({
    queryKey: optionsKeys.dropdownWithParams(dropdownId, ids, searchTerm),
    queryFn: () => (fetchOptions ? fetchOptions({ ids, searchTerm }) : undefined),
    ...queryOptions,
    enabled
  });

  if (isFetchingOptionsEnabled) {
    return {
      data: fetchedOptions?.entries ?? [],
      total: fetchedOptions?.meta?.total || 0,
      isFetching
    };
  }

  const resultOptions = searchTerm ? filterOptions(options ?? [], searchTerm) : options ?? [];
  return {
    data: resultOptions,
    total: resultOptions.length,
    isFetching: false
  };
};
