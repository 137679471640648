import { useQuery } from "@tanstack/react-query";
import { fetchProfileCustomValue } from "PFCore/services/custom_values";
import { Id } from "PFTypes";
import { ProfileCustomValue } from "PFTypes/custom_value";
import { QueryOptions } from "PFTypes/request_options";

import { customValueKeys } from "./query_keys";

export const useProfileCustomValue = (id: Id, profileId: Id, options?: QueryOptions<ProfileCustomValue>) =>
  useQuery<ProfileCustomValue>({
    queryKey: customValueKeys.singleInProfileScope(id, profileId),
    queryFn: () => fetchProfileCustomValue(Number(id), Number(profileId)),
    retry: false,
    ...options
  });
