import { capitalize } from "lodash";
import { every } from "lodash";
import ActivityPageContext from "PFApp/activities/show/activity_page_context";
import useActivityPageCompare from "PFApp/activities/show/hooks/use_activity_page_compare";
import useActivityPageConversations from "PFApp/activities/show/hooks/use_activity_page_conversations";
import { useActivityPageMatches } from "PFApp/activities/show/hooks/use_activity_page_matches";
import useActivityPageRoles from "PFApp/activities/show/hooks/use_activity_page_roles";
import useActivityPageShortlists from "PFApp/activities/show/hooks/use_activity_page_shortlists";
import useActivityPageTabs from "PFApp/activities/show/hooks/use_activity_page_tabs";
import { useActivityPageVacancies } from "PFApp/activities/show/hooks/use_activity_page_vacancies";
import { useActivityParent } from "PFApp/activities/show/hooks/use_activity_parent";
import { useIsUnbiasedEnabled } from "PFApp/activities/show/hooks/use_is_unbiased_enabled";
import { ProfilesViewMode } from "PFApp/activities/types/profiles_view_mode";
import { useAppContext } from "PFApp/app_context";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplateFind } from "PFApp/hooks";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { activityNotificationTypes } from "PFCore/helpers/activities";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import useStorage from "PFCore/helpers/use_storage";
import { useBookings } from "PFCore/hooks/queries";
import { useActivity, useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useAttachments } from "PFCore/hooks/queries/attachments/use_attachments";
import { useNotificationsMarkAsRead } from "PFCore/hooks/queries/notifications/use_notifications_mark_as_read";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ONE_MINUTE_MS } from "PFCore/utilities/time_consts";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import { Activity, FeatureFlag, GIdTargetType, Id, MetaWithPagination } from "PFTypes";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { isAudit as checkIsAudit } from "../helpers";
import { useActivityCurrentUserRole } from "../hooks";
import { useMatchesShortlistTablesColumns } from "./hooks/use_matches_shortlist_tables_columns";

type ActivityContextProviderProps = {
  id: Id;
  children: React.ReactNode;
  onActivityUpdate: () => void;
};

const ActivityContextProvider = ({ id, children, onActivityUpdate }: ActivityContextProviderProps) => {
  const { data: currentProfile } = useCurrentProfile();

  const [showAsInternalExperience, setShowAsInternalExperience] = useState(false);

  const { data: activity, isFetched: loaded, isError } = useActivity(id, { onSuccess: onActivityUpdate });
  const { shortlist_assignment } = activity || {};
  const { data: attachments } = useAttachments({
    targetId: Number(id),
    targetType: GIdTargetType.Task
  });
  const findTemplate = useTemplateFind();

  const checkInternalExperience = () => {
    const workTemplate = findTemplate({ key: TemplateKey.Work, create_permitted: true });
    const roleTemplate = currentProfile.templates.find((template) => template.key === "role");
    const isRole = Boolean(roleTemplate) && activity?.template_id === roleTemplate?.id;
    const hasAssignment = !!shortlist_assignment;

    if (isRole && workTemplate && hasAssignment) {
      setShowAsInternalExperience(true);
    }
  };

  useEffect(() => {
    checkInternalExperience();
  }, [activity]);

  if (!loaded) {
    return <LoadingDots circlesEnabled pageViewEnabled />;
  }

  if (isError || !activity) {
    return <NotFoundComponent />;
  }

  return (
    <LoadedActivity
      activity={activity as Activity}
      showAsInternalExperience={showAsInternalExperience}
      attachments={attachments?.entries || []}
    >
      {children}
    </LoadedActivity>
  );
};

type LoadedActivityProps = {
  activity: Activity;
  attachments: unknown[];
  children: ReactNode;
  showAsInternalExperience: boolean;
};

const LoadedActivity = ({
  activity,
  showAsInternalExperience,
  attachments,
  children
}: LoadedActivityProps) => {
  const { section, tab } = useParams<{ section: string; tab: string }>();
  const queryParams = useQueryParams();
  const location = useLocation<{ utmSource?: string }>();
  const { t } = useTranslation("activities");
  const { invalidate: invalidateActivities } = useActivityInvalidate();
  const { mutate: markNotificationsAsRead } = useNotificationsMarkAsRead();

  const utmSource = location?.state?.utmSource;

  const params = {
    section,
    tab,
    page: queryParams.get("page") || 1,
    utmSource
  };

  const { dispatch } = useAppContext();

  const { data: currentProfile } = useCurrentProfile();

  const { id, coowned, custom_fields, parent_activity_id, source, template_id, updated_at } = activity;
  const isEnabled = useIsFeatureEnabled();

  const [refetchedAt, setRefetchedAt] = useState<null | string>(null);
  const [profilesViewMode, setProfilesViewMode] = useStorage<ProfilesViewMode>(
    "activity_profiles_view_mode",
    ProfilesViewMode.Cards
  );

  const {
    availableMatchesShortlistColumns,
    selectedMatchesShortlistColumns,
    setSelectedMatchesShortlistColumns
  } = useMatchesShortlistTablesColumns();

  const template = currentProfile.templates.find((item) => item.id === template_id);
  const templateName = capitalize(template?.name);

  const isAudit = checkIsAudit(template);
  const roleKey = isAudit ? "audit_role" : "role";
  const engagementKey = isAudit ? "audit_engagement" : "engagement";

  const engagementTemplate = currentProfile.templates.find((item) => item.key === engagementKey);
  const roleTemplate = currentProfile.templates.find((item) => item.key === roleKey);

  const isUnbiasedEnabled = useIsUnbiasedEnabled(activity);

  const isEngagement = Boolean(engagementTemplate) && engagementTemplate?.id === template_id;

  const isRole = Boolean(roleTemplate) && roleTemplate?.id === template_id;
  const { isReviewer: currentProfileIsReviewer, isReviewerUnbiased: isActivityUnbiased } =
    useActivityCurrentUserRole(activity);

  const canCreateRoles = useMemo(() => {
    const roleTemplate = currentProfile?.templates?.find((item) => item.key === "role");
    if (roleTemplate) {
      return roleTemplate.create_permitted && !roleTemplate.hidden;
    }
    return false;
  }, [currentProfile?.templates]);

  const { showTabs, tabs, tabActiveId, tabsIds, handleChangeTab } = useActivityPageTabs(
    activity,
    params.tab || params.section
  );
  const [lastRefresh, setLastRefresh] = useState(activity.matched_at ?? undefined);

  const { matches, matchesMeta, matchesFetchState, canSeeMatches, matchesParams, updateMatchesParams } =
    useActivityPageMatches(activity, params);

  const {
    shortlists,
    shortlistsMeta,
    suspendedShortlists,
    suspendedShortlistsMeta,
    allShortlists,
    isLoadingShortlists,
    rankedShortlists,
    updateShortlistsParams
  } = useActivityPageShortlists(activity);

  const { primaryConversation, otherConversations, participantsOfConversations } =
    useActivityPageConversations(id, activity.profile.id, activity.private);

  const canManageBookings = isEnabled(FeatureFlag.BookingModule) && isRole && coowned && canCreateRoles;
  const {
    isEnabled: isBMEnabled,
    isPermittedToAccess: isPermittedToAccessBM,
    isPermittedToEdit: isPermittedToEditInBM
  } = useBookingModulePermissions();

  const { data } = useBookings(
    { profileId: currentProfile.id, activityId: id, perPage: 0 },
    { enabled: !isPermittedToEditInBM }
  );
  const isCurrentProfileBooked = (data?.meta.total || 0) > 0;

  const canSeeBookingModule = isBMEnabled && isPermittedToAccessBM;
  const canSeeViewInBookingModuleButton =
    canSeeBookingModule && (isCurrentProfileBooked || isPermittedToEditInBM);

  const {
    compareIds,
    setCompareIds,
    comparisonToastComponent,
    isProfileInComparison,
    addProfileToComparison,
    removeProfileFromComparison,
    clearComparisonToast
  } = useActivityPageCompare(id, tabActiveId, isAudit);

  const { parentActivity } = useActivityParent(parent_activity_id);
  const { roles, fetchRolesPage } = useActivityPageRoles(activity, isEngagement, roleTemplate?.key);
  const {
    vacancies,
    vacanciesProfiles,
    vacanciesFilledProfiles,
    fetchRolesVacanciesPage,
    closeVacancies,
    reopenVacancies,
    loading: vacanciesLoading,
    existsOpenVacancy
  } = useActivityPageVacancies(activity);

  const isFillDisabled = vacanciesLoading || !existsOpenVacancy;

  const checkActivityPending = (options: any = {}) => {
    if (options.checkRecentlyUpdated) {
      const updatedAtTime = new Date(updated_at).getTime();
      const currentTime = new Date().getTime();
      if (currentTime - updatedAtTime > ONE_MINUTE_MS) {
        return false;
      }
    }

    const isFromJML = source === "jml";
    const skills = custom_fields.find(({ type }) => type?.name === "skills")?.values || [];
    const allSkillsNotCurated = every(skills, (skill) => skill.curated_at === null);

    return isFromJML && skills.length > 0 && allSkillsNotCurated;
  };

  useEffect(() => {
    if (["workflow", "email"].includes(utmSource || "")) {
      markNotificationsAsRead({
        activityId: id,
        targetTypes: activityNotificationTypes
      });
    }
  }, []);

  useEffect(() => {
    checkActivityPending({ checkRecentlyUpdated: true }) &&
      dispatch({
        type: GROWL_ACTIONS.GROWL_RENDER,
        payload: {
          message: (
            <div>
              <Trans
                i18nKey={"show.patientAsDetailsCurate"}
                t={t}
                components={[<></>]}
                values={{ templateName: templateName }}
              />
              <Button
                kind="text"
                text={t("show.clickToReload")}
                onClick={() =>
                  invalidateActivities([id]).then(() => setRefetchedAt(new Date().toISOString()))
                }
              />
            </div>
          ),
          kind: "alert",
          ttl: null
        }
      });
  }, [refetchedAt]);

  return (
    <ActivityPageContext.Provider
      value={{
        task: activity,
        parentActivity,
        attachments,
        matches,
        shortlists,
        shortlistsMeta,
        suspendedShortlists,
        suspendedShortlistsMeta,
        allShortlists,
        isLoadingShortlists,
        rankedShortlists,
        compareIds,
        setCompareIds,
        updateShortlistsParams,
        clearComparisonToast,
        isProfileInComparison,
        addProfileToComparison,
        removeProfileFromComparison,
        primaryConversation,
        participantsOfConversations,
        otherConversations,
        isUnbiasedEnabled,
        isActivityUnbiased,
        isEngagement,
        isRole,
        isAudit,
        currentProfileIsReviewer,
        roles,
        fetchRolesPage,
        canCreateRoles,
        matchesMeta: matchesMeta as MetaWithPagination,
        matchesFetchState,
        matchesParams,
        canSeeMatches,
        updateMatchesParams,
        checkActivityPending,
        comparisonToastComponent,
        showTabs,
        tabs,
        tabActiveId,
        tabsIds,
        handleChangeTab,
        roleKey,
        vacancies,
        existsOpenVacancy,
        vacanciesLoading,
        vacanciesProfiles,
        vacanciesFilledProfiles,
        fetchRolesVacanciesPage,
        closeVacancies,
        reopenVacancies,
        canManageBookings,
        canSeeBookingModule,
        canSeeViewInBookingModuleButton,
        lastRefresh,
        setLastRefresh,
        showAsInternalExperience,
        profilesViewMode,
        setProfilesViewMode,
        availableMatchesShortlistColumns,
        selectedMatchesShortlistColumns,
        setSelectedMatchesShortlistColumns,
        isFillDisabled
      }}
    >
      {children}
    </ActivityPageContext.Provider>
  );
};

export default ActivityContextProvider;
