import classNames from "classnames";
import { forwardRef, SyntheticEvent } from "react";

import css from "./divs.module.scss";

export type ActionDivProps = React.PropsWithChildren<
  {
    ariaLabel?: string;
    onClick?: () => void;
    disabled?: boolean;
  } & Omit<React.HTMLAttributes<HTMLDivElement>, "onClick" | "ariaLabel">
>;

export const ActionDiv = forwardRef<HTMLDivElement, ActionDivProps>(
  ({ onClick, children, className, ariaLabel, onKeyDown, disabled, ...divProps }, ref) => {
    const handleClick = (event: SyntheticEvent) => {
      if (!onClick || disabled) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      onClick();
    };

    return (
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      <div
        className={classNames(css.actionDiv, { [css.clickable]: !disabled }, className)}
        onClick={handleClick}
        role="button"
        tabIndex={-1}
        aria-label={ariaLabel}
        ref={ref}
        {...divProps}
      >
        {children}
      </div>
    );
  }
);

ActionDiv.displayName = "ActionDiv";
