import { useActivityPermissions } from "PFApp/activities/hooks";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { Modal } from "PFComponents/modal";
import { useActivityInvalidate, useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import colors from "PFTheme/tokens/colors";
import { Shortlist } from "PFTypes";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import ButtonLabel from "../../../parts/button_label";
import ActivityPageContext from "../../activity_page_context";
import ExceptionsWarning from "./exceptions_warning";
import css from "./shortlist_item_buttons.module.scss";

type BookedOrConfirmedButtonsProps = {
  hasException: boolean;
  qaPrefix: string;
  isAudit: boolean;
  item: Shortlist;
};

const BookedOrConfirmedButtons = ({
  hasException,
  qaPrefix,
  isAudit,
  item
}: BookedOrConfirmedButtonsProps) => {
  const { t } = useTranslation(["activities", "translation"]);
  const { task } = useContext(ActivityPageContext);
  const { invalidateVacancies } = useActivityInvalidate();
  const { invalidateProfileBookings } = useProfileInvalidate();
  const { update: updateActivity } = useActivityUpdate(task?.id);
  const { mutate: resetShortlist } = useShortlistReset();
  const history = useHistory();

  const [displayUndo, setDisplayUndo] = useState(false);
  const growl = useGrowl();

  // used to refresh calendar on the side panel when undoing a booking
  const refreshBookings = async () => {
    await invalidateProfileBookings({ id: item.profile.id });
  };

  // can be only Confirmed or Booked
  const { state } = item;
  const { canReviewShortlists: canUndo } = useActivityPermissions(task);

  const handleUndo = (event) => {
    event.stopPropagation();
    setDisplayUndo(true);
  };

  return (
    <>
      <div className={css.rolesActions}>
        <ButtonLabel>
          <Icon name="user-filled" size="sm" fill={colors.palettePrimaryAction} />
          {t(`activities:show.shortlist.state.${state}`)}
        </ButtonLabel>
        {canUndo && (
          <div className={css.acceptedButtons}>
            {hasException && <ExceptionsWarning />}
            <Button
              text={t("translation:undo")}
              kind="blank"
              disabled={hasException}
              onClick={handleUndo}
              qaId={`${qaPrefix}-undo`}
            />
          </div>
        )}
      </div>
      {displayUndo && (
        <Modal
          title={
            isAudit
              ? t("activities:show.shortlist.unallocateResource")
              : t("activities:show.shortlist.unfillRole")
          }
          labelOK={t("translation:continue")}
          onOK={() => {
            resetShortlist(item.id, {
              onSuccess: () => {
                task?.coowned && updateActivity({}); // this is needed to trigger refetching callback on audit planner,
                invalidateVacancies(task?.id);
                refreshBookings();
                growl({
                  kind: "success",
                  message: isAudit
                    ? t("activities:show.shortlist.resourceUnallocated")
                    : t("activities:show.shortlist.roleUnfilled")
                });
                if (isAudit) {
                  history.push("/audit_planner/control_centre");
                }
              },
              onError: () => task?.coowned && updateActivity({})
            });
          }}
          onClose={() => {
            setDisplayUndo(false);
          }}
          kindOK="danger"
        >
          {isAudit
            ? t("activities:show.shortlist.wantUnallocateResource")
            : t("activities:show.shortlist.wantUnfillRole")}
        </Modal>
      )}
    </>
  );
};

export default BookedOrConfirmedButtons;
