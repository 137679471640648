import { NumberInput } from "PFComponents/availability/rules_carousel/number_input";
import { getWeekdays } from "PFCore/helpers/date";
import roundToDecimals from "PFCore/helpers/round_to_decimals";

import css from "./availability_with_hours.module.scss";

type HoursPerDayProps = {
  onHoursChange: (value: string, index: number) => void;
  dayOfWeek: number[];
};

export const HoursPerDay = ({ onHoursChange, dayOfWeek }: HoursPerDayProps) => (
  <div className={css.hoursPerDayWrapper}>
    {getWeekdays().map((day, index) => (
      <NumberInput
        key={`${day}+${index}`}
        label={day}
        controlledValue={roundToDecimals(dayOfWeek[index] / 60)}
        onChange={(value) => onHoursChange(value ? String(value) : "", index)}
        max={24}
        className={css.hourPerDay}
      />
    ))}
  </div>
);
