import { RoleProfileActionButtonsWrapper } from "PFApp/components/role_profile_action_buttons";
import { Divider } from "PFComponents/divider";

import { AddToComparisonButton } from "../../components/add_to_comparison_button";
import { useMatchCardContext } from "../../match_card_context";
import { MatchCardScores } from "../match_card_scores";
import css from "./match_card_header.module.scss";
import { MatchProfileInfo } from "./match_profile_info";
import { MatchStatusIcons } from "./match_status_icons";

export const MatchCardHeader = () => {
  const { role, profile, shortlist, isFillDisabled, allowCompare, areButtonsDisabled, isShortlistTabActive } =
    useMatchCardContext();

  return (
    <div className={css.header}>
      <div className={css.matchStatus}>
        <MatchCardScores />
        <MatchStatusIcons />
      </div>
      <Divider type="vertical" />
      <div className={css.matchProfile}>
        <MatchProfileInfo />
        <RoleProfileActionButtonsWrapper
          role={role}
          profile={profile}
          shortlist={shortlist}
          isFillDisabled={isFillDisabled}
          customButton={allowCompare && <AddToComparisonButton />}
          areButtonsDisabled={areButtonsDisabled}
          isShortlistTabActive={isShortlistTabActive}
        />
      </div>
    </div>
  );
};
