import classNames from "classnames";
import { capitalize } from "lodash";
import {
  AddActivityProfilesModal,
  AddProfilesTabs
} from "PFApp/activities/show/parts/activity_profiles/add_activity_profiles_modal";
import { useMetadata } from "PFApp/components/activity_commons/use_metadata";
import { Button } from "PFComponents/button";
import { Divider } from "PFComponents/divider";
import { Icon } from "PFComponents/icon";
import ToggleableText from "PFComponents/toggleable_text/toggleable_text";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingEngagementsEconomics } from "PFCore/hooks/queries/bookings/engagements/use_booking_engagements_economics";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { BudgetVsCostChart } from "../../../../activities/show/economics/scenario_view/charts/budget_vs_cost_chart";
import css from "../../../parts/overview/detail_view/detail_view.module.scss";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { EngagementDetailChildrenRoles } from "./engagement_detail_children_roles";
import { H5 } from "./parts/h5";
import { Label } from "./parts/label";

export const EngagementDetailContent = () => {
  const { formatDate, utc } = useDateFormatter();
  const { t } = useTranslation(["translation", "bookingModule"]);

  const [showAddOwners, setShowAddOwners] = useState(false);
  const { activity, id } = useBookingActivityContext();
  const { data: economicsData } = useBookingEngagementsEconomics(id ? [id] : []);

  const budgetChartData = economicsData?.entries[0];
  const metadata = useMetadata({ activity });
  const filteredMetadata = metadata.filter(
    ({ type, title }) => "availability" !== type && !["Skills", "Duration"].includes(title)
  );

  if (!activity) {
    return null;
  }

  const {
    profile,
    post_until, // eslint-disable-line camelcase
    metadata: { duration },
    owners,
    description,
    created_at, // eslint-disable-line camelcase
    coowned,
    children_workflow_states_tally: childrenWorkflowStatesTally
  } = activity;

  const daysLeft = -Math.trunc(utc().diff(utc(post_until), "days", true));

  return (
    <div className={classNames(css.root)}>
      <div className={css.item}>
        <Label text={capitalize(t("translation:owner"))} />
        <span className={css.spaceBetween}>
          <div>
            {owners.map(({ profile }, index) => (
              <Typography key={profile.id} variant="bodyBold" tag="span">
                <Link to={`/profiles/${profile.id}`}>{getProfileName(profile)}</Link>
                {index !== owners.length - 1 && ", "}
              </Typography>
            ))}
          </div>

          {coowned && <Button icon="add" kind="secondary" onClick={() => setShowAddOwners(true)} />}
        </span>
      </div>
      <Divider />
      <div className={css.item}>
        <H5>{t("translation:description")}</H5>
        <ToggleableText text={description} />
      </div>
      <span className={css.item}>
        <Label icon="calendar" text={t("translation:duration")} />
        <div>
          <Typography variant="bodyBold">
            {duration
              ? t("bookingModule:details.durationTo", {
                  startDate: duration.start_date ? formatDate(utc(duration.start_date)) : "-",
                  endDate: duration.end_date ? formatDate(utc(duration.end_date)) : "-"
                })
              : "-"}
          </Typography>
        </div>
      </span>
      <span className={css.details}>
        <span className={css.item}>
          <Label icon="hourglass-half" text={t("translation:postUntil")} />
          <Typography variant="bodyBold">{t("translation:day", { count: daysLeft })}</Typography>
        </span>
        <span className={css.item}>
          <Label icon="calendar" text={t("translation:createdAt")} />
          <Typography variant="bodyBold">{formatDate(utc(created_at))}</Typography>
        </span>
        {!!childrenWorkflowStatesTally && id && (
          <EngagementDetailChildrenRoles
            activityId={id}
            childrenWorkflowStatesTally={childrenWorkflowStatesTally}
          />
        )}
      </span>
      {budgetChartData?.budget && (
        <>
          <Divider />
          <div className={css.item}>
            <BudgetVsCostChart
              headerVariant="h5"
              budget={budgetChartData?.budget}
              cost={budgetChartData?.cost}
            />
          </div>
        </>
      )}
      <Divider />
      <div className={css.item}>
        <Label text={t("translation:privacy")} />
        <Typography className={classNames(css.alignCenter, css.gap5)} variant="bodyBold">
          {t(`translation:${activity.private ? "private" : "public"}`)}
          {activity.private && (
            <Tooltip content={t("bookingModule:details.privacyTooltipText")} appendTo="parent">
              <span className={css.alignCenter}>
                <Icon name="info" size="sm" />
              </span>
            </Tooltip>
          )}
        </Typography>
      </div>
      <span className={css.details}>
        {filteredMetadata.map(
          ({ title, text }) =>
            text !== undefined &&
            text !== "" && (
              <div key={title} className={css.item}>
                <Label text={title} />
                <Typography variant="bodyBold">{text}</Typography>
              </div>
            )
        )}
      </span>
      <Divider />
      <div className={css.item}>
        <Label text={t("translation:creator")} />
        <Typography variant="bodyBold">
          <Link to={`/profiles/${profile.id}`}>{getProfileName(profile)}</Link>
        </Typography>
      </div>
      {showAddOwners && (
        <AddActivityProfilesModal
          onClose={() => setShowAddOwners(false)}
          activity={activity}
          tabId={AddProfilesTabs.Owners}
        />
      )}
    </div>
  );
};
