import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useAppContext } from "PFApp/app_context";
import { ShareButton } from "PFApp/components/activity_share/share_button";
import Pill from "PFComponents/pill/pill";
import { isOpen, isUserParticipating as _isUserParticipating } from "PFCore/helpers/activities";
import { useInvites } from "PFCore/hooks/queries/invites/use_invites";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import DraftIcon from "PFIcons/draft.svg";
import PrivateIcon from "PFIcons/lock.svg";
import { useTranslation } from "react-i18next";

import { useIsAudit } from "../hooks/use_is_audit";
import css from "./activity_details_common.module.scss";

const ActivityDetailsTags = () => {
  const {
    store: { themeVars }
  } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();
  const { task } = useActivityPageContext();
  const { t } = useTranslation("activities");
  const { data: invites } = useInvites(task.id);

  const isAudit = useIsAudit();
  const profileId = currentProfile.id;
  const markAsInvited =
    !task.coowned && invites && !!invites.find(({ profile_id }) => profile_id === profileId);

  const isUserParticipating = _isUserParticipating(task, currentProfile.id); // note: uses global PF.current_profile
  const isDraft = task.state === "draft";
  const isPrivate = task.private;
  const isClosed = !isOpen(task) && !isDraft; // draft is not open nor closed
  // task.from_shortlist no longer exists on API, new pareameter=> task.owner
  const isFromShortlist = task.owner;

  const pillStyle = { margin: "0 10px 10px 0" };
  const pill = (text, cond, Icon) =>
    cond && (
      <Pill style={pillStyle}>
        {Icon && <Icon width={20} height={20} style={{ fill: themeVars["--Palette-base"], opacity: 0.4 }} />}
        {` ${t(`show.details.pill${text}`)}`}
      </Pill>
    );
  return (
    <div className={css.tags} data-qa-id="ActivityDetailsTags">
      {pill("Private", isPrivate, PrivateIcon)}
      {pill("Draft", isDraft, DraftIcon)}
      {pill("Closed", isClosed)}
      {pill("Participant", isUserParticipating)}
      {pill("Shared", !isUserParticipating && markAsInvited)}
      {pill("Shortlist", isFromShortlist)}
      {task.state !== "draft" && task.coowned && !isAudit && (
        <ShareButton activityIds={[task.id]} invites={invites} />
      )}
    </div>
  );
};

export default ActivityDetailsTags;
