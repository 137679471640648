import classNames from "classnames";
import WarningIcon from "PFIcons/warning.svg";
import { HTMLAttributes } from "react";

import css from "./yellow_warning_icon.module.scss";

type YellowWarningIconProps = {
  width?: number;
  height?: number;
  style?: HTMLAttributes<HTMLDivElement>["style"];
  className?: HTMLAttributes<HTMLDivElement>["className"];
  viewBox?: string;
} & HTMLAttributes<SVGElement>;

export const YellowWarningIcon = ({
  width = 90,
  height = 60,
  style,
  className,
  ...props
}: YellowWarningIconProps) => (
  <div className={css.container} style={style}>
    <div
      className={css.bar}
      style={{
        width: width / 9,
        height: height / 2
      }}
    />
    <WarningIcon className={classNames(css.icon, className)} width={width} height={height} {...props} />
  </div>
);
