import { useAppContext } from "PFApp/app_context";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SearchPage from "./search_page";

const SearchComponent = () => {
  const { tab, id, term } = useParams();

  const { dispatch } = useAppContext();
  const { customTypes } = useCustomTypes();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const payload = {};

    if (id && id !== "new") {
      payload.searchTaskId = +id;
    }

    if (term) {
      payload.term = term;
    }
    if (tab) {
      payload.tab = tab;
    }

    // we want to do all tose updates in one dispatch to avoid triggering effects
    // multiple times (most importantly url update)
    if (Object.keys(payload).length > 0) {
      dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_STATE, payload });
    }

    setReady(true);
  }, [term, tab]);

  return customTypes && ready ? <SearchPage /> : null;
};

export default SearchComponent;
