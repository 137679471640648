import { Note } from "PFComponents/notes/notes.types";

// TODO: [PROF-1218] Add notes - integration with the API part
export const MOCK_NOTES: Note[] = [
  {
    id: 1,
    author: "Abbey White",
    authorId: 12587,
    content: "I think this person would be a nice fit for this role",
    createdAt: "2021-08-17T13:12:33Z"
  },
  {
    id: 2,
    author: "Bartosz Skwara",
    authorId: 36201,
    content: "I totally agree with Abbey",
    createdAt: "2021-08-24T11:37:11Z"
  }
];
