import api from "PFCore/api";

export type MarkNotificationsPayload = {
  targetTypes?: string[];
  excludedTargetTypes?: string[];
  activityId?: number;
};

export const markNotificationsAsRead = ({
  targetTypes,
  excludedTargetTypes,
  activityId
}: MarkNotificationsPayload) =>
  api({
    url: "notifications/mark_as_read",
    method: "PUT",
    params: {
      activityId,
      targetTypes,
      excludedTargetTypes
    }
  });
