import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "PFTypes";

import { PotentialWarningsResponse } from "../../../services/api";
import { BookingFormValues } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";
import { fetchPotentialWarnings } from "./fetch_potential_warnings";
import { potentialWarningsKeys, QueryKeyParams } from "./query_keys";

export type FetchPotentialWarningsData = {
  values: BookingFormValues;
  mode: BookingFormMode;
  queryKeyParams: QueryKeyParams;
};

export const usePotentialWarningsQuery = (
  params: FetchPotentialWarningsData,
  options?: QueryOptions<PotentialWarningsResponse>
) =>
  useQuery<PotentialWarningsResponse>(
    potentialWarningsKeys.single(params.queryKeyParams),
    () =>
      fetchPotentialWarnings({
        values: params.values,
        mode: params.mode
      }),
    options
  );
