import { ColumnHelper } from "@tanstack/react-table";
import { convertToPercentage } from "PFCore/utilities/math";
import { EconomicsVacancy } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import css from "../vacancies_table.module.scss";

export const useMatchColumn = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });

  return useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor(({ assignment }) => assignment?.match?.normalizedScore, {
        id: "match",
        header: () => <span className={css.rightAlign}>{t("match")}</span>,
        cell: (info) => <span className={css.rightAlign}>{convertToPercentage(info.getValue())}</span>,
        enableSorting: true
      }),
    [t]
  );
};
