import { useActivityPermissions } from "PFApp/activities/hooks";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { Shortlist } from "PFTypes";
import { useTranslation } from "react-i18next";

import ButtonLabel from "../../../parts/button_label";
import { useActivityPageContext } from "../../activity_page_context";

type DeclinedButtonsProps = {
  item: Shortlist;
  qaPrefix: string;
};

const DeclinedButtons = ({ item, qaPrefix }: DeclinedButtonsProps) => {
  const { t } = useTranslation("translation");
  const { mutate: resetShortlist } = useShortlistReset();
  const { task } = useActivityPageContext();
  const { canReviewShortlists: canUndo } = useActivityPermissions(task);

  return (
    <>
      {canUndo && (
        <Button
          text={t("undo")}
          kind="blank"
          onClick={() => {
            resetShortlist(item.id);
          }}
          qaId={`${qaPrefix}-undo`}
        />
      )}
      <ButtonLabel>
        <Icon name="cross" size="sm" color="paletteRed0" />
        {t("declined")}
      </ButtonLabel>
    </>
  );
};

export default DeclinedButtons;
