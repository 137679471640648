import api from "PFCore/api";
import { ApiRoute } from "PFCore/utilities/routes";
import {
  ActivityCustomValue,
  Collection,
  CustomValue,
  CustomValueActivityConnection,
  CustomValueInsights,
  Id,
  ProfileCustomValue,
  PureCustomValue,
  PureCustomValueCamelCased
} from "PFTypes";

export type CustomValuesQueryParams = {
  term?: string;
  ids?: Id[];
  customTypeId?: Id;
  approved?: boolean;
  curated?: boolean;
};

export const fetchCustomValues = ({
  term,
  customTypeId,
  ids,
  approved,
  curated
}: CustomValuesQueryParams): Promise<Collection<PureCustomValue[]>> =>
  api({
    url: "custom_values",
    params: { term, customTypeId, ids, approved, curated }
  });

export const fetchProfileCustomValue = (customValueId: Id, profileId: Id) =>
  // TODO: [SP-2554] Response should be unified with fetchActivityCustomValue
  api<ProfileCustomValue>({
    url: `profiles/${profileId}/custom_values/${customValueId}`
  });

export const fetchActivityCustomValueConnection = (customValueId: Id, activityId: Id) =>
  api<CustomValueActivityConnection>({
    url: `activities/${activityId}/custom_values/${customValueId}/activity_connection`
  });

export const fetchCustomValue = (id: Id) =>
  api<PureCustomValueCamelCased>({
    url: `custom_values/${id}`
  });

export const fetchCustomValueInsights = (id: Id) =>
  api<CustomValueInsights>({
    url: `custom_values/${id}/insights`
  });

export type CustomValuesOptionsQueryParams = {
  term?: string;
  type: string;
  customValueIds?: Id[];
  connectedOptions?: boolean;
  value?: string[];
};

/**
 * @param {Object} params:
 *   A request parameters
 * @param {string} params.term:
 *   Search term
 * @param {string} params.type:
 *   Custom value type, e.g. "skills", "grade", "department"
 * @param {Id[]} params.customValueIds:
 *   It allows you to narrow down the values you get from this endpoint,
 *   to see only values which belong to profiles that also have all the other custom_value_ids provided.
 *   The ID of custom value is unique throughout all Custom Fields.
 * @param {boolean} params.connectedOptions:
 *   It controls whether API should return only values that exist in profiles in current account.
 *   Default value in the API is false.
 * @param {string[]} params.value:
 *   Text value of the custom value. If passed, term will be ignored. E.g. ["Ruby", "Rails"]
 * */
export const fetchCustomValuesOptions = ({
  term,
  type,
  customValueIds,
  connectedOptions,
  value
}: CustomValuesOptionsQueryParams): Promise<Collection<PureCustomValue[]>> =>
  api({
    url: "options/custom_values",
    params: {
      term,
      type,
      customValueIds,
      connectedOptions,
      value
    }
  });

export type ProfilesAutocompleteCustomValuesQueryParams = {
  term: string;
  type: string;
  global?: boolean;
  includeStates?: string[];
};

export const fetchProfilesAutocompleteCustomValues = ({
  term,
  type,
  global,
  includeStates
}: ProfilesAutocompleteCustomValuesQueryParams): Promise<PureCustomValue[]> =>
  //@ts-ignore
  $.ajax({
    url: ApiRoute("/api/profiles/autocomplete/custom_values"),
    api_version: 2, // eslint-disable-line camelcase
    data: {
      term,
      type,
      global,
      include_states: includeStates // eslint-disable-line camelcase
    }
  });

export type CreateCustomValueResponse = Pick<
  CustomValue,
  "createdAt" | "customTypeId" | "globalId" | "id" | "state" | "updatedAt" | "value"
> & { mergedIntoId: number | null };

export type CreateCustomValueData = {
  payload: Pick<CustomValue, "customTypeId" | "value" | "globalId">;
  data?: {
    created?: boolean;
  } & Pick<CustomValue, "value" | "text" | "source" | "globalId">;
};

export const createCustomValue = (
  data: CreateCustomValueData["payload"]
): Promise<CreateCustomValueResponse> =>
  api({
    url: "custom_values",
    method: "POST",
    data
  });

export type CreateDictionaryConnectionParams = {
  customValueId: Id;
} & Partial<
  Pick<ActivityCustomValue, "value" | "source" | "globalId" | "requiredExperience" | "interest"> &
    Pick<ProfileCustomValue, "experience" | "top" | "developmental">
>;

export const createDictionaryConnection = (body: CreateDictionaryConnectionParams) =>
  api({
    url: "profile_connections",
    method: "POST",
    data: { interest: true, ...body }
  });
