// New tooltip with tippyjs
// https://github.com/atomiks/tippyjs-react#readme for reference
// https://atomiks.github.io/tippyjs/v6/all-props/
import "tippy.js/dist/tippy.css";
import "./tooltip_theme.css";

import Tippy, { TippyProps } from "@tippyjs/react";
import HeadlessTippy from "@tippyjs/react/headless";
import { forwardRef } from "react";
import { followCursor as followCursorPlugin, sticky as stickyPlugin } from "tippy.js";

import { getTopModalElement } from "../modal/modal.utils";
import { useLazyTooltip } from "./use_lazy_tooltip";

export type TooltipProps = TippyProps & {
  enableVisible?: boolean;
  lazy?: boolean;
  theme?:
    | "pf"
    | "pf-info"
    | "pf-gray"
    | "pf-light"
    | "pf-skill"
    | "pf-dark-blue"
    | "pf-white"
    | "pf-dropdown"
    | "pf-translucent";
  visible?: boolean;
  children: any; // could not figure out type
};

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      content,
      delay,
      className,
      disabled,
      placement,
      arrow,
      visible,
      theme,
      trigger,
      enableVisible,
      interactive,
      followCursor,
      popperOptions,
      render,
      onHidden,
      onShow,
      onClickOutside,
      maxWidth,
      sticky,
      appendTo,
      lazy
    },
    ref
  ) => {
    const [lazyPlugin, finalContent, finalRender] = useLazyTooltip(lazy, content, render);

    if (!content && !render) {
      return children;
    }

    // visible overrides the trigger config. So if you want to programaticaly controll the component
    // you need to enable first enableVisible. By default works with trigger config
    const hasVisibleProps = enableVisible ? { visible } : { trigger };
    const pluginProps = { ...(followCursor ? { followCursor } : {}), ...(sticky ? { sticky: true } : {}) };
    const plugins = [
      ...(lazy ? [lazyPlugin] : []),
      ...(followCursor ? [followCursorPlugin] : []),
      ...(sticky ? [stickyPlugin] : [])
    ];

    const TippyComponent = render ? HeadlessTippy : Tippy;

    // TODO: [SP-1483] Can it be replaced with global state check?
    const isModalOpen = !!getTopModalElement();

    return (
      <TippyComponent
        role="tooltip"
        ref={ref}
        render={finalRender}
        content={finalContent}
        delay={delay}
        className={className}
        disabled={disabled}
        placement={placement}
        arrow={arrow}
        theme={theme}
        interactive={interactive}
        appendTo={appendTo || (isModalOpen && "parent") || document.body}
        plugins={plugins}
        popperOptions={popperOptions}
        onHidden={onHidden}
        onShow={onShow}
        onClickOutside={onClickOutside}
        maxWidth={maxWidth}
        aria={
          // tippy adds aria-expanded which triggers error on accessibility checks
          // we do not need that when disabled
          disabled
            ? {
                content: null,
                expanded: false
              }
            : undefined
        }
        zIndex={2800} // to be behind the modal when it opens from tooltip
        {...hasVisibleProps}
        {...pluginProps}
      >
        {children}
      </TippyComponent>
    );
  }
);

Tooltip.displayName = "Tooltip";

Tooltip.defaultProps = {
  delay: 100,
  className: "",
  disabled: false,
  placement: "top",
  arrow: true,
  visible: false,
  enableVisible: false,
  interactive: false,
  theme: "pf",
  trigger: "mouseenter focus",
  followCursor: false,
  lazy: false
};

export default Tooltip;
