import AppContext from "PFApp/app_context";
import { CUSTOM_VALUE_PREVIEW_ACTIONS } from "PFReducers/custom_value_preview_reducer";
import { CustomValuePreview } from "PFTypes";
import { OmitNonDist } from "PFTypes/omit";
import { useCallback, useContext } from "react";

export const useCustomValuePreviewActions = () => {
  const { dispatch } = useContext(AppContext);

  const showPreview = useCallback(
    (payload: OmitNonDist<CustomValuePreview, "show" | "isClosing">) =>
      dispatch({
        type: CUSTOM_VALUE_PREVIEW_ACTIONS.CUSTOM_VALUE_PREVIEW_SHOW,
        payload
      }),
    [dispatch]
  );

  const hidePreview = useCallback(
    () => dispatch({ type: CUSTOM_VALUE_PREVIEW_ACTIONS.CUSTOM_VALUE_PREVIEW_HIDE }),
    [dispatch]
  );

  // unmountPreview action is meant to finally unmount the component when animation is done
  // It should not be used in the code - use hidePreview instead
  const unmountPreview = useCallback(
    () => dispatch({ type: CUSTOM_VALUE_PREVIEW_ACTIONS.CUSTOM_VALUE_PREVIEW_UNMOUNT }),
    [dispatch]
  );

  return {
    showPreview,
    hidePreview,
    unmountPreview
  };
};
