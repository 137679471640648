import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import RemoveOwners from "PFApp/activities/show/parts/remove_owners";
import { RequestHelpModal } from "PFApp/activities/show/parts/request_help_modal";
import ActivityActions from "PFApp/components/activity_actions/activity_actions";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const ActivityShowMenuButton = () => {
  const { data: currentProfile } = useCurrentProfile();
  const { invalidate: invalidateActivities, invalidateVacancies } = useActivityInvalidate();
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { task } = useActivityPageContext();

  const [showRemoveCoOwnersModal, setShowRemoveCoOwnersModal] = useState(false);
  const [showRequestHelpModal, setShowRequestHelpModal] = useState(false);
  const { t } = useTranslation("activities");

  const additionalOptions = useMemo(() => {
    const options = [];

    if (!task.coowned) {
      return options;
    }

    const removableOwners = task.owners.filter(
      (owner) => ![currentProfile.id, task.profile.id].includes(owner.profile.id)
    );

    if (removableOwners.length > 0) {
      options.push({
        id: "remove-co-owner",
        displayElement: t("show.details.removeCoOwner"),
        item: () => setShowRemoveCoOwnersModal(true)
      });
    }
    options.push({
      id: "request_help",
      displayElement: t("show.details.requestHelp"),
      item: () => setShowRequestHelpModal(true)
    });

    return options;
  }, [task, currentProfile, t]);

  const handleCloseActivitySuccess = useCallback(() => {
    invalidateActivities([task.id]);
    invalidateVacancies(task.id);
    invalidateShortlists(task.id);
  }, []);

  return (
    <div style={{ display: "inline-block" }}>
      <ActivityActions
        task={task}
        handlePublish={() => invalidateActivities([task.id])}
        currentProfileId={currentProfile.id}
        additionalOptions={additionalOptions}
        dropDownStyle={additionalOptions.length > 0 ? { width: 160, maxHeight: "unset" } : null}
        handleCloseActivitySuccess={handleCloseActivitySuccess}
        qaId="activity-actions-button"
      />
      {showRemoveCoOwnersModal && (
        <RemoveOwners task={task} onClose={() => setShowRemoveCoOwnersModal(false)} />
      )}

      {showRequestHelpModal && (
        <RequestHelpModal activity={task} onClose={() => setShowRequestHelpModal(false)} />
      )}
    </div>
  );
};

export default ActivityShowMenuButton;
