import classNames from "classnames";
import { ProfileItem } from "PFApp/components/profile_item";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import AutoSelect from "PFComponents/select/autoselect";
import { TextArea } from "PFComponents/text/text_area";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { AuditPlannerExceptionsDictionary, AuditRole, BasicProfile, CamelizedShortlist } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { getExceptionDetails } from "../helpers";
import css from "../modals.module.scss";
import { ReasonForException } from "./reason_for_exception";

export type ConfirmHandler = (note: string, approver: BasicProfile) => void;

type ExceptionModalContentProps = {
  close: VoidFunction;
  confirm: ConfirmHandler;
  exception?: AuditPlannerExceptionsDictionary;
  role: AuditRole;
  edit?: boolean;
  isEditModal?: boolean;
  approver?: BasicProfile;
  requester?: BasicProfile;
  requestReason?: string;
  shortlistItem: CamelizedShortlist;
};

export const ExceptionModalContent = ({
  shortlistItem,
  close,
  confirm,
  exception,
  approver,
  requestReason,
  requester,
  role,
  isEditModal
}: ExceptionModalContentProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("auditPlanner");
  const { t: tCommon } = useTranslation("translation");

  const { isMultiple, keys: exceptionKeys } = getExceptionDetails(exception);
  const { profile } = shortlistItem;
  const [editMode, setEditMode] = useState(!isEditModal);
  const [note, setNote] = useState(requestReason || "");
  const [finalApprover, setFinalApprover] = useState(approver || null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const exceptionKeySelected = exceptionKeys[selectedIndex];
  const gradeCustomFieldValue = role.customFields?.find(({ type: { name } }) => name === "grade")?.values[0];
  const grade = role.grade || (gradeCustomFieldValue as any)?.value || gradeCustomFieldValue?.text || "-";

  return (
    <div className={css.modalContent}>
      <h3 className={css.title}>
        <Icon name="warning" color="paletteRed1" size="xxl" className={css.icon} />
        {t(`modals.auditPlannerExceptions.${isMultiple ? "multiple" : exceptionKeySelected}`)}
      </h3>
      <hr className={css.separator} />
      <div className={css.table}>
        <div className={css.cell}>
          <div>{t("parts.headers.roleName")}</div>
          <div className={css.bold}>{role.name}</div>
        </div>
        <div className={css.cell}>
          <div>{t("parts.headers.grade")}</div>
          <div className={css.bold}>{grade}</div>
        </div>
        <div className={css.cell}>
          <div>{t("parts.headers.resource")}</div>
          <div className={css.bold}>{getProfileName(profile)}</div>
        </div>
        <div className={css.cell}>
          <div>{t("parts.headers.raisedBy")}</div>
          <div className={css.bold}>{getProfileName(requester || currentProfile)}</div>
        </div>
      </div>
      <hr className={css.separator} />
      <div className={css.table}>
        <div className={css.cell}>{t("modals.reasonForException")}</div>
        <div className={classNames(css.wideCell, css.bold)}>
          {exception && (
            <ReasonForException
              profile={profile}
              exception={exception}
              selectedIndex={selectedIndex}
              onSelectedIndexChange={setSelectedIndex}
            />
          )}
        </div>
      </div>
      <hr className={css.separator} />
      <div className={css.table}>
        <div className={css.cell}>{`${t("modals.note")}*`}</div>
        <div className={classNames(css.wideCell, css.bold)}>
          {editMode && !requester ? (
            <TextArea
              className={css.textArea}
              placeholder={t("modals.addANote")}
              value={note}
              onChange={setNote}
              maxLength={280}
              maxHeight={200}
            />
          ) : (
            requestReason
          )}
        </div>
      </div>
      <hr className={css.separator} />
      <div className={css.table}>
        <div className={css.cell}>{`${t("modals.requestApprovalForm")}*`}</div>
        <div className={classNames(css.wideCell, css.bold)}>
          {editMode ? (
            <AutoSelect
              query={(term) => fetchProfilesOptions({ term, policyName: "audit_approver" })}
              filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
              formatOption={(item) => ({
                id: item.id,
                displayElement: <ProfileItem profile={item} />,
                item: item
              })}
              label={t("modals.selectApprover")}
              values={finalApprover ? [finalApprover] : null}
              displayValues={finalApprover ? <ProfileItem profile={finalApprover} /> : null}
              controlledValue
              closeOnChange
              handleChange={(approvers) => setFinalApprover(approvers[0])}
            />
          ) : (
            finalApprover && <ProfileItem profile={finalApprover} />
          )}
        </div>
      </div>
      <div className={css.buttons}>
        <Button text={tCommon("cancel")} kind="secondary" onClick={close} />
        <span style={{ display: "flex", alignItems: "center" }}>
          <Button
            text={
              isEditModal ? (!editMode ? tCommon("edit") : tCommon("submit")) : t("modals.requestApproval")
            }
            disabled={!finalApprover || !note}
            onClick={
              isEditModal
                ? editMode
                  ? () => confirm(note, finalApprover!)
                  : () => setEditMode(true)
                : () => confirm(note, finalApprover!)
            }
          />
        </span>
      </div>
    </div>
  );
};
