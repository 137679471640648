import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useBookingsEditModeStateContext } from "../../booking/parts/overview/context/booking_edit_mode_context/edit_mode_context";
import { EditMode } from "../../booking/types/edit_mode";

export const useDDActionNameLabel = () => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookingPills" });
  const { editMode } = useBookingsEditModeStateContext();

  const labels = useMemo(
    () => ({
      [EditMode.Disabled]: t("openInSidePanel", { lng: "en" }),
      [EditMode.WallPlanner]: t("openInSidePanel", { lng: "en" }),
      [EditMode.Split]: t("splitBooking", { lng: "en" }),
      [EditMode.DraftBulkOperation]: t("bulkUpdate", { lng: "en" }),
      [EditMode.Select]: t("selectBooking", { lng: "en" })
    }),
    [t]
  );

  return labels[editMode];
};
