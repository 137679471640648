import { Checkbox } from "PFComponents/checkbox/checkbox";
import { Filter } from "PFTypes";

import css from "./toggle_checkbox_item.module.scss";

type ToggleCheckboxItemProps = {
  onChange: (values: boolean | null) => void;
  filter: Filter;
  disabled?: boolean;
};

export const ToggleCheckboxItem = ({ filter, onChange, disabled }: ToggleCheckboxItemProps) => (
  <div className={css.root}>
    <Checkbox
      label={filter.title}
      checked={filter.value?.toString() === "true"}
      onChange={(checked) => onChange(checked ? checked : null)}
      disabled={disabled}
    />
  </div>
);
