import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useTranslation } from "react-i18next";

import { useCustomValuePreviewContext } from "../../context/custom_value_preview_context";
import css from "../../custom_value_preview.module.scss";

export type FooterProps = {
  allowEdit: boolean;
  isRemoveDisabled: boolean;
  isSaveDisabled: boolean;
  isSaveTooltipVisible: boolean;
  saveNewLabel: string;
  onRemove: VoidFunction;
  onSave: VoidFunction;
  onClose: VoidFunction;
};

export const Footer = ({
  allowEdit,
  isRemoveDisabled,
  isSaveDisabled,
  isSaveTooltipVisible,
  saveNewLabel,
  onRemove,
  onSave,
  onClose
}: FooterProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const { t: tTranslation } = useTranslation();
  const { isNew, canRemove } = useCustomValuePreviewContext();

  // TODO: [SP-2290] Make sure that changes from SP-2562 are applied when related PR is merged
  return (
    <div className={css.sidePanelFooter}>
      <Button
        kind="secondary"
        text={allowEdit ? tTranslation("cancel") : tTranslation("close")}
        onClick={onClose}
      />
      {allowEdit && (
        <>
          {!isNew && canRemove && (
            <Button
              kind="danger"
              disabled={isRemoveDisabled}
              text={tTranslation("remove")}
              onClick={onRemove}
            />
          )}
          <Tooltip disabled={!isSaveTooltipVisible} content={t("saveDisabledTooltip")}>
            <div>
              <Button
                kind="primary"
                disabled={isSaveDisabled}
                text={isNew ? saveNewLabel : tTranslation("update")}
                onClick={onSave}
              />
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};
