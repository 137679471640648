import { Button, ButtonProps } from "PFComponents/button";
import { Activity } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AddActivityProfilesModal, AddProfilesTabs } from "./activity_profiles/add_activity_profiles_modal";

type AddCoOwnerProps = {
  buttonKind?: ButtonProps["kind"];
  buttonStyle?: ButtonProps["style"];
  activity: Activity;
};

const AddCoOwner = ({ buttonKind, buttonStyle, activity }: AddCoOwnerProps) => {
  const [showAddOwners, setShowAddOwners] = useState(false);
  const { t } = useTranslation("activities");

  return (
    <>
      <Button
        text={t("show.parts.addCoOwner")}
        kind={buttonKind || "secondary"}
        style={buttonStyle}
        qaId="activity-add-owner"
        onClick={() => setShowAddOwners(true)}
      />
      {showAddOwners && (
        <AddActivityProfilesModal
          onClose={() => setShowAddOwners(false)}
          activity={activity}
          tabId={AddProfilesTabs.Owners}
        />
      )}
    </>
  );
};

export default AddCoOwner;
