import classNames from "classnames";
import { ProfileItem } from "PFApp/components/profile_item";
import { Typography } from "PFComponents/typography";
import { convertToPercentage } from "PFCore/utilities/math";

import css from "./display_option.module.scss";
import { CostField, Option } from "./matches_named_resources_selector";

type DisplayOptionProps = {
  option: Option;
  costField?: CostField;
};

export const DisplayOption = ({ option, costField }: DisplayOptionProps) => (
  <div className={classNames(css.grid, { [css.withCostField]: !!costField })}>
    <ProfileItem profile={option} />
    {!!costField && (
      <span className={css.cell}>
        <Typography variant="bodyBold" noMargin clipOverflow>
          {costField.getValue(option) || "-"}
        </Typography>
      </span>
    )}
    <span className={css.cell}>
      <Typography className={css.cell} variant="bodyBold" noMargin>
        {convertToPercentage(option.scores?.normalizedScore)}
      </Typography>
    </span>
    <span className={css.cell}>
      <Typography className={css.cell} variant="bodyBold" noMargin>
        {convertToPercentage(option.scores?.normalizedScore)}
      </Typography>
    </span>
  </div>
);
