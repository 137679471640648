import { useMutation } from "@tanstack/react-query";
import { OwnerUpdatePayload, updateOwner } from "PFCore/services/owners";
import { Owner } from "PFTypes";
import { MutationOptions } from "PFTypes/request_options";

import { useActivityInvalidate } from "../activity/use_activity_invalidate";

export const useOwnerUpdate = (
  activityId: number,
  { onSuccess, ...options }: MutationOptions<OwnerUpdatePayload & { id: number }> = {}
) => {
  const { invalidate: invalidateActivity } = useActivityInvalidate();

  return useMutation({
    mutationFn: (payload: OwnerUpdatePayload & { id: number }) => updateOwner(payload.id, payload),
    onSuccess: (data: Owner, ...params) => {
      invalidateActivity([activityId]);
      onSuccess?.(data, ...params);
    },
    ...options
  });
};
