import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { ShortlistState } from "PFTypes";

type UseBookButtonPermissionsProps = {
  state: ShortlistState;
  coowned: boolean;
  isReviewer: boolean;
};

export const useBookButtonPermissions = ({
  state,
  coowned,
  isReviewer
}: UseBookButtonPermissionsProps): boolean => {
  const { isEnabled, isPermittedToEdit } = useBookingModulePermissions();
  const { bookingCategories } = useBookingCategories();
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !!demandRelated)
    .some((i) => !i.readonly);

  return (
    (coowned || isReviewer) &&
    state === "filled" &&
    isEnabled &&
    isPermittedToEdit &&
    hasWriteAccessToAtLeastOneCategory
  );
};
