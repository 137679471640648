import { usePreviousDistinct } from "react-use";

import { JsonValue, useDeterministicStringify } from "../helpers/use_deterministic_stringify";

type PreviousValue<T> = T | null;

export const usePreviousValue = <T>(value: T): PreviousValue<T> => {
  const stringify = useDeterministicStringify();
  const previousValue = usePreviousDistinct<PreviousValue<T>>(
    value,
    (prev, next) => stringify(prev as JsonValue) === stringify(next as JsonValue)
  );

  return previousValue ?? null;
};
