import { ActionIcons, useActionIcons } from "PFComponents/select_v2/action_icons"; // TODO: [SP-2251] Move useActionIcons hook and ActionIcons component to common directory
import { Typography } from "PFComponents/typography";
import { FilterWithDefaults, Id } from "PFTypes";

import css from "./filter_header.module.scss";

type FilterHeaderProps = {
  filter: FilterWithDefaults;
  onChange: (id: Id | Id[] | null) => void;
  canRestore: boolean;
  canClear: boolean;
  disabled?: boolean;
};

export const FilterHeader = ({ filter, onChange, canRestore, canClear, disabled }: FilterHeaderProps) => {
  const actionIcons = useActionIcons({
    onRestore: canRestore ? () => onChange(filter.defaultValue as Id | Id[] | null) : undefined,
    onClear: canClear ? () => onChange([]) : undefined,
    disabled,
    label: filter.title
  });

  return (
    <div className={css.header}>
      <Typography variant="labelRegular">{filter.title}</Typography>
      <ActionIcons actionIcons={actionIcons} />
    </div>
  );
};
