import { cloneDeep, pick } from "lodash";
import {
  ActivityCustomField,
  ActivityPureCustomValue,
  CustomType,
  Experience,
  Id,
  Importance,
  PureCustomValueCamelCased
} from "PFTypes";

const findCustomField = (customFields: ActivityCustomField[], id: Id) =>
  customFields.find((cf) => cf.type?.id === id);

export const removeCustomField = (
  customFields: ActivityCustomField[],
  customValueId: Id,
  customTypeId: Id
) => {
  const newCustomFields = cloneDeep(customFields);
  const customField = findCustomField(newCustomFields, customTypeId);

  if (!customField) {
    return customFields;
  }

  customField.values = customField.values.filter((value) => value.id !== customValueId);

  return newCustomFields;
};

export const addCustomField = (
  customFields: ActivityCustomField[],
  customType: Pick<CustomType, "id" | "name">,
  customValue: Pick<PureCustomValueCamelCased, "id" | "text" | "value">,
  importance: Importance,
  requiredExperience: Experience
) => {
  const newCustomFields = cloneDeep(customFields);
  const customField = findCustomField(newCustomFields, customType.id);

  const newValue = {
    ...pick(customValue, ["id", "text", "value"]),
    importance,
    required_experience: requiredExperience || Experience.Intermediate
  } as ActivityPureCustomValue;

  if (!customField) {
    return [
      ...customFields,
      {
        type: pick(customType, ["id", "name"]),
        values: [newValue]
      } as ActivityCustomField
    ];
  }

  customField.values.push(newValue);

  return newCustomFields;
};

export const editCustomField = (
  customFields: ActivityCustomField[],
  customValueId: Id,
  customTypeId: Id,
  importance: Importance,
  requiredExperience: Experience
) => {
  const newCustomFields = cloneDeep(customFields);
  const customField = findCustomField(newCustomFields, customTypeId);

  if (!customField) {
    return customFields;
  }

  customField.values = customField.values.map((value) =>
    value.id === customValueId
      ? ({
          ...value,
          importance,
          required_experience: requiredExperience || Experience.Intermediate
        } as ActivityPureCustomValue)
      : value
  );

  return newCustomFields;
};
