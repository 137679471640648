import { useAppContext } from "PFApp/app_context";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";

import KeywordsSliders from "./keywords_sliders";

const SearchKeywordSliders = () => {
  const {
    dispatch,
    store: {
      search: { choosenKeywords, matchTypes }
    }
  } = useAppContext();

  return (
    <KeywordsSliders
      search
      choosenKeywords={choosenKeywords}
      matchableTypes={matchTypes}
      remove={(keyword) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_REMOVE_CHOOSEN_KEYWORD, payload: keyword });
      }}
      pushWithNewImportance={(keyword, importance) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD, payload: { ...keyword, importance } });
      }}
      handleAddOne={(payload) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD, payload });
      }}
    />
  );
};

export default SearchKeywordSliders;
