import { getGrowlId } from "PFApp/use_growl";
import { BOOKING_CATEGORY_ERROR_KEY, PHASES_ERROR_KEY } from "PFComponents/availability";
import { UseFormClearErrors, UseFormSetError } from "react-hook-form";

import { BookingFormValues } from "./booking_form_provider";
import { BookingDataItem, BookingFormMode } from "./use_booking_form";
import { isNotLimited } from "./use_handle_submit/use_booking_payload/use_booking_payload";

export const getHeaderTitleTranslationKey = (mode: BookingFormMode | undefined) => {
  if (mode === BookingFormMode.Edit) {
    return "bookingModule:bookings.update.editBooking";
  }
  if (mode && [BookingFormMode.CloneSingle, BookingFormMode.CloneAll].includes(mode)) {
    return "bookingModule:bookings.clone.cloneBooking";
  }
  return "bookingModule:bookings.create.createBooking";
};

export const getSubmitSuccessMessageTranslationKey = (
  mode: BookingFormMode | undefined,
  isAffectingGroup: boolean
): {
  actionId: string;
  messageKey:
    | "bookings.create.bulk.success"
    | "bookings.update.success"
    | "bookings.clone.success"
    | "bookings.create.success";
} => {
  if (isAffectingGroup) {
    return {
      actionId: getGrowlId("bm-booking-form-create-bulk"),
      messageKey: "bookings.create.bulk.success"
    };
  }
  if (mode === BookingFormMode.Edit) {
    return {
      actionId: getGrowlId("bm-booking-form-update"),
      messageKey: "bookings.update.success"
    };
  }
  if (mode && [BookingFormMode.CloneSingle, BookingFormMode.CloneAll].includes(mode)) {
    return {
      actionId: getGrowlId("bm-booking-form-clone"),
      messageKey: "bookings.clone.success"
    };
  }
  return {
    actionId: getGrowlId("bm-booking-form-create"),
    messageKey: "bookings.create.success"
  };
};

export const getSubmitErrorMessageTranslationKey = (mode: BookingFormMode | undefined) => {
  if (mode === BookingFormMode.Edit) {
    return "bookings.update.error";
  }
  if (mode && [BookingFormMode.CloneSingle, BookingFormMode.CloneAll].includes(mode)) {
    return "bookings.clone.error";
  }
  return "bookings.create.error";
};

export const setCategoryOrPhaseErrors = (
  mode: BookingFormMode,
  bookings: BookingDataItem[],
  responseErrors: Record<string, string>,
  setError: UseFormSetError<BookingFormValues>,
  clearErrors: UseFormClearErrors<BookingFormValues>,
  action: "potential-warnings" | "submit"
) => {
  const { categoryErrors, phaseSourceIdErrors } = bookings.filter(isNotLimited).reduce(
    (result, current, index) => {
      const globalCategoryErrorKey = BOOKING_CATEGORY_ERROR_KEY;
      const categoryErrorKey = `bookings[${index}][${BOOKING_CATEGORY_ERROR_KEY}]`;
      const phaseErrorKey = `bookings[${index}]${PHASES_ERROR_KEY}`;
      const categoryError = responseErrors[categoryErrorKey] ?? responseErrors[globalCategoryErrorKey];
      const phaseError = responseErrors[phaseErrorKey];

      return {
        categoryErrors: {
          ...result.categoryErrors,
          ...(categoryError ? { [current.key]: categoryError } : {})
        },
        phaseSourceIdErrors: {
          ...result.phaseSourceIdErrors,
          ...(phaseError ? { [current.key]: phaseError } : {})
        }
      };
    },
    { categoryErrors: {}, phaseSourceIdErrors: {} }
  );
  bookings.forEach((item, index) => {
    const categoryErrorKey =
      mode === BookingFormMode.Create
        ? `globalCategoryErrorAPI.${action}`
        : `bookings.${index}.categoryErrorAPI.${action}`;
    if (categoryErrors[item.key]) {
      setError(categoryErrorKey as keyof BookingFormValues, {
        type: "custom",
        message: categoryErrors[item.key]
      });
    } else {
      clearErrors(categoryErrorKey as keyof BookingFormValues);
    }
    if (phaseSourceIdErrors[item.key]) {
      setError(`bookings.${index}.phaseSourceIdErrorAPI.${action}` as keyof BookingFormValues, {
        type: "custom",
        message: phaseSourceIdErrors[item.key]
      });
    } else {
      clearErrors(`bookings.${index}.phaseSourceIdErrorAPI.${action}` as keyof BookingFormValues);
    }
  });
};

export const clearCategoryOrPhaseErrors = (
  mode: BookingFormMode,
  bookings: BookingDataItem[],
  clearErrors: UseFormClearErrors<BookingFormValues>,
  action: "potential-warnings" | "submit"
) => {
  if (mode === BookingFormMode.Create) {
    clearErrors(`globalCategoryErrorAPI.${action}` as keyof BookingFormValues);
    bookings.forEach((_, index) =>
      clearErrors(`bookings.${index}.phaseSourceIdErrorAPI.${action}` as keyof BookingFormValues)
    );
  } else {
    bookings.forEach((_, index) =>
      clearErrors([
        `bookings.${index}.categoryErrorAPI.${action}` as keyof BookingFormValues,
        `bookings.${index}.phaseSourceIdErrorAPI.${action}` as keyof BookingFormValues
      ])
    );
  }
};
