import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { useCurrentGroupPermissions } from "PFCore/hooks/use_current_group_permissions";
import { AccessLevel } from "PFTypes";

export const useCanEditAuditRoles = () => {
  const { getActivityPermissions } = useCurrentGroupPermissions();
  const auditRoleTemplate = useTemplate(TemplateKey.AuditRole);
  const { task: activity, isAudit, isRole } = useActivityPageContext();

  const isAuditRole = isAudit && isRole;
  const auditRolePermissions = getActivityPermissions(auditRoleTemplate);
  const canEditAuditRole = isAuditRole && auditRolePermissions === AccessLevel.ReadWrite && activity.coowned;

  // TODO: [SP-2182] We decided to disable the edit action for Audit Roles. It will be enabled in the future.
  const isDisabled = true;

  return {
    canEditAuditRole: canEditAuditRole && !isDisabled
  };
};
