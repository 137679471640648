import classNames from "classnames";
import { trim } from "lodash";
import moment from "moment";
import { useAppContext } from "PFApp/app_context";
import DownloadLink from "PFApp/components/chat/download_link";
import showProfile from "PFApp/components/chat/show_profile";
import { useGrowl } from "PFApp/use_growl";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { fullMarkdown } from "PFCore/helpers/markdown";
import { getProfileName } from "PFCore/helpers/profile";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useDeleteChatMessage } from "PFCore/hooks/queries/chat/use_delete_chat_message";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PermissionRule } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./chat_message.scss";
import { MessageAttachment } from "./message_attachment";
import { MessageInfo } from "./message_info";

type Props = {
  owned: boolean;
  message: any;
  prevMessage: any;
  nextMessage: any;
  handleMinimize: () => void;
};

export const ChatMessage = ({
  owned,
  message,
  prevMessage,
  nextMessage,
  handleMinimize
}: Props): JSX.Element => {
  const { t } = useTranslation("core");
  const [showInfo, setShowInfo] = useState(false);
  const { dispatch } = useAppContext();
  const growl = useGrowl();

  const { mutate: deleteMessage } = useDeleteChatMessage(message.conversation_id, {
    onError: (error) => {
      growl({
        message: error.responseJSON.errors[0].title,
        kind: "error"
      });
    }
  });

  const { formatDate, formatISODate } = useDateFormatter();

  const dateFmt = formatISODate(message.created_at);
  const firstOfDate = prevMessage && dateFmt !== formatISODate(prevMessage.created_at);
  const lastOfDate = nextMessage && dateFmt !== formatISODate(nextMessage.created_at);

  const text = trim(message.plain || "");
  const { attachments, profile, created_at: createdAt, deleted } = message;

  const hasPrevious = !firstOfDate && prevMessage && prevMessage.profile_id === profile.id;
  const hasNext = !lastOfDate && nextMessage && nextMessage.profile_id === profile.id;

  const expanded = showInfo && message.id;
  const canListProfiles = useIsCurrentUserPermittedTo(PermissionRule.ProfileList);

  const isProfileViewable = (): boolean => {
    if (!profile) {
      return false;
    }

    const isProfileViewable = profile.viewable as boolean;

    return canListProfiles && isProfileViewable;
  };

  const handleProfileClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    if (isProfileViewable()) {
      showProfile(handleMinimize, event, profile.id, dispatch);
    }
  }, []);

  const handleOuterContentClick = useCallback(() => {
    setShowInfo(!showInfo);
  }, [setShowInfo, showInfo]);

  const handleDeleteClicked = useCallback(() => {
    if (confirm(t("chat.confirmMessageDelete"))) {
      deleteMessage(message.id);
    }
  }, [deleteMessage, message.id]);

  return (
    <li key={message.id} className={classNames({ [css.rootOwned]: owned, [css.rootNoNext]: !hasNext })}>
      {firstOfDate && <span className={css.hr}>{formatDate(createdAt)}</span>}
      <div
        className={classNames(css.row, { [css.rowDeleted]: deleted })}
        data-qa-id="conversation-message-wrapper"
      >
        {!owned && (
          <div className={css.avatarWrap}>
            {!hasPrevious && <ProfileAvatar profile={profile} size={26} onClick={handleProfileClicked} />}
          </div>
        )}
        {
          <div
            role="button"
            tabIndex={0}
            className={classNames(css.content, {
              [css.contentHasPrevious]: hasPrevious,
              [css.contentHasNext]: hasNext,
              [css.contentOwned]: owned,
              [css.contentNotOwned]: !owned
            })}
            onClick={handleOuterContentClick}
            onKeyDown={() => {}}
          >
            {deleted && <div className={css.contentHtml}>{t("chat.deletedMessageInfo")}</div>}
            {!deleted && (
              <>
                {text && (
                  <div
                    className={css.contentHtml}
                    data-qa-id="conversation-message"
                    dangerouslySetInnerHTML={{ __html: fullMarkdown(text) }}
                  />
                )}
                {!deleted && attachments && attachments.length > 0 && (
                  <ul className={classNames(css.attachments, { [css.attachmentsOwned]: owned })}>
                    {attachments.map((attachment) => (
                      <li key={attachment.id}>
                        <DownloadLink attachment={attachment}>
                          <MessageAttachment attachmentName={attachment.file_name} />
                        </DownloadLink>
                      </li>
                    ))}
                  </ul>
                )}
                {expanded && (
                  <div className={css.messageInfoWrap}>
                    <MessageInfo
                      profileName={profile ? getProfileName(profile) : ""}
                      profileViewable={isProfileViewable()}
                      owned={owned}
                      createdAt={moment(createdAt)}
                      handleProfileClicked={handleProfileClicked}
                      handleDeleteClicked={handleDeleteClicked}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        }
      </div>
    </li>
  );
};
