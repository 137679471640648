import { Camelized, camelizeKeys } from "humps";
import { RequestHelpModal } from "PFApp/activities/show/parts/request_help_modal";
import CloseConfirmModal from "PFApp/components/activity_actions/modals/close_confirm_modal";
import { ExpiryDateWarningModal } from "PFApp/components/activity_actions/modals/expiry_date_warning_modal";
import { ReopenActivityModal } from "PFApp/components/reopen_activity_modal";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { useGrowl } from "PFApp/use_growl";
import { DropdownButton } from "PFComponents/dropdown_button";
import { Modal } from "PFComponents/modal";
import { useActivityDelete } from "PFCore/hooks/queries/activity/use_activity_delete";
import DotsIcon from "PFIcons/vertical_dots.svg";
import { Activity } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import css from "./three_dots_menu.module.scss";
import { useThreeDotsMenuOptions } from "./use_three_dots_menu_options";

type ExpiryDateWarningModalData = {
  show: boolean;
  mode?: "edit" | "clone";
};

type ThreeDotsMenuProps = {
  afterRemove: VoidFunction;
  item: Activity;
  type: TemplateKey;
  coowned: boolean;
  pinned?: number[];
  setPinned?: (pinned: number[]) => void;
  share: (id: number) => void;
  onReopenSuccess: VoidFunction;
};

export const ThreeDotsMenu = ({
  afterRemove,
  item,
  type,
  coowned,
  pinned = [],
  setPinned,
  share,
  onReopenSuccess
}: ThreeDotsMenuProps) => {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);
  const [showRequestHelpModal, setShowRequestHelpModal] = useState(false);
  const { t } = useTranslation("workflow", { keyPrefix: "parts.threeDotsMenu" });
  const history = useHistory();

  const { mutateAsync: deleteActivity } = useActivityDelete();

  const template = useTemplate({ key: type });

  const [showExpiryDateModal, setShowExpiryDateModal] = useState(false);
  const [expiryDateWarningModalData, setExpiryDateWarningModalData] = useState<ExpiryDateWarningModalData>({
    show: false
  });

  const growl = useGrowl();

  const handlePinnedChange = useCallback(
    (isPinned) => {
      const newPinned = [...pinned];
      const index = newPinned.indexOf(item.id);
      if (isPinned) {
        index === -1 && newPinned.unshift(item.id);
      } else {
        index !== -1 && newPinned.splice(index, 1);
      }
      setPinned?.(newPinned);
    },
    [item.id, pinned, setPinned]
  );

  const performIfNotExpired = useCallback(
    ({ action, mode }: { action: VoidFunction; mode: ExpiryDateWarningModalData["mode"] }) => {
      const { post_until } = item;
      const isExpired = new Date(post_until) < new Date();
      if (isExpired) {
        setExpiryDateWarningModalData({
          show: true,
          mode
        });
      } else {
        action();
      }
    },
    [item]
  );

  const { options, onClose } = useThreeDotsMenuOptions({
    item,
    pinned,
    type,
    coowned,
    onEdit: () =>
      performIfNotExpired({
        action: () => history.push(`/activities/${item.id}/edit`, { utmSource: "workflow" }),
        mode: "edit"
      }),
    onClone: () =>
      performIfNotExpired({
        action: () => template && history.push(`/activities/${template.key}/new/${item.id}`, "clone"),
        mode: "clone"
      }),
    onReopen: () => setShowExpiryDateModal(true),
    onShare: share ? () => share(item.id) : undefined,
    onRequestHelp: () => setShowRequestHelpModal(true),
    onPrioritySelected: afterRemove,
    onRemove: () => setShowRemoveConfirm(true),
    onClose: () => setShowCloseConfirm(true),
    onPinned: setPinned ? (isPinned) => handlePinnedChange(isPinned) : undefined
  });

  const handleRemove = () => {
    deleteActivity(item.id).then(
      () => {
        growl({ message: t("activityTypeRemove.success", { type }) });
        setPinned && handlePinnedChange(false);
        afterRemove && afterRemove();
      },
      (resp) => {
        const msg = resp.status === 404 ? "activityTypeRemove.error404" : "activityTypeRemove.error";
        growl({ message: t(msg, { type }), kind: "error" });
      }
    );
  };

  return (
    <>
      <DropdownButton
        style={{ marginLeft: "auto" }}
        options={options}
        buttonKind="blank"
        buttonStyle={{ height: 18 }}
        dropDownClassName={css.dropdown}
        dropDownStyle={{ maxHeight: 300 }}
        handleClick={(event) => event.preventDefault()}
        handleChange={(optionItem) => (optionItem.func ? optionItem.func() : optionItem())}
        handleClose={onClose}
        qaId={`WorkflowPageRowMoreButton.${item.id}`}
      >
        <DotsIcon width={20} height={17} />
      </DropdownButton>

      {showRemoveConfirm && (
        <Modal
          title={t("activityTypeRemove.title", { type })}
          onOK={handleRemove}
          onClose={() => setShowRemoveConfirm(false)}
          kindOK="danger"
        >
          {t("activityTypeRemove.confirm", { type })}
        </Modal>
      )}
      {showCloseConfirm && (
        <CloseConfirmModal
          activity={item}
          handleClose={() => setShowCloseConfirm(false)}
          type={type}
          handleSuccess={afterRemove}
        />
      )}

      {expiryDateWarningModalData.show && expiryDateWarningModalData.mode && template && (
        <ExpiryDateWarningModal
          activityId={item.id}
          templateKey={template.key}
          mode={expiryDateWarningModalData.mode}
          onClose={() => setExpiryDateWarningModalData({ show: false })}
        />
      )}
      {showExpiryDateModal && (
        <ReopenActivityModal
          activity={camelizeKeys(item) as Camelized<Activity>}
          onClose={() => setShowExpiryDateModal(false)}
          onSuccess={onReopenSuccess}
        />
      )}

      {showRequestHelpModal && (
        <RequestHelpModal activity={item} onClose={() => setShowRequestHelpModal(false)} />
      )}
    </>
  );
};
