import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Button } from "PFComponents/button";
import ButtonGroup from "PFComponents/button_group/button_group";
import { LoadingDots } from "PFComponents/loading_dots";
import { ObjectWithProfileName } from "PFCore/helpers/profile";
import useAddShortlist from "PFCore/hooks/use_add_shortlist";
import { Activity, Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

type MatchesItemRoleButtonsProps = {
  profile: Pick<Profile, "id"> & ObjectWithProfileName;
  task: Activity;
};

export const MatchesItemRoleButtons = ({ profile, task }: MatchesItemRoleButtonsProps) => {
  const { shortlists } = useActivityPageContext();
  const { shortlistProfile, undoShortlist, loading, isShortlisted, isShortlistsLoaded, shortlistItem } =
    useAddShortlist(profile, task, shortlists);
  const { t } = useTranslation("activities");

  if (!isShortlistsLoaded) {
    return <LoadingDots />;
  }

  const handleShortlist = (event) => {
    event.stopPropagation();
    shortlistProfile();
  };

  const handleUndoShortlist = (event) => {
    event.stopPropagation();
    undoShortlist();
  };

  return (
    <div>
      {isShortlisted ? (
        <ButtonGroup style={{ display: "flex" }}>
          <Button text={t("show.matches.shortlisted")} disabled kind="secondary" style={{ minWidth: 100 }} />
          <Button
            text={t("show.matches.undo")}
            disabled={loading || shortlistItem?.state !== "pending"}
            onClick={handleUndoShortlist}
          />
        </ButtonGroup>
      ) : (
        <Button
          text={t("show.matches.shortlist")}
          disabled={loading}
          kind="secondary"
          onClick={handleShortlist}
          style={{ minWidth: 100 }}
        />
      )}
    </div>
  );
};
