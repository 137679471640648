import { omit } from "lodash";
import { MisalignmentItem, Overbooking } from "PFApp/booking/types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { FeatureFlag } from "PFTypes";
import { useEffect } from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";

import { clearCategoryOrPhaseErrors, setCategoryOrPhaseErrors } from "../booking_form.utils";
import { useBookingFormContext } from "../booking_form_context_provider";
import { BookingFormValues } from "../booking_form_provider";
import { useAreBookingsValid } from "../use_are_bookings_valid";
import { BookingFormMode } from "../use_booking_form";
import { isGroupOption } from "../workforce_member_select/workforce_member_select_dropdown";
import { QueryKeyParams } from "./query_keys";
import { usePotentialWarningsQuery } from "./use_potential_warnings_query";

const POTENTIAL_WARNINGS_LOADING = "potential-warnings-loading";

type UsePotentialWarnings = {
  mode: BookingFormMode;
};
export type PotentialWarnings = {
  overbookings: Overbooking[];
  misalignments: MisalignmentItem[];
  exeedsCalendarHours: boolean;
  includesNonWorkingDays: boolean;
  overbookingIntersectionsDates: string[];
};

export const usePotentialWarnings = ({ mode }: UsePotentialWarnings): Partial<PotentialWarnings> => {
  const isBookingModuleEnabled = useIsFeatureEnabled()(FeatureFlag.BookingModule);
  const { getFormattedErrors } = useResponseErrors();
  const { setLoadingState } = useBookingFormContext();

  const { setError, clearErrors, getValues } = useFormContext<BookingFormValues>();

  const { isValid, isSubmitting } = useFormState();

  const [bookings, activityId, workforceMember, bookingType] = useWatch<BookingFormValues>({
    name: ["bookings", "activityId", "workforceMember", "bookingType"]
  });

  const areBookingsValid = useAreBookingsValid();
  const isFormValid = isValid && areBookingsValid;

  const shouldBeDisabled = !isFormValid || isSubmitting || !workforceMember || isGroupOption(workforceMember);

  const globalValuesArray = useWatch<BookingFormValues>({
    name: ["globalCategory", "globalOverridesDiaryTime", "globalOverridesNonWorkingTime"]
  });

  const queryKeyParams = {
    bookingsData: (bookings ?? []).map((booking) => omit(booking, "title", "description")),
    activityId,
    workforceMemberId: workforceMember?.id,
    bookingType,
    globalValuesArray
  };

  const { data, isLoading } = usePotentialWarningsQuery(
    {
      values: getValues(),
      mode,
      queryKeyParams: queryKeyParams as QueryKeyParams
    },
    {
      enabled: isBookingModuleEnabled && !shouldBeDisabled,
      retry: 0,
      cacheTime: 0,
      select: (response) =>
        ({
          ...response,
          misalignments: activityId ? response.misalignments[activityId]?.[workforceMember.id] || [] : []
        } as PotentialWarnings),
      onSuccess: () => clearCategoryOrPhaseErrors(mode, bookings, clearErrors, "potential-warnings"),
      onError: (err) => {
        if (err.statusText === "abort" || err.code === "ERR_CANCELED") {
          return;
        }

        console.debug("Potential warnings request error", err);

        const responseErrors = getFormattedErrors(err.response.data);
        if (Object.keys(responseErrors).length > 0) {
          setCategoryOrPhaseErrors(
            mode,
            bookings,
            responseErrors,
            setError,
            clearErrors,
            "potential-warnings"
          );
        }
      }
    }
  );

  useEffect(() => {
    if (isBookingModuleEnabled && !shouldBeDisabled) {
      setLoadingState((prev) => ({ ...prev, [POTENTIAL_WARNINGS_LOADING]: isLoading }));
    }
  }, [isLoading, shouldBeDisabled, isBookingModuleEnabled]);

  return (data ?? {}) as Partial<PotentialWarnings>;
};
