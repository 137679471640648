import { map } from "lodash";
import { ActivityConnectionsEvent, GenericDiff } from "PFTypes";

import { HistoryEntryTemplate } from "../../components/history_entry_template";
import { HistoryUpdatedAttributes } from "../../components/history_updated_attributes/history_updated_attributes";
import { useHumanizedAcitivityConnectionEventDiff } from "./use_humanized_acitivity_connection_event_diff";

export const HistoryEntryActivityConnections = ({ entry }: { entry: ActivityConnectionsEvent }) => {
  const eventDiff = useHumanizedAcitivityConnectionEventDiff(entry.diff);

  const renderHistoryContent = () =>
    map(eventDiff, (diff, key) => (
      <HistoryUpdatedAttributes label={key} level={1} updatedAttributes={diff as GenericDiff} />
    ));

  return <HistoryEntryTemplate entry={entry}>{renderHistoryContent()}</HistoryEntryTemplate>;
};
