import { filter, find } from "lodash";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import EMOJIS from "PFComponents/text/emojis";
import { getProfileName } from "PFCore/helpers/profile";
import { fetchMentionables } from "PFCore/services/chat/fetch_mentionables";
import { Profile, ProfileMinimized } from "PFTypes";
import { useMemo, useRef } from "react";
import { startsWith } from "underscore.string";

import { Typography } from "../../typography";
import { ChatInputFieldSetProps } from "./chat_input_field_set";
import css from "./chat_input_field_set.module.scss";

type UseAutcompleteDataProps = {
  displayEmojis: boolean;
  displayMentions?: boolean;
  profileId: number;
  conversationId: number;
  value: ChatInputFieldSetProps["value"];
};

export type AutocompleteDropdown = {
  trigger: RegExp;
  data: (value, cb) => void;
};

type AutocompleteReplace = { pattern: RegExp; replace: (string: string) => void };

export const useAutcompleteData = ({
  displayEmojis,
  displayMentions,
  profileId,
  conversationId,
  value
}: UseAutcompleteDataProps): {
  autoCompleteDropdowns: AutocompleteDropdown[];
  mentions: { [key: string]: Profile };
  autoCompleteReplace: AutocompleteReplace[];
} => {
  const { current: emojisAutocomplete } = useRef<AutocompleteDropdown>({
    trigger: /:[a-z]*$/,
    data: (value, cb) => {
      cb(
        filter(EMOJIS, (emoji) => startsWith(emoji.pattern, value)).map((emoji) => ({
          id: emoji.pattern,
          displayElement: <span>{`${emoji.display} ${emoji.pattern}`}</span>,
          item: () => emoji.display
        }))
      );
    }
  });

  const mentionsAutocomplete = useMemo<AutocompleteDropdown>(
    () => ({
      trigger: /@\S* ?\S*$/,
      data: (value, cb) => {
        cb([
          {
            id: 0,
            displayElement: <span>...</span>,
            item: false
          }
        ]);
        fetchMentionables({ profileId, conversationId, term: value.replace("@", "") }).then(
          ({ entries: mentionables }) => {
            cb(
              mentionables.map((mention) => ({
                id: mention.id,
                displayElement: (
                  <>
                    <div className={css.mentionable}>
                      <ProfileAvatar profile={mention as ProfileMinimized} size={16} />
                      <Typography variant="bodyRegular" noMargin>
                        {getProfileName(mention)}
                      </Typography>
                      <Typography variant="labelRegular" noMargin>
                        {mention.email}
                      </Typography>
                    </div>
                  </>
                ),
                item: () => {
                  let format = getProfileName(mention);

                  if (mentions[format] && mentions[format].id !== mention.id) {
                    format = `${format}[${mention.email}]`;
                  }

                  mentions[format] = mention;

                  return `@${format}`;
                }
              }))
            );
          }
        );
      }
    }),
    [profileId, conversationId]
  );
  const [autoCompleteDropdowns, mentions] = useMemo(() => {
    const mentions = {};
    const autoCompleteDropdowns: AutocompleteDropdown[] = displayEmojis ? [emojisAutocomplete] : [];

    if (displayMentions) {
      autoCompleteDropdowns.push(mentionsAutocomplete);
    }
    return [autoCompleteDropdowns, mentions];
  }, [displayEmojis, displayMentions, emojisAutocomplete, mentionsAutocomplete, value]);

  const autoCompleteReplace = useMemo(
    () =>
      displayEmojis
        ? []
        : [
            {
              pattern: /:.*:?/g,
              replace: (matched) => {
                const emoji = find(EMOJIS, { pattern: matched });
                return emoji ? emoji.display : matched;
              }
            }
          ],
    [displayEmojis]
  );
  return { autoCompleteDropdowns, mentions, autoCompleteReplace };
};
