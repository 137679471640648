import {
  fetchPotentialWarningsForBookingTemplates,
  fetchPotentialWarningsForSingleBookings,
  PotentialWarningsResponse
} from "PFApp/booking/services/api";
import { Profile } from "PFTypes";

import { BookingFormValues, BookingType } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";
import { isNotLimited } from "../use_handle_submit/use_booking_payload/use_booking_payload";
import { mapToBookingTemplatePayload, mapToSingleBookingPayload } from "./payload.utils";

type FetchPotentialWarnings = {
  values: BookingFormValues;
  mode: BookingFormMode;
};

export const fetchPotentialWarnings = ({
  values,
  mode
}: FetchPotentialWarnings): Promise<PotentialWarningsResponse> => {
  const { workforceMember, bookings, bookingType } = values;
  const isRepeated = bookingType === BookingType.Repeated;
  const profileId = (workforceMember as Profile)?.id;
  if (!isRepeated) {
    const potentialBookings = bookings
      .filter(isNotLimited)
      .map((item) => mapToSingleBookingPayload(item, values, mode));
    return fetchPotentialWarningsForSingleBookings(profileId, potentialBookings);
  } else {
    const potentialBookingTemplates = bookings
      .filter(isNotLimited)
      .map((item) => mapToBookingTemplatePayload(item, values, mode, profileId));
    return fetchPotentialWarningsForBookingTemplates(profileId, potentialBookingTemplates);
  }
};
