import { EditMode } from "PFApp/booking/types/edit_mode";
import { createContext, useContext, useReducer } from "react";

export type EditModeState = {
  editMode: EditMode;
  isEditModeEnabled: boolean;
};

export type EditModeApi = {
  setEditMode: (mode: EditMode) => void;
};

const INITIAL_EDIT_MODE_STATE: EditModeState = {
  editMode: EditMode.Disabled,
  isEditModeEnabled: false
};

const INITIAL_EDIT_MODE_API: EditModeApi = {
  setEditMode: () => {}
};

export const EditModeStateContext = createContext<EditModeState>(INITIAL_EDIT_MODE_STATE);
export const EditModeApiContext = createContext<EditModeApi>(INITIAL_EDIT_MODE_API);

type EditModeActions = {
  type: "setEditMode";
  editMode: EditMode;
};

const editModeReducer = (state: EditModeState, action: EditModeActions): EditModeState => {
  switch (action.type) {
    case "setEditMode":
      return {
        ...state,
        editMode: action.editMode,
        isEditModeEnabled: action.editMode !== EditMode.Disabled
      };
    default:
      return state;
  }
};

export const useEditModeReducer = () => useReducer(editModeReducer, INITIAL_EDIT_MODE_STATE);

export const useBookingsEditModeStateContext = () => useContext(EditModeStateContext);
export const useBookingsEditModeApiContext = () => useContext(EditModeApiContext);
