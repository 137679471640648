import find from "lodash/find";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { useInvites } from "PFCore/hooks/queries/invites/use_invites";
import { useSendInvitation } from "PFCore/hooks/queries/invites/use_send_invitation";
import { Activity, Profile } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type DisabledButtonProps = { text: string };

export const DisabledButton = ({ text }: DisabledButtonProps) => (
  <Button text={text} kind="secondary" disabled style={{ minWidth: 80 }} />
);

type InviteButtonProps = {
  profile: Pick<Profile, "id">;
  task: Activity;
};

export const InviteButton = ({ profile, task }: InviteButtonProps) => {
  const { t } = useTranslation("activities");
  const growl = useGrowl();

  const { data: invites } = useInvites(task.id);
  const { mutate: sendInvitation, isLoading } = useSendInvitation([task.id], [profile.id]);

  const invitation = find(invites, { profile_id: profile.id });
  const isInvited = !!invitation;
  const isParticipant = invitation && invitation.state === "accepted";
  const [invitationSubmitted, markInvitationSubmit] = useState(false);

  const handleInvitationSuccess = () => {
    markInvitationSubmit(true);

    growl({
      message: t("show.parts.taskInviteSuccess")
    });
  };

  const handleInvitationFailed = (resp) => {
    if (resp.responseJSON.errors.profile_id[0] === "has already been taken") {
      markInvitationSubmit(true);
      return;
    }

    growl({
      kind: "error",
      message: t("show.parts.taskInviteError")
    });
  };

  if (!invites) {
    return null;
  }

  if (isParticipant) {
    return <DisabledButton text={t("show.parts.responded")} />;
  }

  if (isInvited || invitationSubmitted) {
    return <DisabledButton text={t("show.matches.invited")} />;
  }

  if (isLoading) {
    return <DisabledButton text="..." />;
  }

  return (
    <Button
      text={t("show.parts.invite")}
      style={{ minWidth: 80, marginRight: 5 }}
      kind="secondary"
      qaId="shortlist-invite"
      onClick={() =>
        sendInvitation(null, {
          onSuccess: handleInvitationSuccess,
          onError: handleInvitationFailed
        })
      }
    />
  );
};
