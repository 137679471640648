import { TippyProps } from "@tippyjs/react";
import { ActionIcon } from "PFComponents/action_icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";

import { ContactInfo } from "../use_contact_info_array";

type ContactIconProps = ContactInfo & {
  tooltipAppendTo?: TippyProps["appendTo"];
};

export const ContactIcon = ({
  value,
  url,
  target,
  iconName,
  title,
  onClick,
  tooltipAppendTo
}: ContactIconProps) => (
  <TooltipIcon
    content={value}
    iconColor={null}
    tooltipProps={{
      appendTo: tooltipAppendTo
    }}
    IconComponent={() =>
      url ? (
        <ActionIcon
          tag="a"
          href={url}
          target={target}
          rel="noopener noreferrer"
          name={iconName}
          size="sm"
          color="paletteBlue0"
          title={title}
        />
      ) : (
        <ActionIcon onClick={onClick} name={iconName} size="sm" color="paletteBlue0" title={title} />
      )
    }
  />
);
