import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { AuditRoleProfileActionButtons } from "PFApp/audit_planner/parts/activity_buttons/audit_role_profile_action_buttons";
import { useMemo } from "react";

import {
  RoleProfileActionButtonsContent,
  RoleProfileActionButtonsContentProps
} from "./action_buttons/role_profile_action_buttons";
import {
  RoleProfileActionButtonsProvider,
  RoleProfileActionButtonsProviderProps
} from "./context/role_profile_action_buttons_context";

export type RoleProfileActionButtonsWrapperProps = {
  isShortlistTabActive: boolean;
  isCompareView?: boolean;
} & RoleProfileActionButtonsProviderProps &
  RoleProfileActionButtonsContentProps;

export const RoleProfileActionButtonsWrapper = ({
  customButton,
  areButtonsDisabled = false,
  small = false,
  className,
  isShortlistTabActive,
  isCompareView,
  ...props
}: RoleProfileActionButtonsWrapperProps) => {
  const { isAudit } = useActivityPageContext();

  const buttonsContent = useMemo(() => {
    if (isAudit && !isCompareView) {
      return (
        <AuditRoleProfileActionButtons
          isShortlistTabActive={isShortlistTabActive}
          customButton={customButton}
        />
      );
    }

    return <RoleProfileActionButtonsContent customButton={customButton} className={className} />;
  }, [isAudit, customButton, isCompareView, className, isShortlistTabActive]);

  return (
    <RoleProfileActionButtonsProvider {...props} areButtonsDisabled={areButtonsDisabled} small={small}>
      {buttonsContent}
    </RoleProfileActionButtonsProvider>
  );
};
