import { useActivityCurrentUserRole } from "PFApp/activities/hooks";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import ButtonLink from "PFComponents/button/button_link";
import { Typography } from "PFComponents/typography";
import NoShortlistIllustration from "PFTheme/graphics/illustrations/illustration-no-shortlist.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import css from "./shortlist_empty_state.module.scss";

export const ShortlistEmptyState = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist" });
  const { task: activity, canSeeMatches } = useActivityPageContext();
  const location = useLocation();

  const { isReviewer } = useActivityCurrentUserRole(activity);

  return (
    <div className={css.noItems}>
      <NoShortlistIllustration />
      <Typography variant="h1">{t("noShortlistedCandidates")}</Typography>

      {activity.coowned && (
        <Typography variant="h5" className={css.subtitle}>
          {t("shortlistVisibleToYou")}
          <br />
          {t("wontNotifyAnyone")}
        </Typography>
      )}
      {!isReviewer && canSeeMatches && (
        <ButtonLink href={`/activities/${activity.id}/matches${location.search}`}>
          {t("viewMatches")}
        </ButtonLink>
      )}
    </div>
  );
};
