import { Button, ButtonProps } from "PFComponents/button";
import { Activity } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AddActivityProfilesModal, AddProfilesTabs } from "./activity_profiles/add_activity_profiles_modal";

type ChangeAssigneeProps = {
  buttonKind?: ButtonProps["kind"];
  buttonStyle?: ButtonProps["style"];
  activity: Activity;
};

export const ChangeAssigneeButton = ({ activity, buttonKind, buttonStyle }: ChangeAssigneeProps) => {
  const [showAssingee, setShowAssignee] = useState(false);
  const { t } = useTranslation("activities");

  return (
    <>
      <Button
        text={t("show.parts.setAssignee")}
        kind={buttonKind || "secondary"}
        style={buttonStyle}
        qaId="activity-change-assignee"
        onClick={() => setShowAssignee(true)}
      />
      {showAssingee && (
        <AddActivityProfilesModal
          onClose={() => setShowAssignee(false)}
          activity={activity}
          tabId={AddProfilesTabs.Assignee}
        />
      )}
    </>
  );
};
