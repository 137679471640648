import AppContext from "PFApp/app_context";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { CustomValueScope } from "PFTypes";
import { useContext } from "react";

import { CustomValuePreviewContextProvider } from "./context/custom_value_preview_context";
import { ActivityCustomValuePreview } from "./views/activity_custom_value_preview";
import { ProfileCustomValuePreview } from "./views/profile_custom_value_preview";

export const CustomValuePreview = () => {
  const {
    store: { customValuePreview }
  } = useContext(AppContext);
  const { getCustomTypeById } = useCustomTypes();

  const { customTypeId, scope } = customValuePreview;

  const customType = getCustomTypeById(customTypeId);

  if (!customType || ![CustomValueScope.Activity, CustomValueScope.Profile].includes(scope)) {
    return null;
  }

  return (
    <CustomValuePreviewContextProvider customType={customType}>
      {scope === CustomValueScope.Activity && <ActivityCustomValuePreview />}
      {scope === CustomValueScope.Profile && <ProfileCustomValuePreview />}
    </CustomValuePreviewContextProvider>
  );
};
