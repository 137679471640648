import { Divider } from "PFComponents/divider";
import { ActivityEvent } from "PFTypes";

import { useEventsProfiles } from "../../../hooks/use_events_profiles";
import css from "../history_entry.scss";
import { HistoryEntryHeader } from "./history_entry_header";

type HistoryEntryTemplateProps = {
  entry: ActivityEvent;
  children: React.ReactNode;
};

export const HistoryEntryTemplate = ({
  entry: { createdAt, event, operator },
  children
}: React.PropsWithChildren<HistoryEntryTemplateProps>) => {
  const { findProfile, isLoading } = useEventsProfiles();
  const profile = findProfile(operator?.id);

  return (
    <div className={css.entry}>
      <HistoryEntryHeader isLoading={isLoading} profile={profile} event={event} createdAt={createdAt} />
      <div className={css.entryContent}>{children}</div>
      <Divider />
    </div>
  );
};
