import {
  CollisionDetection,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import classNames from "classnames";

import { ActionIcon } from "../action_icon";
import Pill from "../pill/pill";
import Toggle from "../toggle/toggle";
import { Typography } from "../typography";
import css from "./sortable_list.module.scss";

export enum ListAction {
  Sort = "sort",
  Removal = "removal",
  Toggle = "toggle"
}

type SortableListItem = {
  id: string;
  label: string | React.ReactNode;
  toggled?: boolean;
  actionDisabled?: boolean;
};

export type SortableListProps = {
  onChange: (sortedList: SortableListItem[], itemId: string, listAction: ListAction) => void;
  onToggle?: (itemId: string, value: boolean) => void;
  strategy: typeof horizontalListSortingStrategy | typeof verticalListSortingStrategy;
  items: SortableListItem[];
  collisionDetection?: CollisionDetection;
  action?: Omit<ListAction, ListAction.Sort>;
  disabled?: boolean;
};

export const SortableList = ({
  onChange,
  onToggle,
  strategy,
  items,
  collisionDetection,
  action,
  disabled
}: SortableListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const newOrder = arrayMove(items, oldIndex, newIndex);
      onChange(newOrder, active.id, ListAction.Sort);
      return newOrder;
    }
  };

  const handleRemove = (itemId: string) => {
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const listCopy = [...items];
    listCopy.splice(itemIndex, 1);
    onChange(listCopy, itemId, ListAction.Removal);
  };

  return (
    <DndContext sensors={sensors} collisionDetection={collisionDetection} onDragEnd={handleDragEnd}>
      <SortableContext strategy={strategy} items={items}>
        {items.map((item) => (
          <SortableItem
            key={item.id}
            id={item.id}
            label={item.label}
            onRemove={handleRemove}
            onToggle={onToggle}
            strategy={strategy}
            toggled={item.toggled}
            action={action}
            disabled={disabled}
            actionDisabled={item.actionDisabled}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

type SortableItemProps = {
  id: string;
  label: string | React.ReactNode;
  toggled?: boolean;
  disabled?: boolean;
  actionDisabled?: boolean;
  onRemove: (id: string) => void;
  onToggle?: (id: string, value: boolean) => void;
  strategy: typeof horizontalListSortingStrategy | typeof verticalListSortingStrategy;
  action?: Omit<ListAction, ListAction.Sort>;
};

const SortableItem = ({
  label,
  id,
  onRemove,
  onToggle,
  strategy,
  toggled,
  action,
  disabled,
  actionDisabled
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id
  });

  const style = {
    transform: transform
      ? `translate3d(${strategy === horizontalListSortingStrategy ? transform.x : 0}px, ${
          strategy === verticalListSortingStrategy ? transform.y : 0
        }px, 0)`
      : undefined
  };

  return (
    <Pill ref={setNodeRef} className={css.cell} style={style}>
      {!disabled && (
        <ActionIcon
          name={
            strategy === horizontalListSortingStrategy ? "arrow-2-directions" : "arrow-2-directions-vertical"
          }
          {...listeners}
          {...attributes}
          size="xs"
          cursor="pointer"
          className={css.dragHandle}
        />
      )}
      <Typography
        variant="bodyBold"
        noMargin
        className={classNames(css.label, { [css.offsetLeft]: disabled })}
      >
        {label}
      </Typography>
      {action === ListAction.Removal && (
        <ActionIcon name="cross" size="xs" onClick={() => onRemove(id)} disabled={actionDisabled} />
      )}
      {action === ListAction.Toggle && (
        <Toggle
          onChange={(value) => onToggle?.(id, value)}
          checked={toggled}
          disabled={actionDisabled}
          compact
        />
      )}
    </Pill>
  );
};
