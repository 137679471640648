import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { AccessLevel, Template } from "PFTypes";

type UseCurrentGroupPermissionsReturn = {
  getActivityPermissions: (template: Template | undefined) => AccessLevel | undefined;
};

export const useCurrentGroupPermissions = (): UseCurrentGroupPermissionsReturn => {
  const { data: currentProfile } = useCurrentProfile();
  const ownGroup = currentProfile.permissions_group;
  const ownGroupPermissions = ownGroup.cross_group_permissions[ownGroup.id];

  const getActivityPermissions = (template: Template | undefined): AccessLevel | undefined => {
    if (!template) {
      return undefined;
    }
    return ownGroupPermissions.activities_access_levels[template.id];
  };

  return {
    getActivityPermissions
  };
};
