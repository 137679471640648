import { getGrowlId, useGrowl } from "PFApp/use_growl";
import { Modal } from "PFComponents/modal";
import { getProfileName } from "PFCore/helpers/profile";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import DeleteBookingIcon from "PFIcons/calendar_delete.svg";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { undoBooking } from "../../../services/api";
import { useBookingActivityContext } from "../../providers/booking_activity_context_provider";
import { useSyncRows } from "../use_sync_rows";
import css from "./undo_booking_modal.module.scss";

const UNDO_BOOKING_SUCCESS_ACTION_ID = getGrowlId("bm-undo-booking");

const UndoBookingModal = ({ onClose, item, activityName, parentActivityId }) => {
  const { id } = useBookingActivityContext();
  const { t } = useTranslation("bookingModule");
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { invalidate: invalidateActivity } = useActivityInvalidate();
  const growl = useGrowl();
  const syncRows = useSyncRows();
  const talentName = getProfileName(item.profile);
  const { invalidateProfile } = useProfileInvalidate();
  const { invalidateVacancies } = useActivityInvalidate();
  const { getFirstError } = useResponseErrors();

  const handleUndo = async () => {
    try {
      await undoBooking(item.id);
      growl({
        id: UNDO_BOOKING_SUCCESS_ACTION_ID,
        kind: "success",
        message: t("bookings.delete.success"),
        hidePreviousByTypeAndId: true,
        ttl: 5000
      });
      await syncRows({
        profileId: item.profile.id,
        activityId: item.activity_id,
        parentActivityIds: [parentActivityId]
      });
      await invalidateProfile(item.profile.id);
      await invalidateShortlists(id);
      await invalidateActivity([id]);
      await invalidateVacancies(id);
    } catch (response) {
      const error = getFirstError(response);
      growl({
        message: error || t("somethingWentWrong"),
        kind: "error"
      });
    }
  };

  return (
    <Modal title={t("bookings.undo.header")} onClose={onClose} onOK={handleUndo} kindOK="danger">
      <div className={css.headerIcon}>
        <DeleteBookingIcon height={30} width={30} />
      </div>
      <div className={css.description}>
        <Trans
          t={t}
          i18nKey={"bookings.undo.confirm"}
          values={{ roleName: activityName, talentName }}
          // eslint-disable-next-line react/jsx-key
          components={[<strong />, <br></br>]}
        />
      </div>
    </Modal>
  );
};

UndoBookingModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    activity_id: PropTypes.number, // eslint-disable-line camelcase
    profile: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string, // eslint-disable-line camelcase
      last_name: PropTypes.string // eslint-disable-line camelcase
    })
  }),
  onClose: PropTypes.func,
  activityName: PropTypes.string,
  parentActivityId: PropTypes.number
};

export default UndoBookingModal;
