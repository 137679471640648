import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useSidePanelClose } from "PFComponents/side_panel/use_side_panel_close";
import { isRankable } from "PFCore/helpers/custom_type";
import { ownProfileCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { getProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { useCustomValue } from "PFCore/hooks/queries/custom_fields/use_custom_value";
import { useCustomValueInsights } from "PFCore/hooks/queries/custom_fields/use_custom_value_insights";
import { useProfileCustomValue } from "PFCore/hooks/queries/custom_fields/use_profile_custom_value";
import { useCustomValuesCreate } from "PFCore/hooks/queries/custom_values/use_custom_values_create";
import { useDictionaryConnectionCreate } from "PFCore/hooks/queries/custom_values/use_dictionary_connection_create";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { useFirstLoad } from "PFCore/hooks/use_first_load";
import { AccessLevel, Experience } from "PFTypes";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useCustomValuePreviewContext } from "../context/custom_value_preview_context";
import css from "../custom_value_preview.module.scss";
import { CustomValueSelect } from "../parts/custom_value_select";
import { ProfileCustomValueFooter } from "../parts/footer/profile_custom_value_footer";
import { ProfileCustomValueContent } from "./parts/profile_custom_value_content";

export const ProfileCustomValuePreview = () => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const { t: tActivities } = useTranslation("activities");
  const { pathname } = useLocation();
  const isFirstLoad = useFirstLoad();
  const { data: currentProfile } = useCurrentProfile();
  const { unmountPreview } = useCustomValuePreviewActions();
  const {
    show,
    customType,
    allowSearch,
    profileId,
    canEdit,
    isNew,
    isClosing: isClosingPreviewState,
    customValueId,
    setSelectedCustomValueId,
    isBlindModeOn
  } = useCustomValuePreviewContext();

  // TODO: [SP-2573] Consider moving it to Sidepanel component
  const { isClosing, onSidePanelClose: closeWithAnimation } = useSidePanelClose({
    isSidePanelClosing: isClosingPreviewState,
    onClose: unmountPreview
  });

  useEffect(() => {
    // Close sidepanel when page is changed
    if (isFirstLoad) {
      return;
    }

    closeWithAnimation();
  }, [pathname, closeWithAnimation]);

  const { data: profile, isFetching: isFetchingProfile } = useProfile(profileId!, {
    enabled: show && !isClosing && !isBlindModeOn
  });

  const { data: customValue, isFetching: isFetchingCustomValue } = useCustomValue(customValueId!, {
    enabled: Boolean(show && customValueId && !isClosing)
  });

  const connectionQueryEnabled = Boolean(show && customValueId && !isNew && !isClosing);
  // TODO: [SP-2554] useProfileCustomValue should be replaced with new endpoint for profile connection only
  const { data: profileConnectionData, isFetching: isFetchingProfileConnection } = useProfileCustomValue(
    customValueId!,
    profileId!,
    {
      enabled: connectionQueryEnabled
    }
  );

  const profileConnection = connectionQueryEnabled ? profileConnectionData : undefined;

  const { data: insights, isFetching: isFetchingInsights } = useCustomValueInsights(customValueId!, {
    enabled: Boolean(show && customValueId && !isClosing)
  });

  const { mutateAsync: createCustomValue, isLoading: isCreatingCustomValue } = useCustomValuesCreate();
  const { mutateAsync: createDictionaryConnection, isLoading: isUpdating } = useDictionaryConnectionCreate();

  const [experience, setExperience] = useState<Experience | null>(null);
  const [isCore, setIsCore] = useState(false);
  const [isDevelopmental, setIsDevelopmental] = useState(false);

  useEffect(() => {
    setExperience(profileConnection?.experience ?? null);
    setIsCore(profileConnection?.top ?? false);
    setIsDevelopmental(profileConnection?.developmental ?? false);
  }, [profileConnection]);

  const onResetForm = useCallback(() => {
    setSelectedCustomValueId(null);
    setExperience(null);
    setIsCore(false);
    setIsDevelopmental(false);
  }, [setSelectedCustomValueId]);

  useEffect(() => {
    if (allowSearch) {
      onResetForm();
    }
  }, [allowSearch, onResetForm]);

  const rankable = isRankable(customType);
  const isLoading =
    isFetchingProfile ||
    isFetchingCustomValue ||
    isFetchingProfileConnection ||
    isFetchingInsights ||
    isCreatingCustomValue;

  const isAllDataFetched =
    (isBlindModeOn || !!profile) && !!customValue && (isNew || !!profileConnection) && insights;

  const isMe = profileId === currentProfile.id;

  const title = isNew && !customValue ? t("addCustomValue") : customValue?.text;
  const subtitle =
    isMe && canEdit
      ? customType?.display_as
      : isBlindModeOn
      ? tActivities("parts.candidateHash", { profileHash: profileHash(Number(profileId)) })
      : getProfileName(profile);
  const showSubtitle = !!title && (!isNew || !!customValue);
  const showCustomValueSelect = !isLoading && isNew && !customValue && allowSearch;
  const showContent = !isLoading && isAllDataFetched;

  const allowEdit =
    !!canEdit &&
    isMe &&
    ownProfileCustomTypesAccessLevels(currentProfile)?.[customType.id] === AccessLevel.ReadWrite;

  const isFormFilled = !rankable || experience !== null;

  const renderFooter = useCallback(
    ({ onSidePanelClose }) => (
      <ProfileCustomValueFooter
        customValue={customValue}
        experience={experience}
        isCore={isCore}
        isDevelopmental={isDevelopmental}
        allowEdit={allowEdit}
        isRemoveDisabled={isLoading}
        isSaveDisabled={isLoading || !customValue || !isFormFilled}
        isSaveTooltipVisible={!!customValue && !isFormFilled}
        createDictionaryConnection={createDictionaryConnection}
        closeWithAnimation={closeWithAnimation}
        onClose={onSidePanelClose}
      />
    ),
    [
      allowEdit,
      closeWithAnimation,
      createDictionaryConnection,
      customValue,
      experience,
      isCore,
      isDevelopmental,
      isFormFilled,
      isLoading
    ]
  );

  return (
    <SidePanel
      show={show}
      title={title}
      subTitle={showSubtitle ? subtitle : undefined}
      isSidePanelClosing={isClosing}
      footerRenderer={renderFooter}
      onClose={unmountPreview}
    >
      {!!isUpdating && (
        <div className={css.savingOverlay}>
          <LoadingDots circlesEnabled circleSize="xl" />
        </div>
      )}
      <div className={css.sidePanelRoot}>
        {isLoading && <LoadingDots centered circlesEnabled circleSize="sm" />}
        {showCustomValueSelect && (
          <CustomValueSelect customType={customType} createCustomValue={createCustomValue} />
        )}
        {showContent && (
          <ProfileCustomValueContent
            experience={experience}
            isCore={isCore}
            isDevelopmental={isDevelopmental}
            customValue={customValue}
            profileConnection={profileConnection}
            insights={insights}
            allowEdit={allowEdit}
            onExperienceChange={setExperience}
            onCoreChange={setIsCore}
            onDevelopmentalChange={setIsDevelopmental}
            onResetForm={onResetForm}
          />
        )}
      </div>
    </SidePanel>
  );
};
