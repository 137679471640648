import { useQuery } from "@tanstack/react-query";
import { fetchProfilesOptions, ProfilesOptionsParams } from "PFCore/services/profile";
import { BasicProfile, Collection } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { profilesKeys } from "./query_keys";

export const useProfilesOptions = (
  params: ProfilesOptionsParams,
  options: QueryOptions<Collection<BasicProfile[]>> = {}
) =>
  useQuery<Collection<BasicProfile[]>>(
    profilesKeys.options(params),
    () => fetchProfilesOptions(params),
    options
  );
