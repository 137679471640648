import { intersection, isEmpty } from "lodash";
import { CalendarModeData } from "PFApp/booking/types";
import { getValueFilters, handleRequestFiltersReplace } from "PFApp/use_filtered_collection";
import { useGrowl } from "PFApp/use_growl";
import { stringify } from "PFCore/helpers/use_deterministic_stringify";
import {
  useWorkforceEntries,
  WorkforceResponse
} from "PFCore/hooks/queries/bookings/workforce/use_workforce_entries";
import { CalendarRange } from "PFTypes/calendar_range";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFiltersWithPostAction } from "./use_filters_with_post_action";
import { useOrderWithPostActions } from "./use_order_with_post_actions";
import { checkIfShouldUpdateOrderToMostRelevant, MOST_RELEVANT_ORDER } from "./use_tab_data.utils";

const DEFAULT_META = {
  filters: {},
  page: 1,
  total: 0,
  perPage: 0,
  totalPages: 0,
  search_id: undefined
};

const DEFAULT_DATA = {
  data: { entries: [], meta: DEFAULT_META, loading: false },
  loaded: false,
  noFiltersSelected: true,
  noOrderSelected: true,
  updateFilter: () => {},
  setFilters: () => {},
  resetFilters: () => {},
  onOrderChange: () => {}
};

const VERSION = 3;
export const getStoragePrefix = () => `booking_module_overview_workforce-v${VERSION}`;
export const DEFAULT_ORDER = { name: ["profile.name"], text: "A-Z", direction: "asc" };
const PER_PAGE = 15;

interface UseWorkforceData {
  dateRange: CalendarRange;
  enabled: boolean;
  page: number;
  resetPagination: VoidFunction;
}

const useWorkforceData = ({
  dateRange,
  enabled,
  page,
  resetPagination
}: UseWorkforceData): CalendarModeData<WorkforceResponse> => {
  const [workforce, setWorkforce] = useState<CalendarModeData<WorkforceResponse>>(DEFAULT_DATA);
  const storagePrefix = getStoragePrefix();
  const growl = useGrowl();
  const { t } = useTranslation("bookingModule");

  const { selectedOrder, setSelectedOrder, noOrderSelected } = useOrderWithPostActions({
    storagePrefix,
    options: workforce?.data?.meta?.order?.options,
    postChangeAction: () => resetPagination(),
    defaultValue: DEFAULT_ORDER
  });

  const postFiltersChangeAction = useCallback(
    (...args: any) => {
      resetPagination();
      const shouldUpdateOrderToMostRelevant = checkIfShouldUpdateOrderToMostRelevant(args);
      if (shouldUpdateOrderToMostRelevant) {
        setSelectedOrder(MOST_RELEVANT_ORDER);
        return;
      }
      if (!("name" in selectedOrder)) {
        return;
      }
      if (!isEmpty(intersection(["billable_utilization", "available_minutes"], selectedOrder.name))) {
        setSelectedOrder(DEFAULT_ORDER);
        growl({
          message: t("workforce.sortingReset"),
          kind: "alert"
        });
      }
    },
    [selectedOrder, setSelectedOrder, growl, t, resetPagination]
  );

  const { selectedFilters, noFiltersSelected, setFiltersRaw, setFilters, updateFilter, resetFilters } =
    useFiltersWithPostAction({
      storagePrefix,
      postChangeAction: postFiltersChangeAction
    });

  const { data, isLoading, isFetching, error } = useWorkforceEntries(
    page,
    PER_PAGE,
    selectedOrder,
    selectedFilters,
    dateRange,
    workforce.data.meta.search_id,
    {
      enabled
    }
  );

  useEffect(() => {
    setWorkforce((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        loading: isLoading || isFetching
      }
    }));
  }, [isLoading, isFetching]);

  const stringifiedFilters = stringify(selectedFilters);

  useEffect(() => {
    const basicFiltersAndOrderState = {
      updateFilter,
      setFilters,
      resetFilters,
      onOrderChange: setSelectedOrder,
      noOrderSelected
    };
    if (!data) {
      setWorkforce((prev) => ({ ...prev, ...basicFiltersAndOrderState }));
      return;
    }
    const { entries = [], meta = DEFAULT_META } = data;

    // we need to update filters schema on meta change, but keep selected filters values
    // this way we avoid another api call when default filters are configured,
    // or children toogle was turned off
    const valueFilters = getValueFilters(meta.filters);
    const newSelectedFilters = handleRequestFiltersReplace(valueFilters, selectedFilters);
    setFiltersRaw(newSelectedFilters);

    setWorkforce((prev) => ({
      data: {
        ...prev.data,
        entries,
        meta,
        loading: false
      },
      loaded: true,
      noFiltersSelected,
      previousSearchId: data?.meta.search_id,
      ...basicFiltersAndOrderState
    }));
  }, [data, stringifiedFilters]);

  useEffect(() => {
    setWorkforce((prev) => ({ ...prev, setFilters, updateFilter, resetFilters }));
  }, [selectedOrder]);

  useEffect(() => {
    if (!!error && !error.status) {
      growl({
        message: t("workforce.fetchingError"),
        kind: "error"
      });
      setSelectedOrder(DEFAULT_ORDER);
    }
  }, [error]);

  return workforce;
};

export default useWorkforceData;
