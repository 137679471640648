import { find } from "lodash";
import { MultiToggle } from "PFComponents/multi_toggle";
import { Typography } from "PFComponents/typography";
import { Importance } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DataEntry } from "./data_entry";

type ImportanceToggleProps = {
  importance: Importance | null;
  canEdit?: boolean;
  onChange: (value: Importance) => void;
};

export const ImportanceToggle = ({ importance, canEdit, onChange }: ImportanceToggleProps) => {
  const { t } = useTranslation();

  const importanceOptions = useMemo(
    () => [
      {
        id: Importance.Supporting,
        value: t("importance.supporting")
      },
      {
        id: Importance.Essential,
        value: t("importance.essential")
      },
      {
        id: Importance.Required,
        value: t("importance.hardFilter")
      }
    ],
    [t]
  );

  return canEdit ? (
    <MultiToggle
      label={`${t("customValueSidepanel.importance")} *`}
      options={importanceOptions}
      controlledValue={importance}
      onChange={onChange}
    />
  ) : (
    <DataEntry
      label={t("customValueSidepanel.importance")}
      value={
        <Typography variant="bodyBold" noMargin>
          {find(importanceOptions, ["id", importance])?.value}
        </Typography>
      }
    />
  );
};
