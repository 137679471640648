import { ContactInformation } from "PFApp/components/contact_information";
import { Typography } from "PFComponents/typography";
import { Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./contact_information_section.module.scss";

type ContactInformationSectionProps = {
  profile: Profile;
};

export const ContactInformationSection = ({ profile }: ContactInformationSectionProps) => {
  const { t } = useTranslation("profiles");

  return (
    <section className={css.contactInformationSection}>
      <Typography variant="labelBold">{t("show.parts.contactUser", { name: profile.first_name })}</Typography>
      <ContactInformation profile={profile} showLabels={false} tooltipAppendTo={document.body} />
    </section>
  );
};
