import moment from "moment";
import { ReassignSelectOption } from "PFApp/booking/components/reassign_booking_modal/bookings_to_reassign_selector";
import { useBookingInvalidate, useBookingUpdate } from "PFCore/hooks/queries";
import { useBookingsReassign } from "PFCore/hooks/queries/bookings/reassign/use_bookings_reassign";
import { useWorkforceInvalidate } from "PFCore/hooks/queries/bookings/workforce/use_workforce_invalidate";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

import useRowChildrenInvalidate from "../../side_panel_row/queries/use_row_children_invalidate";
import { ReassignActionData } from "./reassign_action_context_provider";

type UseReassignSubmit = {
  data: ReassignActionData | null;
};

type UseReassignSubmitReturn = {
  handleSubmit: (reassignOption: ReassignSelectOption, targetProfileId?: number) => void;
};

export const useReassignSubmit = ({ data }: UseReassignSubmit): UseReassignSubmitReturn => {
  const { mutateAsync: reassignBooking } = useBookingsReassign({
    options: {
      onError: () => {
        if (data?.onError) {
          data.onError();
        }
      }
    }
  });

  const { update: updateBooking } = useBookingUpdate({
    onError: () => {
      if (data?.onError) {
        data.onError();
      }
    }
  });
  const { invalidateByActivityIds } = useRowChildrenInvalidate();
  const { invalidate: invalidateWorkforce } = useWorkforceInvalidate();
  const { invalidateProfileBookingsList } = useBookingInvalidate();

  const { formatISODate } = useDateFormatter();

  const handleSubmit = async (reassignOption: ReassignSelectOption, targetProfileId?: number) => {
    const newProfileId = targetProfileId ?? data?.targetProfileId;

    if (data?.booking.activity_id && newProfileId !== undefined) {
      if (reassignOption === ReassignSelectOption.ThisBooking) {
        await updateBooking({ id: data?.booking.id, profileId: newProfileId }).then(() => {
          data?.booking.activity_id && invalidateByActivityIds([data.booking.activity_id]);
        });
      } else {
        const min =
          reassignOption === ReassignSelectOption.ThisAndFuture
            ? formatISODate(data.booking.start_date)
            : formatISODate(moment().subtract(2, "year"));
        const max = formatISODate(moment().add(2, "year"));

        await reassignBooking({
          activityId: data?.booking.activity_id,
          newProfileId,
          oldProfileId: data?.sourceProfileId,
          dateRange: {
            min,
            max
          }
        });
      }
      invalidateWorkforce();
      invalidateProfileBookingsList(newProfileId);
      invalidateProfileBookingsList(data.sourceProfileId);
    }
    data?.onSubmit && data.onSubmit();
  };

  return { handleSubmit };
};
