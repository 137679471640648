import { decamelizeKeys } from "humps";
import { isEmpty } from "lodash";
import { FillRoleModal } from "PFApp/audit_planner/parts/modals/fill_role_modal";
import { RequestApprovalModal } from "PFApp/audit_planner/parts/modals/request_approval_modal";
import { Button } from "PFComponents/button";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { checkExceptions } from "PFCore/services/audit_planner";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const FillAuditRoleButton = ({ shortlistItem, role, engagement }) => {
  const { invalidateShortlists } = useShortlistsInvalidate();

  const [exceptionsChecked, setExceptionsChecked] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const canFill =
    !["exception", "booked", "filled", "confirmed"].includes(role.workflow_state) &&
    role.state !== "complete";

  const { t } = useTranslation("activities");
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const onClick = (event) => {
    event.stopPropagation();
    checkExceptions(shortlistItem.id).then((exceptions) => {
      if (exceptions) {
        setExceptionsChecked(decamelizeKeys(exceptions)); // TODO: [PROF-4418] remove decamelizeKeys when rewriting to TS
      }
      setShowModal(true);
    });
  };

  const handleSuccess = () => {
    invalidateActivities([role.id]);
    invalidateShortlists(role.id);
  };

  return (
    <>
      <Button
        text={t("show.parts.fillRole")}
        kind="secondary"
        disabled={!canFill}
        onClick={onClick}
        style={{ marginLeft: "auto" }}
      />
      {showModal &&
        (!isEmpty(exceptionsChecked) ? (
          <RequestApprovalModal
            close={() => setShowModal(false)}
            shortlistItem={shortlistItem}
            exception={exceptionsChecked}
            role={role}
            engagement={engagement}
            onSuccess={handleSuccess}
          />
        ) : (
          <FillRoleModal
            close={() => setShowModal(false)}
            shortlistItem={shortlistItem}
            role={role}
            engagement={engagement}
            onSuccess={handleSuccess}
          />
        ))}
    </>
  );
};

FillAuditRoleButton.propTypes = {
  shortlistItem: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  engagement: PropTypes.object.isRequired
};

export default FillAuditRoleButton;
