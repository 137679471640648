import { getGrowlId, useGrowl } from "PFApp/use_growl";
import { LegacyModal } from "PFComponents/modal/legacy_modal";
import { getProfileName } from "PFCore/helpers/profile";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { fillAndBookShortlist } from "PFCore/services/shortlist";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "../modals.module.scss";
import { RequestApprovalModalStepOne, RequestApprovalModalStepTwo } from "./request_approval_modal_steps";
import { ApprovalConfirmationHandler, RequestApprovalModalProps } from "./types";

export const RequestApprovalModal = ({
  close,
  exception,
  role,
  engagement,
  shortlistItem,
  onSuccess
}: RequestApprovalModalProps) => {
  const growl = useGrowl();
  const { t } = useTranslation(["auditPlanner", "translation"]);
  const { getFirstError } = useResponseErrors();

  const confirm: ApprovalConfirmationHandler = (note, approver) => {
    close();
    fillAndBookShortlist(shortlistItem.id, { approverId: approver.id, requestReason: note })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        growl({
          id: getGrowlId("approval-request-submitted"),
          message: t("auditPlanner:modals.requestSubmittedTo", {
            name: getProfileName(approver)
          }),
          kind: "success",
          hidePreviousByTypeAndId: true,
          ttl: 5000
        });
      })
      .catch((response) => {
        const error = getFirstError(response);
        growl({
          message: error || t("translation:somethingWentWrong"),
          kind: "error"
        });
      });
  };
  const [stepIndex, setStepIndex] = useState(0);
  const steps = [RequestApprovalModalStepOne, RequestApprovalModalStepTwo];
  const CurrentStepComponent = steps[stepIndex];

  return (
    <LegacyModal onClose={close} classes={{ modal: css.modal }}>
      <CurrentStepComponent
        setStepIndex={setStepIndex}
        close={close}
        confirm={confirm}
        exception={exception}
        role={role}
        engagement={engagement}
        shortlistItem={shortlistItem}
      />
    </LegacyModal>
  );
};
