import { ReactNode } from "react";

export type FormatMinMaxFunction = (value: number) => ReactNode;

export type SliderAttributes = {
  min: number;
  max: number;
  step: number;
  title: string;
  multiRange: boolean;
  unit?: string;
  showSteps?: boolean;
  showMinMax?: boolean;
  disabled?: boolean;
  instantOnChange?: boolean;
  small: boolean;
  formatMinMax: FormatMinMaxFunction;
};

type GetLeftOffset = {
  value: number;
  min: number;
  max: number;
  inputWidth: number;
  small: boolean;
};

const THUMB_WIDTH = 24;
const THUMB_WIDTH_SMALL = 20;

export const getLeftOffset = ({ value, min, max, inputWidth, small }: GetLeftOffset) => {
  const thumbWidth = small ? THUMB_WIDTH_SMALL : THUMB_WIDTH;

  // https://stackoverflow.com/a/61665977
  return ((value - min) / (max - min)) * (inputWidth - thumbWidth) + thumbWidth / 2;
};
