import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { useReopenActivity } from "PFCore/hooks/queries/activities/use_reopen_activity";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ExpiryDateModal, getPostUntilChangeReasonData, PostUntilData } from "../expiry_date_modal";

type ReopenActivityModalProps = {
  activity: Pick<Activity, "id"> & {
    templateId: Activity["template_id"];
  };
  onSuccess?: VoidFunction;
  onClose: VoidFunction;
};

export const ReopenActivityModal = ({ activity, onSuccess, onClose }: ReopenActivityModalProps) => {
  const { t } = useTranslation("activities");

  const template = useTemplate(activity.templateId);
  const { mutateAsync: reopenActivity } = useReopenActivity();

  const getTitle = () => {
    switch (template?.key) {
      case TemplateKey.Role:
        return t("reopenActivity.role");
      case TemplateKey.AuditRole:
        return t("reopenActivity.auditRole");
      case TemplateKey.Engagement:
        return t("reopenActivity.engagement");
      case TemplateKey.AuditEngagement:
        return t("reopenActivity.auditEngagement");
      default:
        return t("reopenActivity.defaultTitle");
    }
  };

  const handleChange = async ({ date, reason }: PostUntilData) => {
    const postUntilChangeReason = getPostUntilChangeReasonData(reason ?? null);
    await reopenActivity({
      activityId: activity.id,
      postUntil: date,
      ...(postUntilChangeReason ? { postUntilChangeReason } : {})
    });
    onSuccess?.();
  };

  if (!template) {
    return null;
  }

  return (
    <ExpiryDateModal
      title={getTitle()}
      reasonLabel={t("reopenActivity.reasonToReopen")}
      template={template}
      onChange={handleChange}
      onClose={onClose}
    />
  );
};
