import { ExceptionModalContent } from "../../parts/exception_modal_content";
import { RequestApprovalModalStepProps } from "../types";

export const RequestApprovalModalStepTwo = ({
  close,
  confirm,
  exception,
  role,
  shortlistItem
}: RequestApprovalModalStepProps) => (
  <ExceptionModalContent
    role={role}
    shortlistItem={shortlistItem}
    exception={exception}
    confirm={confirm}
    close={close}
    isEditModal={false}
  />
);
