import { Id } from "PFTypes/id";

import { FetchVacanciesParams } from "../../../services/vacancies";

const normalizeParams = (params) =>
  ((params && (Array.isArray(params) ? params : [params])) as Array<unknown>) || [];

const activityKeys = {
  all: () => ["activity"],
  activity: (activityId?: Id) => [...activityKeys.all(), String(activityId)],
  warnings: (activityId: Id, params?: unknown) => [
    ...activityKeys.activity(activityId),
    "warnings",
    ...normalizeParams(params)
  ],
  isWarningsQuery: (query) => query.queryKey[0] === "activity" && query.queryKey.includes("warnings"),
  watchers: (activityId: Id, params?: unknown) => [
    ...activityKeys.activity(activityId),
    "watchers",
    ...normalizeParams(params)
  ],
  feedbackParticipants: (activityId?: number, params?: unknown) => [
    ...activityKeys.activity(activityId),
    "feedbackParticipants",
    ...normalizeParams(params)
  ],
  feedback: (feedbackId?: number, params?: unknown) => ["feedbacks", feedbackId, ...normalizeParams(params)],
  profileFeedback: (profileId?: number, params?: unknown) => [
    "feedbacks",
    profileId,
    ...normalizeParams(params)
  ],
  feedbackCreate: (activityId?: number) => [...activityKeys.activity(activityId), "feedbacks", "create"],
  revealReviewers: (activityId: Id) => [...activityKeys.all(), "reviewers", "reveal", String(activityId)],
  vacancies: (activityId?: Id, params?: FetchVacanciesParams) => [
    ...activityKeys.activity(activityId),
    "vacancies",
    ...normalizeParams(params)
  ],
  economics: (activityId: Id, params?: unknown) => [
    ...activityKeys.activity(activityId),
    "economics",
    ...normalizeParams(params)
  ],
  economicsScenario: (scenarioId: Id) => [...activityKeys.all(), "economicsScenario", String(scenarioId)]
};

export default activityKeys;
