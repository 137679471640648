import { isArray, isNil } from "lodash";
import { Stack } from "PFComponents/containers/flex/stack";
import { Radios } from "PFComponents/radios/radios";

import { letFilterRestore } from "../../use_filtered_collection";
import { ExpandedFilterParams } from "./components/expanded_filter_wrapper";
import { FilterHeader } from "./components/filter_header";
import { numberizeValue } from "./filters.utils";

export const RadioButtonsItem = ({ filter, onChange, disabled, options }: ExpandedFilterParams) => {
  const filterValue = numberizeValue(isArray(filter.value) ? filter.value[0] : filter.value);
  const canRestore = letFilterRestore(filter);
  const canClear = !isNil(filterValue);

  return (
    <Stack gap="spacingMd">
      <FilterHeader
        filter={filter}
        disabled={disabled}
        canClear={canClear}
        canRestore={canRestore}
        onChange={onChange}
      />
      {options.length > 0 && (
        <Radios
          items={options.map((option) => ({
            id: option.id,
            label: option.value
          }))}
          name={filter.name!}
          value={filterValue}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </Stack>
  );
};
