import { find, isEqual, pick } from "lodash";
import moment from "moment";
import { useAppContext } from "PFApp/app_context";
import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import LegacyForm from "PFApp/components/form/legacy_form";
import BasicInputField from "PFApp/profiles/edit/fields/basic_input_field";
import PositionPeriodPicker from "PFApp/profiles/edit/fields/position_period_picker";
import AutoSelect from "PFComponents/select/autoselect";
import SidePanel from "PFComponents/side_panel/side_panel";
import {
  customTypesAccessLevelsAgainstProfile,
  ownProfileCustomTypesAccessLevels
} from "PFCore/helpers/custom_types";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useActivityDelete } from "PFCore/hooks/queries/activity/use_activity_delete";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { createActivity } from "PFCore/services/activities";
import { ApiRoute } from "PFCore/utilities/routes";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./edit_internal_experience_form.module.scss";

const EditInternalExperienceForm = ({
  experience,
  adminPage,
  profile,
  handleSuccess,
  handleRemove,
  onClose
}) => {
  const { formatDatabaseDateTime, formatISODate, utc } = useDateFormatter();

  const dateRange = experience.metadata.date_range || {};

  const [positionId, setPositionId] = useState(experience.metadata.position_id);
  const [positionText, setPositionText] = useState(null);
  const [startDate, setStartDate] = useState(
    dateRange.start_date ? formatISODate(utc(dateRange.start_date)) : null
  );
  const [endDate, setEndDate] = useState(dateRange.end_date ? formatISODate(utc(dateRange.end_date)) : null);
  const [current, setCurrent] = useState(dateRange.current || false);
  const [location, setLocation] = useState(experience.metadata.location);
  const [engagementHours, setEngagementHours] = useState(experience.metadata.engagement_hours);
  const [description, setDescription] = useState(experience.description);
  const [name, setName] = useState(experience.name);
  const [customFields, setCustomFields] = useState(experience.custom_fields || []);
  const [errors, setErrors] = useState({});

  const { dispatch } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles", { keyPrefix: "common.experience" });
  const { update: updateActivity } = useActivityUpdate(experience.id);
  const { mutateAsync: deleteActivity } = useActivityDelete();
  const { getPermittedTypes } = useCustomTypes();

  const isNew = !experience.id;

  useEffect(() => {
    if (positionId && profile.id) {
      $.ajax({
        url: ApiRoute(`/api/options/positions?profile_id=${profile?.id}`),
        api_version: 2
      }).then((response) =>
        setPositionText(
          response.entries.filter((position) => position.id === positionId).map((val) => val.text)[0]
        )
      );
    }
  }, []);

  const handleSuccessCallback = (experience) => {
    dispatch({
      type: GROWL_ACTIONS.GROWL_RENDER,
      payload: {
        message: isNew ? t("internalExperience.created") : t("internalExperience.updated")
      }
    });
    handleSuccess &&
      handleSuccess({
        ...experience,
        statistics: {
          ...profile?.statistics
        }
      });
  };

  const handleError = (errors) => {
    setErrors(errors);

    if (errors.base) {
      dispatch({
        type: GROWL_ACTIONS.GROWL_RENDER,
        payload: {
          message: errors.base,
          kind: "error"
        }
      });
    }
  };

  const formData = () => ({
    metadata: {
      engagement_hours: engagementHours,
      position_id: positionId,
      date_range: {
        start_date: !current && !endDate ? null : formatDatabaseDateTime(startDate),
        end_date: endDate ? formatDatabaseDateTime(endDate) : null,
        current
      },
      location
    },
    custom_fields: customFields,
    name,
    description
  });

  const isChanged = () => {
    const data = pick(experience, ["custom_fields", "name", "description"]);
    data.metadata = pick(experience.metadata, ["engagement_hours", "position_id", "date_range", "location"]);
    if (data.metadata.date_range) {
      data.metadata.date_range.current = data.metadata.date_range.current || false;
    }
    return !isEqual(data, formData());
  };

  const handleRemoveCallback = () => {
    if (!isNew) {
      if (window.confirm(t("translation:sureToDelete", { key: name }))) {
        deleteActivity(experience.id).then(() => handleRemove && handleRemove());
      }
    } else {
      if (isChanged()) {
        if (window.confirm(t("confirmCloseDialog"))) {
          handleRemove && handleRemove();
        }
      } else {
        handleRemove && handleRemove();
      }
    }
  };

  const handleSubmit = () => {
    setErrors({});
    const workTemplate = find(profile?.templates, (template) => template.key === "work");
    const payload = {
      template_id: workTemplate.id,
      post_until: moment().add(workTemplate.default_post_until_days, "days").format(),
      ...formData()
    };

    const apiFunction = isNew ? createActivity : updateActivity;
    return apiFunction(payload);
  };

  const getCustomField = (name) => customFields.filter((customField) => customField.type.name === name)[0];

  const getCustomFieldValues = (name) => {
    const customField = getCustomField(name);
    return customField ? customField.values : [];
  };

  const updateCustomField = (customType, values) => {
    const typeName = customType.name;
    const customField = getCustomField(typeName);

    if (customField) {
      customField.values = values;
    } else {
      setCustomFields([
        ...customFields,
        {
          type: { id: customType.id, name: typeName },
          values: values
        }
      ]);
    }
  };

  const permittedCustomTypes = useMemo(() => {
    const ctAccessLevels = adminPage
      ? customTypesAccessLevelsAgainstProfile(profile, currentProfile)
      : ownProfileCustomTypesAccessLevels(currentProfile);

    return getPermittedTypes({ ctAccessLevels });
  }, [adminPage, currentProfile, getPermittedTypes, profile]);

  const getCustomType = (name) => permittedCustomTypes.find((customType) => customType.name === name);

  const brandsCustomType = getCustomType("brands");
  const sectorsCustomType = getCustomType("sectors");
  const skillsCustomType = getCustomType("skills");

  return (
    <SidePanel
      show
      fullHeight
      isOnTop
      size="medium"
      title={isNew ? t("position.newExperience") : t("position.editPosition")}
      onClose={onClose}
    >
      <LegacyForm
        blockNavigationOnChange
        growlErrors
        isDirty={isChanged()}
        isNew={isNew}
        onError={handleError}
        onRemove={handleRemoveCallback}
        onSubmit={handleSubmit}
        onSuccess={handleSuccessCallback}
        qaIdPrefix="profile-edit-past-experience"
      >
        <div className={css.form}>
          <AutoSelect
            label={t("internalExperience.edit.position.label")}
            key={positionText}
            tip={t("internalExperience.edit.position.tip")}
            closeOnChange
            values={positionText && [{ text: positionText, id: positionId }]}
            multi={false}
            query={() => {
              if (!profile?.id) {
                return Promise.resolve({ entries: [], meta: {} });
              } else {
                return $.ajax({
                  url: ApiRoute(`/api/options/positions?profile_id=${profile?.id}`),
                  api_version: 2
                });
              }
            }}
            error={errors["metadata[position_id]"]}
            parseResponse={(response) => response.entries}
            handleChange={(values) => {
              setPositionId(values[0].id);
              setPositionText(values[0].text);
            }}
            filterOptions={(options, term) =>
              options.filter((option) => option.text.toLowerCase().indexOf(term) !== -1)
            }
            disabled={!!experience.metadata.position_id}
          />
          <BasicInputField
            label={t("internalExperience.edit.roleTitle.label")}
            tip={t("internalExperience.edit.roleTitle.tip")}
            value={name}
            inputType="text"
            attr="name"
            errors={errors}
            handleChange={(value) => setName(value)}
          />
          {brandsCustomType && (
            <CustomValuesEditField
              label={brandsCustomType.display_as}
              tip={t("internalExperience.edit.client")}
              customType={brandsCustomType}
              values={getCustomFieldValues("brands")}
              qaIdPrefix="profile-internal-experience"
              errors={errors[`custom_fields[brands]`]}
              adminPage={adminPage}
              multi={false}
              closeOnChange={true}
              profile={profile}
              handleChange={(values) => updateCustomField(brandsCustomType, values)}
            />
          )}
          {sectorsCustomType && (
            <CustomValuesEditField
              label={sectorsCustomType.display_as}
              tip={t("internalExperience.edit.industries")}
              customType={sectorsCustomType}
              values={getCustomFieldValues("sectors")}
              qaIdPrefix="profile-internal-experience"
              errors={errors[`custom_fields[sectors]`]}
              adminPage={adminPage}
              profile={profile}
              handleChange={(values) => updateCustomField(sectorsCustomType, values)}
            />
          )}
          {skillsCustomType && (
            <CustomValuesEditField
              label={skillsCustomType.display_as}
              tip={t("internalExperience.edit.skills")}
              customType={skillsCustomType}
              values={getCustomFieldValues("skills")}
              qaIdPrefix="profile-internal-experience"
              errors={errors[`custom_fields[skills]`]}
              adminPage={adminPage}
              profile={profile}
              handleChange={(values) => updateCustomField(skillsCustomType, values)}
            />
          )}
          <BasicInputField
            label={t("internalExperience.edit.location.label")}
            tip={t("internalExperience.edit.location.tip")}
            value={location}
            inputType="text"
            attr="metadata[location]"
            errors={errors}
            handleChange={(value) => setLocation(value)}
          />
          <BasicInputField
            label={t("internalExperience.edit.description.label")}
            tip={t("internalExperience.edit.description.tip")}
            value={description}
            inputType="textarea"
            attr="description"
            errors={errors}
            handleChange={(value) => setDescription(value)}
          />
          <BasicInputField
            label={t("internalExperience.edit.hours.label")}
            tip={t("internalExperience.edit.hours.tip")}
            value={isNaN(engagementHours) ? undefined : `${engagementHours}`}
            inputType="number"
            attr="metadata[engagement_hours]"
            errors={errors}
            handleKeyDown={(event) => {
              if (!event.key.match(/^[0-9]+|Backspace$/)) {
                event.preventDefault();
              }
            }}
            handleChange={(value) => setEngagementHours(isNaN(parseInt(value)) ? undefined : parseInt(value))}
          />
          <PositionPeriodPicker
            error={
              errors["metadata[date_range][start_date]"] ||
              errors["metadata[date_range][end_date]"] ||
              errors["metadata[date_range]"]
            }
            showLabel
            currentPosition={current}
            startDate={startDate ? formatISODate(startDate) : null}
            endDate={endDate ? formatISODate(endDate) : null}
            handleToggleClick={(current) => setCurrent(current)}
            handleChange={(startDate, endDate) => {
              setStartDate(startDate);

              if (endDate && !current) {
                setEndDate(endDate);
              } else {
                setEndDate(null);
              }
            }}
          />
        </div>
      </LegacyForm>
    </SidePanel>
  );
};

export default EditInternalExperienceForm;

EditInternalExperienceForm.propTypes = {
  experience: PropTypes.object.isRequired,
  profile: PropTypes.object,
  adminPage: PropTypes.any.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
  onClose: PropTypes.func
};

EditInternalExperienceForm.defaultProps = {
  experience: {
    custom_fields: [],
    name: "",
    description: "",
    metadata: {
      engagement_hours: undefined,
      position_id: undefined,
      date_range: { start_date: null, end_date: null, current: false },
      location: ""
    }
  }
};
