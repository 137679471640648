import { useAppContext } from "PFApp/app_context";
import PROFILE_SECTIONS from "PFApp/profiles/profile_sections";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { customTypesAccessLevelsAgainstProfile, hasCustomTypeSection } from "PFCore/helpers/custom_types";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ProfilePreviewHeader } from "./profile_preview_header";

const ProfilePreviewCustomFields = ({ only }) => {
  const {
    store: {
      profilePreview: { ready, profile }
    }
  } = useAppContext();

  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();

  const types = useMemo(() => {
    if (
      !currentProfile?.permissions_group ||
      !profile ||
      !profile.permissions_group ||
      !customTypes ||
      customTypes.length === 0
    ) {
      return [];
    }

    const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);

    const isMe = currentProfile.id === profile.id;

    return only
      ?.map((ct) => customTypes?.find(({ name }) => name === ct))
      .filter(
        (ct) =>
          !!ct &&
          accessLevels[ct.id] &&
          (isMe || !hasCustomTypeSection(ct, PROFILE_SECTIONS.profile_private_fields))
      );
  }, [currentProfile, profile, customTypes, only]);

  if (!ready || !profile || !types || types.length === 0) {
    return null;
  }

  // NOTE: don't wrap in it's own <div> so the <ProfilePreviewHeader> can manage spacing
  // NOTE: the order of custom fields is the same as provided by api in the /me endpoint
  return (
    <>
      {types.map((type) => (
        <Fragment key={type.name}>
          <Section
            type={type}
            values={profile[type.name]}
            profile={profile}
            currentProfile={currentProfile}
            currentAccount={currentAccount}
          />
        </Fragment>
      ))}
    </>
  );
};

ProfilePreviewCustomFields.defaultValues = {
  only: []
};

ProfilePreviewCustomFields.propTypes = {
  only: PropTypes.array
};

const Section = ({ values, type, profile, currentProfile, currentAccount }) => {
  const { t } = useTranslation("core");

  return (
    <div key={`ProfilePreviewCustomFields.${type.name}`}>
      <ProfilePreviewHeader>{type.display_as}</ProfilePreviewHeader>
      {values && values.length > 0 ? (
        <CustomValuesList
          moreLimit={10}
          type={type}
          customValues={values}
          showMoreButton={false}
          showTips={true}
          displayAttributeLevel={true}
          profileId={profile.id}
          currentProfile={currentProfile}
          currentAccount={currentAccount}
          showModal
        />
      ) : (
        t("profilePreview.noInformation")
      )}
    </div>
  );
};

Section.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
      top: PropTypes.bool
    })
  ),
  type: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    // eslint-disable-next-line camelcase
    display_as: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string }))
  }),
  currentProfile: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default ProfilePreviewCustomFields;
