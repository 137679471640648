import type {
  BarSeriesOption,
  CustomSeriesOption,
  LineSeriesOption,
  PieSeriesOption,
  RadarSeriesOption,
  TreemapSeriesOption
} from "echarts/charts";
import type {
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  MarkAreaComponentOption,
  TitleComponentOption,
  ToolboxComponentOption,
  TooltipComponentOption
} from "echarts/components";
import { ComposeOption, ECharts, SetOptionOpts } from "echarts/core";
import { LoadingDots } from "PFComponents/loading_dots";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import css from "./echarts_wrapper.module.scss";

export type ECOption = ComposeOption<
  | CustomSeriesOption
  | RadarSeriesOption
  | BarSeriesOption
  | LineSeriesOption
  | TreemapSeriesOption
  | PieSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | MarkAreaComponentOption
  | GridComponentOption
  | DatasetComponentOption
  | ToolboxComponentOption
>;

// Documentation for the `options` can be found here https://echarts.apache.org/en/option.html#title

// Based on https://dev.to/maneetgoyal/using-apache-echarts-with-react-and-typescript-353k

type EchartsWrapperProps = {
  options: ECOption;
  settings?: SetOptionOpts;
  loading?: boolean;
  style?: React.CSSProperties;
  height?: number;
  width?: number;
  minWidth?: string | number;
  onClick?: (params: any) => void;
  hidden?: boolean;
  echartsLoadingOptions?: object;
};

type EchartsInit = {
  init: (ref: HTMLDivElement) => void;
  getInstanceByDom: (ref: HTMLDivElement) => ECharts | undefined;
};

const EchartsWrapper = ({
  options,
  settings,
  loading,
  style,
  height,
  width,
  minWidth = "auto",
  onClick,
  hidden = false,
  echartsLoadingOptions
}: EchartsWrapperProps): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);

  const [echarts, setEcharts] = useState<EchartsInit | null>(null);

  useEffect(() => {
    import(/* webpackChunkName: "echarts" */ "PFCore/initializers/echarts").then((echarts) => {
      setEcharts(echarts);
    });
  }, []);

  useEffect(() => {
    if (!echarts) {
      return;
    }
    // Initialize chart
    let chart;

    if (chartRef.current !== null) {
      chart = echarts.init(chartRef.current);
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [echarts]);

  useEffect(() => {
    if (!echarts) {
      return;
    }
    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current);
      chart?.resize();
    }
  }, [height, width, echarts]);

  useEffect(() => {
    if (!echarts) {
      return;
    }
    // Update chart
    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current);
      chart?.setOption(options, settings);
    }
  }, [options, settings, echarts]);

  // To support more React like behaviour, we need to readd the onClick everytime we tell it to
  // This allows us to use Reacts state with the ECharts events
  useEffect(() => {
    if (!echarts) {
      return;
    }
    if (onClick && chartRef.current) {
      const chart = echarts.getInstanceByDom(chartRef.current);
      // To prevent overloading with the same onclick, we need to turn off the old one first
      chart?.off("click");
      chart?.on("click", onClick);
    }
  }, [onClick, echarts]);

  useEffect(() => {
    if (!echarts) {
      return;
    }
    // Update chart
    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart?.showLoading(echartsLoadingOptions) : chart?.hideLoading();
    }
  }, [loading, echarts, echartsLoadingOptions]);

  return (
    <div
      style={{
        width: width || "100%",
        height: height || "100%",
        overflow: minWidth === "auto" ? "initial" : "auto",
        display: hidden ? "none" : "block"
      }}
    >
      {echarts ? (
        <div ref={chartRef} style={{ width: width || "100%", minWidth, height, ...style }} />
      ) : (
        <div className={css.loadingWrapper}>
          <LoadingDots circlesEnabled circleSize="md" />
        </div>
      )}
    </div>
  );
};

EchartsWrapper.propTypes = {
  options: PropTypes.object,
  settings: PropTypes.object,
  loading: PropTypes.bool,
  style: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  hidden: PropTypes.bool
};

export default EchartsWrapper;
