import moment from "moment";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { TimeRuleRange } from "PFTypes/booking";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { convertToPercentage } from "../../../../../utilities/math";
import { DEFAULT_MONTHS_FORWARD_LIMIT } from "../../../constants";
import { LoadEstimationData } from "../../../hooks/use_load_estimation";
import { AvailabilityRangeCalendar } from "../../availability_range_calendar";
import { SideInfo } from "../../parts/side_info";
import commonCss from "../../rules_carousel.modules.scss";
import css from "./availability_with_hours.module.scss";
import { HoursPerDay } from "./hours_per_day";
import { useDebouncedHours } from "./use_debounced_hours";

type AvailabilityWithHoursProps = {
  range: TimeRuleRange;
  onChange: (range: Partial<TimeRuleRange>) => void;
  isFilter: boolean;
  minDate?: { start: string; end?: string };
  errors?: Record<string, string>;
  portalRef?: React.RefObject<HTMLDivElement>;
  loadData: LoadEstimationData;
};

export const AvailabilityWithHours = ({
  range: { start, end, day_of_week },
  onChange,
  errors,
  isFilter,
  minDate,
  portalRef,
  loadData
}: AvailabilityWithHoursProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "availabilityRequirement" });
  const { data: currentAccount } = useCurrentAccount();
  const { formatISODate } = useDateFormatter();

  const [hours, setHours] = useDebouncedHours(day_of_week, onChange, isFilter);

  const rangeCalendarRef = useRef();

  const matchMonthsForwardLimit =
    currentAccount?.availability?.match_months_limit || DEFAULT_MONTHS_FORWARD_LIMIT;
  const matchMaxDate = formatISODate(moment().add(matchMonthsForwardLimit, "months").endOf("month"));
  const { totalAvailableHours, percentageLoad } = loadData;

  const handleDatesChange = (start: string, end: string) => {
    onChange({ start, end });
  };

  return (
    <div className={css.wrapper}>
      <span className={commonCss.row}>
        <AvailabilityRangeCalendar
          key={`${start}+${end}`}
          ref={rangeCalendarRef}
          // @ts-ignore
          start={start}
          end={end}
          matchMaxDate={matchMaxDate}
          onChange={handleDatesChange}
          errors={errors}
          wrapped
          minDate={minDate}
          portalRef={portalRef}
        />
        <SideInfo
          label={t("totalAvailable")}
          value={totalAvailableHours !== null ? t("nHours", { count: Math.round(totalAvailableHours) }) : "-"}
          tooltipContent={t("tooltips.totalAvailable")}
        />
      </span>
      <div className={css.hoursPerDayLabel}>{t("selectHoursPerDay")}</div>
      <span className={commonCss.row}>
        <HoursPerDay onHoursChange={setHours} dayOfWeek={hours} />
        <SideInfo
          label={t("requiredPercent")}
          value={convertToPercentage(percentageLoad, { isNormalized: true })}
          tooltipContent={t("tooltips.requiredPercent")}
        />
      </span>
    </div>
  );
};
