import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { AUDIT_PLANNER_TAB_QUERY_PARAM } from "PFApp/audit_planner/audit_planner_controller.utils";
import { AuditPlannerTabId } from "PFApp/audit_planner/types";
import { useTemplate } from "PFApp/hooks";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export const Breadcrumbs = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const params = useQueryParams();

  const { isAudit, isRole, isEngagement, parentActivity, task } = useActivityPageContext();
  const template = useTemplate(task.template_id);

  const auditPlannerTab = params.get(AUDIT_PLANNER_TAB_QUERY_PARAM) ?? AuditPlannerTabId.ControlCentre;
  const auditEngagementId = isRole ? parentActivity?.id : task.id;

  const rootLinkData = useMemo(() => {
    if (isAudit) {
      if (auditPlannerTab && auditEngagementId) {
        return { path: `/audit_planner/${auditPlannerTab}/${auditEngagementId}`, label: t("auditPlanner") };
      }

      return { path: "/audit_planner", label: t("auditPlanner") };
    }
    if (isRole || isEngagement) {
      return { path: `/workflow/${isRole ? "roles" : "engagements"}`, label: t("workflow") };
    }
  }, [isAudit, isRole, isEngagement, t, auditPlannerTab, auditEngagementId]);

  if (!rootLinkData) {
    return null;
  }

  const parentActivitySearch = new URLSearchParams({
    origin_id: `${task.id}`,
    origin_type: template?.name ?? ""
  });

  if (isAudit) {
    parentActivitySearch.set(AUDIT_PLANNER_TAB_QUERY_PARAM, auditPlannerTab);
  }

  return (
    <div>
      <Link to={rootLinkData.path}>{rootLinkData.label}</Link>
      {parentActivity && (
        <>
          <span> / </span>
          <Link
            to={{
              pathname: `/activities/${parentActivity.id}`,
              search: parentActivitySearch.toString(),
              state: { utmSource: (location?.state as any)?.utmSource }
            }}
          >
            {parentActivity.name}
          </Link>
        </>
      )}
    </div>
  );
};
