import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Card } from "PFComponents/card";
import Table from "PFComponents/table";

import { ShortlistTableEntry } from "../../../../activity_tabs.utils";
import { useMatchesShortlistTableColumns } from "../../../../hooks/use_matches_shortlist_table_columns";

type ShortlistsTableViewProps = {
  shortlistsEntries: ShortlistTableEntry[];
  isLoading: boolean;
};

export const ShortlistsTableView = ({ shortlistsEntries, isLoading }: ShortlistsTableViewProps) => {
  const { compareIds, setCompareIds } = useActivityPageContext();
  const { generateColumns } = useMatchesShortlistTableColumns<ShortlistTableEntry>({
    isShortlistTabActive: true
  });

  return (
    <Card shadowVariant="none" qaId="ActivityShortlistsTabTableContainer">
      <Table<ShortlistTableEntry>
        isLoading={isLoading}
        tableData={shortlistsEntries}
        generateColumns={generateColumns}
        rowSelection={compareIds}
        onRowSelectionChange={setCompareIds}
        getRowId={({ shortlist }) => String(shortlist.profile.id)}
        enableRowSelection
        enableMultiRowSelection
        compact
      />
    </Card>
  );
};
