import { useQuery } from "@tanstack/react-query";
import { CustomValuesOptionsQueryParams, fetchCustomValuesOptions } from "PFCore/services/custom_values";
import { Collection, PureCustomValue } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { customValuesKeys } from "./query_keys";

export const useCustomValuesOptions = (
  params: CustomValuesOptionsQueryParams,
  options: QueryOptions<Collection<PureCustomValue[]>> = {}
) =>
  useQuery<Collection<PureCustomValue[]>>(
    customValuesKeys.options(params),
    () => fetchCustomValuesOptions(params),
    options
  );
