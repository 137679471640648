import { ColumnHelper } from "@tanstack/react-table";
import { convertToPercentage } from "PFCore/utilities/math";
import { EconomicsVacancy } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import css from "../vacancies_table.module.scss";

export const useAvailabilityColumn = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });

  return useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor(
        ({ assignment }) => assignment?.match?.availabilityScore || assignment?.match?.availabilityScore,
        {
          id: "availability",
          header: () => <span className={css.rightAlign}>{t("availability")}</span>,
          cell: (info) => <span className={css.rightAlign}>{convertToPercentage(info.getValue())}</span>,
          size: 100,
          enableSorting: true
        }
      ),
    [t]
  );
};
