import { concat, keyBy } from "lodash";
import { getProfileName } from "PFCore/helpers/profile";
import { BasicProfile } from "PFTypes";
import { RecentSearch } from "PFTypes/recent_searches";
import { useTranslation } from "react-i18next";

import { EntityConfig, SearchConfig, SearchEntity } from "../search_autocomplete.types";
import { useAutocompleteEntityOptions } from "./use_autocomplete_entity_options";
import { useAutocompleteRecentSearchesOptions } from "./use_autocomplete_recent_searches_options";
import { useDebouncedTerm } from "./use_debounced_term";

type UseAutocompleteOptions = {
  term: string;
  config: SearchConfig;
  recentSearches: RecentSearch[];
};

export const useAutocompleteOptions = ({ term, config, recentSearches }: UseAutocompleteOptions) => {
  const { t } = useTranslation(["activities", "translation"]);

  const configs = keyBy<EntityConfig>(config, "entity");
  const debouncedTerm = useDebouncedTerm(term);

  const profilesOptions = useAutocompleteEntityOptions<BasicProfile>({
    term: debouncedTerm,
    config: configs[SearchEntity.Profile],
    iconName: "profile",
    label: t("translation:profile"),
    getRedirectUrl: ({ id }) => `/profiles/${id}`,
    getEntityTitle: (entity) => getProfileName(entity),
    getEntitySubTitle: ({ email }) => email
  });
  const rolesOptions = useAutocompleteEntityOptions({
    term: debouncedTerm,
    config: configs[SearchEntity.Role],
    templateKey: "role",
    iconName: "role",
    label: t("activities:templates.role"),
    getRedirectUrl: ({ id }) => `/activities/${id}`
  });
  const auditRolesOptions = useAutocompleteEntityOptions({
    term: debouncedTerm,
    config: configs[SearchEntity.AuditRole],
    templateKey: "audit_role",
    iconName: "role-audit",
    label: t("activities:templates.audit_role"),
    getRedirectUrl: ({ id }) => `/activities/${id}`
  });
  const engagementsOptions = useAutocompleteEntityOptions({
    term: debouncedTerm,
    config: configs[SearchEntity.Engagement],
    templateKey: "engagement",
    iconName: "engagement",
    label: t("activities:templates.engagement"),
    getRedirectUrl: ({ id }) => `/activities/${id}`
  });
  const auditEngagementsOptions = useAutocompleteEntityOptions({
    term: debouncedTerm,
    config: configs[SearchEntity.AuditEngagement],
    templateKey: "audit_engagement",
    iconName: "engagement-audit",
    label: t("activities:templates.audit_engagement"),
    getRedirectUrl: ({ id }) => `/activities/${id}`
  });

  const recentSearchesOptions = useAutocompleteRecentSearchesOptions({ recentSearches });

  return concat(
    profilesOptions,
    engagementsOptions,
    rolesOptions,
    auditRolesOptions,
    auditEngagementsOptions,
    recentSearchesOptions
  );
};
