import { Inline } from "PFComponents/containers/flex/inline";
import { Icon, IconProps } from "PFComponents/icon";
import { ProfileCustomValue } from "PFTypes";
import { useTranslation } from "react-i18next";

import { DataEntry } from "./data_entry";

type VerifiedIconsProps = {
  profileConnection?: ProfileCustomValue;
};

type IconWithTitleProps = {
  title: string;
} & IconProps;

const IconWithTitle = ({ title, ...props }: IconWithTitleProps) => (
  <div title={title}>
    <Icon {...props} />
  </div>
);

export const VerifiedIcons = ({ profileConnection }: VerifiedIconsProps) => {
  const { t } = useTranslation();
  const { t: tCore } = useTranslation("core");

  const isVerified = profileConnection?.assessed;
  const isCredlySource = profileConnection?.assessment_source === "credly";
  const isVerifiedOthers = !!profileConnection?.em_verified;

  if (!isVerified && !isVerifiedOthers) {
    return null;
  }

  return (
    <DataEntry
      label={t("customValueSidepanel.verified")}
      value={
        <Inline gap="spacingMd">
          {isVerified &&
            (isCredlySource ? (
              <IconWithTitle name="verified-credly" title={tCore("skillBadge.assessment.credly")} />
            ) : (
              <IconWithTitle name="verified" title={tCore("skillBadge.assessment.assessed")} />
            ))}
          {isVerifiedOthers && (
            <IconWithTitle name="verified-others" title={tCore("skillBadge.assessment.feedback")} />
          )}
        </Inline>
      }
    />
  );
};
