import css from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityShortlistsFilters } from "PFApp/activities/show/hooks/use_activity_shortlists_filters";
import { FiltersContextProvider } from "PFApp/components/filters/context/filters_context";
import { PagePrefix } from "PFApp/components/filters/filters_panel";
import { useTranslation } from "react-i18next";

import { ActivityReviewSection } from "../../../../../parts/review_section";
import { SideFilters } from "../../components/side_filters";
import { ShortlistInformation } from "./shortlist_information";

export const ShortlistSidePanel = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });

  const { shortlistsMeta, isLoadingShortlists } = useActivityPageContext();
  const { setFilters, resetFilters, updateFilter } = useActivityShortlistsFilters();

  return (
    <div className={css.pageCardSidePanel}>
      <ShortlistInformation />

      <FiltersContextProvider
        keyPrefix={PagePrefix.ActivityShortlists}
        meta={shortlistsMeta}
        onFilterChange={updateFilter}
        onFiltersChange={setFilters}
        clearFilters={resetFilters}
      >
        <SideFilters
          disabled={isLoadingShortlists}
          moreFiltersTitle={t("shortlistFilters")}
          clearMoreFiltersTitle={t("clearShortlistFilters")}
        />
      </FiltersContextProvider>

      <ActivityReviewSection />
    </div>
  );
};
