import { useQuery } from "@tanstack/react-query";
import { fetchRoles, FetchRolesParams, FetchRolesResponse } from "PFCore/services/roles";
import { QueryOptions } from "PFTypes/request_options";

import { rolesKeys } from "./query_keys";

export const useRoles = (params: FetchRolesParams, options: QueryOptions<FetchRolesResponse> = {}) =>
  useQuery<FetchRolesResponse>({
    queryKey: rolesKeys.list(params),
    queryFn: ({ signal }) => fetchRoles(params, { signal }),
    ...options
  });
