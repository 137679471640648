import classNames from "classnames";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActiveTab } from "PFApp/activities/show/hooks/use_active_tab";
import { useGrowl } from "PFApp/use_growl";
import { getProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useRoleProfileActionsPermissions } from "../../../hooks/use_role_profile_actions_permissions";
import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import {
  ApprovalButtons,
  BookButton,
  FillAndBookButton,
  FillButton,
  InviteButton,
  ShortlistButton
} from "../parts";
import { ShortlistStateLabel } from "../parts/shortlist_state_label";
import css from "./role_profile_action_buttons.module.scss";

export type ShortlistAction = "accept" | "decline" | "fill" | "invite" | "shortlist";

const GROWL_TTL = 3000;

export type RoleProfileActionButtonsContentProps = {
  customButton?: ReactNode;
  className?: string;
};

export const RoleProfileActionButtonsContent = ({
  customButton,
  className
}: RoleProfileActionButtonsContentProps) => {
  const { role, profile, shortlist } = useRoleProfileActionButtonsContext();
  const { isUnbiasedEnabled, isActivityUnbiased } = useActivityPageContext();
  const { isOnMatchesTab } = useActiveTab();
  const { t } = useTranslation("activities");
  const growl = useGrowl();

  const isUnbiased = isUnbiasedEnabled && isActivityUnbiased;

  const { checkCanApproveDecline, checkCanInvite, checkCanFill, checkCanBook, checkCanFillAndBook } =
    useRoleProfileActionsPermissions();

  const displayApprovalButtons = checkCanApproveDecline(role, profile, shortlist);
  const displayInviteButton = checkCanInvite(role, profile, shortlist);
  const displayFillButton = checkCanFill(role, profile, shortlist);
  const displayFillAndBookButton = checkCanFillAndBook(role, profile, shortlist);
  const displayBookButton = checkCanBook(role, profile, shortlist);

  const candidateNameDisplay = isUnbiased
    ? t("parts.candidateHash", { profileHash: profileHash(profile.id) })
    : getProfileName(profile);

  const handleShortlistActionSuccess = (action: ShortlistAction) =>
    growl({
      kind: "success",
      message: t(`parts.shortlistActionSuccess.${action}`, { name: candidateNameDisplay }),
      ttl: GROWL_TTL
    });

  const handleShortlistActionError = () =>
    growl({ kind: "error", message: t("parts.problemUpdatingShortlist"), ttl: GROWL_TTL });

  const ShortlistActionButton = useMemo(() => {
    if (displayApprovalButtons) {
      return ApprovalButtons;
    }
    if (displayInviteButton) {
      return InviteButton;
    }
    if (displayFillAndBookButton) {
      return FillAndBookButton;
    }
    if (displayFillButton) {
      return FillButton;
    }
    if (displayBookButton) {
      return BookButton;
    }

    return null;
  }, [
    displayApprovalButtons,
    displayBookButton,
    displayFillAndBookButton,
    displayFillButton,
    displayInviteButton
  ]);

  const isShortlisted = !!shortlist;

  return (
    <div className={classNames(css.root, className)}>
      {(isShortlisted || !!customButton) && (
        <div className={css.labelAndIcons}>
          {isShortlisted && <ShortlistStateLabel />}
          {customButton}
        </div>
      )}
      {isShortlisted && !isOnMatchesTab ? (
        ShortlistActionButton && (
          <ShortlistActionButton
            onSuccess={handleShortlistActionSuccess}
            onError={handleShortlistActionError}
          />
        )
      ) : (
        <>
          {!isShortlisted && (
            <ShortlistButton onError={handleShortlistActionError} onSuccess={handleShortlistActionSuccess} />
          )}
          {displayFillAndBookButton && <FillAndBookButton />}
        </>
      )}
    </div>
  );
};
