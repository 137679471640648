import { IsoDate } from "./date_time";
import { Id } from "./id";

export enum HistorySortBy {
  Latest = "latest",
  Oldest = "oldest"
}

export const EventActions = ["created", "updated", "deleted"] as const;
export type EventAction = (typeof EventActions)[number];

export const ActivityMembers = ["assignee", "owner", "reviewer", "interest"] as const;
export type ActivityMember = (typeof ActivityMembers)[number];

export const EventShortlistActions = [
  "assigned",
  "booked",
  "confirmed",
  "created",
  "declined",
  "deleted",
  "filled",
  "rejected",
  "reset"
] as const;
export type EventShortlistAction = (typeof EventShortlistActions)[number];

export type GenericDiff = string[] | { [Key: string]: GenericDiff };

type EventOperatorProfile = {
  id: Id;
  name: string;
};

type CommonActivityHistoryEntryProperties = {
  id: Id;
  operator?: EventOperatorProfile;
  createdAt: IsoDate;
  metadata: Record<string, any>;
};

export type ActivityMemberEvent = CommonActivityHistoryEntryProperties & {
  event: `${ActivityMember}_${EventAction}` | `shortlist_${EventShortlistAction}`;
  diff: {
    principal?: [boolean, boolean];
    profileId: [Id | null, Id | null];
  };
};

export type ActivityShareEvent = CommonActivityHistoryEntryProperties & {
  event: "activity_shared" | "help_requested";
  diff: {
    profileId: [Id | null, Id[] | null];
  };
};

export type ActivityConnectionsEvent = CommonActivityHistoryEntryProperties & {
  event: "activity_connections_updated";
  diff: {
    customTypeId: number;
    customValueId: number;
    diff: GenericDiff;
  }[];
};

export type GenericActivityEvent = CommonActivityHistoryEntryProperties & {
  event: string;
  diff: GenericDiff;
};

export type ActivityEvent =
  | ActivityMemberEvent
  | ActivityShareEvent
  | ActivityConnectionsEvent
  | GenericActivityEvent;

export type ActivityEventType = ActivityEvent["event"];
