import { Checkbox } from "PFComponents/checkbox";
import { CheckboxProps } from "PFComponents/checkbox/checkbox";
import { Inline } from "PFComponents/containers/flex/inline";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";

import css from "../custom_value_preview.module.scss";

type AttributeCheckboxProps = {
  iconName: IconName;
  label: string;
  canEdit: boolean;
  disabledTooltip?: string;
  classes: {
    readonly?: string;
  };
} & Omit<CheckboxProps, "label">;

type CheckboxLabelProps = Pick<AttributeCheckboxProps, "label" | "iconName"> & {
  className?: string;
};

const CheckboxLabel = ({ label, iconName, className }: CheckboxLabelProps) => (
  <Inline gap="spacingSm" alignItems="center" className={className}>
    <Icon name={iconName} size="lg" />
    <Typography variant="bodyRegular" noMargin>
      {label}
    </Typography>
  </Inline>
);

export const AttributeCheckbox = ({
  iconName,
  label,
  checked,
  canEdit,
  classes = {},
  disabled,
  disabledTooltip,
  ...props
}: AttributeCheckboxProps) => {
  if (!canEdit) {
    return checked ? <CheckboxLabel label={label} iconName={iconName} className={classes.readonly} /> : null;
  }

  return (
    <Tooltip
      disabled={!disabled}
      content={
        <Typography variant="bodyRegular" noMargin>
          {disabledTooltip}
        </Typography>
      }
    >
      <div className={css.fitContent}>
        <Checkbox
          {...props}
          checked={checked}
          label={<CheckboxLabel label={label} iconName={iconName} />}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
};
