import { useAppContext } from "PFApp/app_context";
import { GrowlEntry } from "PFApp/components/growls/growls";
import { GrowlProps } from "PFComponents/growl";
import { GROWL_ACTIONS } from "PFCore/reducers/growl_reducer";
import { useCallback } from "react";

export const getGrowlId = (actionId: string, kind: GrowlProps["kind"] = "success") =>
  `${actionId}-${kind}-action`;

export type GrowlPayload = Omit<GrowlEntry, "id" | "timestamp"> & Partial<Pick<GrowlEntry, "id">>;

export const useGrowl = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    (payload: GrowlPayload) => {
      if (payload.hidePreviousByTypeAndId) {
        dispatch({
          type: GROWL_ACTIONS.GROWL_UNRENDER_LAST_BY_TYPE_AND_ID,
          payload
        });
      }
      dispatch({
        type: GROWL_ACTIONS.GROWL_RENDER,
        payload
      });
    },
    [dispatch]
  );
};

export const useHideGrowl = () => {
  const { dispatch } = useAppContext();

  return useCallback(
    (id: GrowlEntry["id"]) => {
      dispatch({
        type: GROWL_ACTIONS.GROWL_UNRENDER,
        payload: { id }
      });
    },
    [dispatch]
  );
};
