import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { MatchCard } from "PFApp/components/activity_match_card";
import { LoadingDots } from "PFComponents/loading_dots";

import { ShortlistTableEntry } from "../../../../activity_tabs.utils";

type ShortlistsCardsViewProps = {
  shortlistsEntries: ShortlistTableEntry[];
  isLoading: boolean;
};

export const ShortlistsCardsView = ({ shortlistsEntries, isLoading }: ShortlistsCardsViewProps) => {
  const {
    task: activity,
    isActivityUnbiased,
    isFillDisabled,
    isProfileInComparison,
    addProfileToComparison,
    removeProfileFromComparison
  } = useActivityPageContext();

  return (
    <div className={commonCss.pageCardEntriesList} data-qa-id="ActivityShortlistsTabCardsContainer">
      {isLoading && <LoadingDots circlesEnabled circleSize="xs" className={commonCss.loading} />}

      {shortlistsEntries.map(({ shortlist, match }) => (
        <MatchCard
          key={shortlist.id}
          match={match}
          shortlist={shortlist}
          role={activity!}
          isBlindModeOn={isActivityUnbiased}
          isProfileInComparison={isProfileInComparison}
          addProfileToComparison={addProfileToComparison}
          removeProfileFromComparison={removeProfileFromComparison}
          isFillDisabled={isFillDisabled}
          areButtonsDisabled={isLoading}
        />
      ))}
    </div>
  );
};
