import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useNumberInput } from "PFCore/hooks/use_number_input";

type NumberInputProps = {
  label: string;
  controlledValue: number | undefined;
  onChange: (value: number | undefined) => void;
  max?: number;
  className?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
};
export const NumberInput = ({
  label,
  controlledValue,
  onChange,
  max,
  className,
  placeholder,
  error,
  disabled
}: NumberInputProps) => {
  const { value, handleChange } = useNumberInput({
    controlledValue,
    onChange,
    min: 0,
    max
  });

  return (
    <InputFieldSet
      label={label}
      min={0}
      max={max}
      value={value}
      onChange={handleChange}
      className={className}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
    />
  );
};
