import isNil from "lodash/isNil";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useActivity } from "PFCore/hooks/queries/activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity, FeatureFlag } from "PFTypes";
import { createContext, ReactNode, useContext, useMemo } from "react";

type BookingActivityContext = {
  id?: number;
  canEdit: boolean;
  isLoading: boolean;
  activity?: Activity;
  parentActivity?: Activity;
  isActivityFetching: boolean;
  isParentActivityFetching: boolean;
  isActivityError: boolean;
  isParentActivityError: boolean;
};

const BookingActivityContext = createContext({} as BookingActivityContext);

const BookingActivityContextProvider = ({ id, children }: { id?: number; children: ReactNode }) => {
  const { data: currentProfile } = useCurrentProfile();

  const {
    data: activity,
    isFetching: isActivityFetching,
    isError: isActivityError
  } = useActivity(id ?? -1, { enabled: !!id });
  const {
    data: parentActivity,
    isFetching: isParentActivityFetching,
    isError: isParentActivityError
  } = useActivity(activity?.parent_activity_id ?? -1, { enabled: !isNil(activity?.parent_activity_id) });

  const isEnabled = useIsFeatureEnabled();

  const canCreateRoles = useMemo(() => {
    const roleTemplate = currentProfile?.templates?.find((item) => item.key === "role");
    return roleTemplate?.create_permitted && !roleTemplate?.hidden;
  }, [currentProfile?.templates]);

  const canEdit = (canCreateRoles ?? false) && isEnabled(FeatureFlag.BookingModule) && !!activity?.coowned;

  const value: BookingActivityContext = {
    id,
    activity,
    parentActivity,
    canEdit,
    isLoading: isActivityFetching || isParentActivityFetching,
    isActivityFetching,
    isParentActivityFetching,
    isActivityError,
    isParentActivityError
  };
  return <BookingActivityContext.Provider value={value}>{children}</BookingActivityContext.Provider>;
};

export const useBookingActivityContext = () => useContext(BookingActivityContext);

export default BookingActivityContextProvider;
