import { AppContextStore } from "PFApp/app_context";
import { CustomValuePreview } from "PFTypes";
import { OmitNonDist } from "PFTypes/omit";

export const CUSTOM_VALUE_PREVIEW_ACTIONS = {
  CUSTOM_VALUE_PREVIEW_SHOW: "CUSTOM_VALUE_PREVIEW_SHOW",
  CUSTOM_VALUE_PREVIEW_HIDE: "CUSTOM_VALUE_PREVIEW_HIDE",
  CUSTOM_VALUE_PREVIEW_UNMOUNT: "CUSTOM_VALUE_PREVIEW_UNMOUNT"
};

type CustomValuePreviewReducerParams = {
  type: string;
  payload: OmitNonDist<CustomValuePreview, "show">;
};

export const customValuePreviewReducer = (
  state: AppContextStore,
  { type, payload }: CustomValuePreviewReducerParams
) => {
  switch (type) {
    case CUSTOM_VALUE_PREVIEW_ACTIONS.CUSTOM_VALUE_PREVIEW_SHOW:
      return {
        ...state,
        customValuePreview: {
          ...payload,
          show: true,
          isClosing: false
        }
      };

    case CUSTOM_VALUE_PREVIEW_ACTIONS.CUSTOM_VALUE_PREVIEW_HIDE:
      return {
        ...state,
        customValuePreview: { ...state.customValuePreview, isClosing: true }
      };

    case CUSTOM_VALUE_PREVIEW_ACTIONS.CUSTOM_VALUE_PREVIEW_UNMOUNT:
      // CUSTOM_VALUE_PREVIEW_UNMOUNT action is meant to finally unmount the component when animation is done
      // It should not be used in the code - use CUSTOM_VALUE_PREVIEW_HIDE instead
      return {
        ...state,
        customValuePreview: { show: false }
      };
  }
};
