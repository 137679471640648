import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { Button } from "PFComponents/button";
import { MultiToggle } from "PFComponents/multi_toggle";
import Pill from "PFComponents/pill/pill";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import canonicalId from "PFCore/helpers/canonicalId";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { Experience } from "PFTypes";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NewSkill } from "../../hooks/use_skills_modal";
import { BlockedSkillsAlert } from "../blocked_skills_alert";
import { Description } from "../description";
import css from "./add_new_skill_section.module.scss";
import { ExperienceOption } from "./experience_option";

type AddNewSkillSectionProps = {
  addNewSkill: VoidFunction;
  skillsValues: string[];
  newSkills: NewSkill[];
  newSkillRating: Experience | null;
  setNewSkillRating: Dispatch<SetStateAction<Experience | null>>;
  addedValues: string[];
  deletedValues: string[];
  handleSkillChange: (newSkills: NewSkill[]) => void;
  blockedSkills: string[];
};

export const AddNewSkillSection = ({
  addNewSkill,
  skillsValues,
  newSkills,
  newSkillRating,
  setNewSkillRating,
  addedValues,
  deletedValues,
  handleSkillChange,
  blockedSkills
}: AddNewSkillSectionProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });

  const { data: currentProfile } = useCurrentProfile();
  const skillsCustomType = useSkillsType();

  const experienceOptions = useMemo(
    () =>
      [Experience.Basic, Experience.Intermediate, Experience.Advanced].map((experience) => ({
        id: experience,
        value: <ExperienceOption experience={experience} isSelected={newSkillRating === experience} />
      })),
    [newSkillRating]
  );

  return (
    <section className={css.root}>
      <Typography variant="h5" noMargin>
        {t("addNewSkill")}
      </Typography>
      <div className={css.skillSelectorContainer}>
        <CustomValuesEditField
          key={newSkills.length}
          kind="bordered"
          label={t("skillsLabel")}
          placeholder={newSkills.length === 0 ? t("skillPlaceholder") : undefined}
          tip=""
          customType={skillsCustomType ?? null}
          values={newSkills as any}
          qaIdPrefix="profile-skills-new-input"
          adminPage={false}
          letClear
          useProfilesEndpoint
          profile={currentProfile}
          handleChange={(changedSkills) => handleSkillChange(changedSkills as any[])}
          hasValuesWithExpiryDate={false}
          disabledValues={skillsValues}
          parseResponse={(options) =>
            options.map((option) => {
              const canonicalOptionText = canonicalId(option.text);
              const isAlreadyAdded =
                (option.added && !deletedValues.includes(canonicalOptionText)) ||
                addedValues.includes(canonicalOptionText);
              const isBlocked = option.state === "blocked";
              const isDisabled = isBlocked || isAlreadyAdded;

              return {
                ...option,
                disabled: isDisabled,
                displayElement: (
                  <Tooltip
                    content={option.description && <Description text={option.description} />}
                    placement="right"
                    interactive
                    className={css.descTooltip}
                  >
                    <span className={css.selectValueWrapper}>
                      {option.text}
                      {isDisabled && (
                        <Pill className={css.pill} small dark>
                          {t(isAlreadyAdded ? "added" : option.state)}
                        </Pill>
                      )}
                    </span>
                  </Tooltip>
                )
              };
            })
          }
        />
        {blockedSkills.length > 0 && <BlockedSkillsAlert blockedSkills={blockedSkills} />}
      </div>
      <MultiToggle<Experience>
        label={t("ratingLabel")}
        controlledValue={newSkillRating}
        options={experienceOptions}
        onChange={setNewSkillRating}
        classes={{ button: css.multiToggleButton }}
        fitHeightToContent
      />
      <Button
        onClick={addNewSkill}
        disabled={blockedSkills.length > 0 || newSkills.length === 0 || !newSkillRating}
        aria-label={t("addNewSkill")}
        className={css.addButton}
      >
        {t("add")}
      </Button>
    </section>
  );
};
