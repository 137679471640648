import api from "PFCore/api";
import {
  AuditPlannerException,
  AuditPlannerExceptionKey,
  AuditRole,
  AutoAllocationSettings,
  Collection,
  Id,
  PaginatedCollection,
  QueryParams,
  WorkflowAutomation
} from "PFTypes";

export const getAuditRoles = ({
  page,
  perPage,
  filters
}: QueryParams): Promise<PaginatedCollection<{ id: Id; activity: AuditRole }[]>> =>
  api({
    url: "audit_roles",
    params: {
      filters,
      perPage: perPage || 100,
      page: page || 1
    }
  });

export const approveExceptionRequest = (
  shortlistId: number,
  approverId: number,
  reason: string
): Promise<Collection<AuditPlannerException[]>> =>
  api({
    url: "exception_requests/approve",
    method: "PUT",
    data: { shortlistId, approverId, reason }
  });

export const rejectExceptionRequest = (
  shortlistId: number,
  approverId: number,
  reason: string
): Promise<Collection<AuditPlannerException[]>> =>
  api({
    url: "exception_requests/reject",
    method: "PUT",
    data: { shortlistId, approverId, reason }
  });

export const editExceptionRequest = (
  shortlistId: number,
  approverId: number
): Promise<Collection<AuditPlannerException[]>> =>
  api({
    url: "exception_requests",
    method: "PUT",
    data: { approverId, shortlistId }
  });

export const getExceptionRequests = (
  activityId: number,
  profileIds: number | number[]
): Promise<Collection<AuditPlannerException[]>> =>
  api({
    url: "exception_requests",
    params: {
      activityId,
      profileIds
    }
  });

export const checkExceptions = (shortlistId: number): Promise<AuditPlannerExceptionKey> =>
  api({
    url: `shortlists/${shortlistId}/check_exceptions`
  });

export const getWorkflowAutomation = (): Promise<WorkflowAutomation> =>
  api({
    url: "workflow_automation"
  });

export const runWorkflowAutomation = (): Promise<WorkflowAutomation> =>
  api({
    url: "workflow_automation",
    method: "POST"
  });

export const fetchAutoAllocationSettings = (): Promise<AutoAllocationSettings> =>
  api({
    url: "auto_allocation_settings"
  });

export const setAutoAllocationSettings = (alwaysOn: boolean): Promise<AutoAllocationSettings> =>
  api({
    url: "auto_allocation_settings",
    method: "PUT",
    data: {
      alwaysOn
    }
  });
