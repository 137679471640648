import { ActionIcon } from "PFComponents/action_icon";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { IconName } from "PFTheme/graphics/icons";
import { useState } from "react";

import WarningTooltipContent from "./warning_tooltip_content";
import css from "./warning_tooltip_icon.module.scss";

interface WarningTooltipIconProps {
  label: string;
  counter: number;
  classes?: Record<string, string>;
  renderExpanded?: () => React.ReactNode;
  icon: IconName;
  height?: number;
  width?: number;
  iconColor?: string;
  counterColor?: string;
  onClick?: VoidFunction;
  title?: string;
}

const WarningTooltipIcon = ({
  icon,
  label,
  counter,
  classes = {},
  renderExpanded,
  iconColor,
  counterColor,
  onClick,
  title
}: WarningTooltipIconProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => setExpanded((prev) => !prev);

  const root = onClick ? (
    <div>
      <ActionIcon onClick={onClick} size="sm" name={icon} fill={iconColor} title={title} />
    </div>
  ) : (
    <div className={css.iconContainer}>
      <Icon name={icon} size="sm" fill={iconColor} />
    </div>
  );

  return (
    <Tooltip
      content={
        <WarningTooltipContent
          label={label}
          counter={counter}
          expanded={expanded}
          renderExpanded={renderExpanded}
          onToggleExpanded={toggleExpanded}
          classes={{
            counter: classes.counter
          }}
          counterColor={counterColor}
        />
      }
      interactive={!!renderExpanded}
      onHidden={() => {
        setExpanded(false);
      }}
      theme="pf-translucent"
      maxWidth={400}
    >
      {root}
    </Tooltip>
  );
};

export default WarningTooltipIcon;
