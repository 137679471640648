import classNames from "classnames";
import { useAppContext } from "PFApp/app_context";
import { Button } from "PFComponents/button";
import Hr from "PFComponents/hr";
import { Icon } from "PFComponents/icon";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import SuggestionIcon from "PFIcons/chat_suggestion.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import cssCommon from "../details/activity_details_common.module.scss";
import ActivityDetailsMainChat from "../details/activity_details_main_chat";
import css from "./chats_section.module.scss";

const ChatsSectionMainChat = ({
  collapsed,
  handleChatSectionClick,
  isEngagement,
  primaryConversation,
  allParticipants,
  nonOwnerParticipants,
  renderSeparator,
  qaId
}) => {
  const {
    store: { themeVars }
  } = useAppContext();
  const { t } = useTranslation("activities");

  const isUnread = !!primaryConversation?.unread_count;

  return (
    <div
      className={classNames(css.section, {
        [css.isOpen]: !collapsed,
        [css.wideSection]: isEngagement
      })}
    >
      {renderSeparator && <Hr />}
      <Button
        kind="blank"
        className={classNames(css.sectionHeader, {
          [cssCommon.headerEmpty]: !allParticipants || allParticipants.length === 0
        })}
        onClick={handleChatSectionClick}
        qaId={qaId}
      >
        <div className={css.headerAlign}>
          <SuggestionIcon style={isUnread ? { fill: themeVars["--Palette-warning"] } : {}} />
          <div className={css.textWrap}>
            <Typography variant="h5" noMargin>
              {t("show.parts.activityChat")} -&nbsp;
              <span className={css.isLoadingSection}>
                {!allParticipants ? (
                  <LoadingDots />
                ) : (
                  <>{t("show.parts.participantsCount", { count: allParticipants.length })}</>
                )}
              </span>
            </Typography>
          </div>
        </div>
        <Icon name={collapsed ? "add" : "subtract"} size="sm" />
      </Button>
      <div
        className={classNames(css.contentSection, {
          [css.collapsed]: collapsed,
          [css.wideSection]: isEngagement
        })}
      >
        <ActivityDetailsMainChat
          allParticipants={allParticipants}
          nonOwnerParticipants={nonOwnerParticipants}
          primaryConversation={primaryConversation}
          isEngagement={isEngagement}
        />
      </div>
    </div>
  );
};

ChatsSectionMainChat.propTypes = {
  collapsed: PropTypes.bool,
  handleChatSectionClick: PropTypes.func,
  allParticipants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      profile: PropTypes.object.isRequired
    })
  ),
  primaryConversation: PropTypes.object,
  nonOwnerParticipants: PropTypes.array,
  isEngagement: PropTypes.bool,
  renderSeparator: PropTypes.bool,
  qaId: PropTypes.string
};

export default ChatsSectionMainChat;
