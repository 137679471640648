import classNames from "classnames";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";
import { Id } from "PFTypes";
import { DataDogProps } from "PFTypes/datadog";
import { JSX, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { CountTag } from "./parts/count_tag";
import css from "./tabs.module.scss";

export enum TabsVariant {
  Horizontal = "horizontal",
  Vertical = "vertical"
}

export enum TabVariant {
  Default = "default",
  Danger = "danger"
}

export type Tab<TabType> = {
  id: TabType;
  title: string;
  subtitle?: string;
  href?: string;
  count?: number;
  icon?: IconName;
  variant?: TabVariant;
  disabled?: boolean;
  ariaLabel?: string;
} & DataDogProps;

type TabsProps<TabType> = {
  tabs: Tab<TabType>[];
  variant?: TabsVariant;
  onChange: (id: TabType) => void;
  activeTab?: Id;
  className?: string;
  qaId?: string;
};

export const Tabs = <TabType extends Id = Id>({
  tabs,
  activeTab,
  variant = TabsVariant.Horizontal,
  onChange,
  className,
  qaId
}: TabsProps<TabType>): JSX.Element => {
  const history = useHistory();

  const areIconsAvailable = useMemo(() => tabs.some((tab) => tab.icon), [tabs]);

  return (
    <section
      className={classNames(css.navigationContainer, className, {
        [css.vertical]: variant === TabsVariant.Vertical
      })}
    >
      {tabs.map(({ title, id, subtitle, count, href, icon, variant, disabled, ariaLabel, ddActionName }) => {
        const isIdSelected = activeTab === id;
        const handleOnClick = () => {
          onChange(id);
          if (href) {
            history.push(href);
          }
        };

        return (
          <nav key={id}>
            <button
              onClick={handleOnClick}
              className={classNames(css.tabContainer, css.button, {
                [css.selected]: isIdSelected
              })}
              data-qa-id={qaId ? `${qaId}.${id}` : null}
              disabled={disabled}
              aria-label={ariaLabel || title}
              title={ariaLabel || title}
              data-dd-action-name={ddActionName}
            >
              {icon && <Icon name={icon} size="sm" />}
              <div className={areIconsAvailable && !icon ? css.textWithIconPlaceholder : css.text}>
                <div className={css.titleSection}>
                  <Typography
                    variant={isIdSelected ? "bodyBold" : "bodyRegular"}
                    noMargin
                    className={classNames(css.title, {
                      [css.selected]: isIdSelected
                    })}
                    wrapText={true}
                  >
                    {title}
                  </Typography>
                  {count !== undefined && (
                    <CountTag highlight={!!isIdSelected} count={count} variant={variant} />
                  )}
                </div>
                <Typography variant="labelRegular" noMargin className={css.title}>
                  {subtitle}
                </Typography>
              </div>
            </button>
          </nav>
        );
      })}
    </section>
  );
};
