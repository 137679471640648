import { FillButton } from "PFApp/activities/parts/fill_button";
import { InviteButton } from "PFApp/activities/parts/invite_buttons";
import { FillAndBookButton } from "PFApp/components/fill_and_book_button";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { Modal } from "PFComponents/modal";
import { getProfileName, isProfileActive } from "PFCore/helpers/profile";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import colors from "PFTheme/tokens/colors";
import { PermissionRule } from "PFTypes";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonLabel from "../../../parts/button_label";
import { useActivityPageContext } from "../../activity_page_context";
import AssignButton from "../../parts/assign_button";
import ExceptionsWarning from "./exceptions_warning";
import css from "./shortlist_item_buttons.module.scss";

const AcceptedButtons = ({
  item,
  task,
  isResourcer,
  isReviewer,
  isRole,
  qaPrefix,
  hasException,
  isBlindModeOn,
  template
}) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("activities");

  const { vacanciesLoading, existsOpenVacancy } = useActivityPageContext();
  const { mutate: resetShortlist } = useShortlistReset();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const isAutoBookingCreationEnabled = !!template?.automations?.auto_create_bookings?.enabled;
  const { canProfileCreateBooking } = useBookingModulePermissions();
  const { bookingCategories } = useBookingCategories();
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !!demandRelated)
    .some((i) => !i.readonly);
  const canBook = canProfileCreateBooking(item.profile.id) && hasWriteAccessToAtLeastOneCategory;

  const reviewCompleted = !!task.review?.completed_at;
  const hasAcceptForEndUser = isPermittedTo(PermissionRule.AcceptForEndUser);
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const isShortlistActive = isProfileActive(item.profile);
  const canFill = task.state !== "draft" && task.coowned && isResourcer && isShortlistActive;
  const canInvite =
    task.state !== "draft" && (task.coowned || isReviewer) && isShortlistActive && !reviewCompleted;
  const isAssigned = item.state === "assigned";
  const canUndo = !isAssigned && (task.coowned || isReviewer) && !reviewCompleted;

  const status = task.coowned ? t("show.shortlist.state.accepted") : t("show.shortlist.state.approved");

  const renderActionButton = () =>
    isAutoBookingCreationEnabled && canBook ? (
      <FillAndBookButton
        profileId={item.profile.id}
        shortlistId={item.id}
        activity={task}
        isFillButtonDisabled={vacanciesLoading || !existsOpenVacancy}
      />
    ) : (
      <FillButton
        qaPrefix={qaPrefix}
        disabled={vacanciesLoading || !existsOpenVacancy}
        isUnbiased={isBlindModeOn}
        item={item}
      />
    );

  return (
    <div className={css.rolesActions}>
      {isRole ? (
        template.shortlist_invites && canInvite ? (
          isAssigned && canFill && hasAcceptForEndUser ? (
            renderActionButton()
          ) : (
            <AssignButton item={item} isBlindModeOn={isBlindModeOn} />
          )
        ) : canFill ? (
          renderActionButton()
        ) : (
          <>&nbsp;</>
        )
      ) : canInvite ? (
        <InviteButton profile={item.profile} task={task} />
      ) : (
        <>&nbsp;</>
      )}
      <div className={css.acceptedButtons}>
        {hasException && <ExceptionsWarning />}
        {canUndo && (
          <Button
            text={t("undo")}
            kind="blank"
            qaId={`${qaPrefix}-undo`}
            onClick={() => {
              const isAccepted = item.state === "accepted";
              if (isAccepted) {
                setDisplayConfirm(true);
              } else {
                resetShortlist(item.id);
              }
            }}
          />
        )}
        <ButtonLabel>
          <Icon name="check" size="sm" fill={colors.palettePrimaryAction} />
          {status}
        </ButtonLabel>
        {displayConfirm && (
          <Modal
            title={t("show.shortlist.removeStatus")}
            labelOK="Confirm" // Avoid "accept" (default) for "unaccepting"
            onOK={() => {
              resetShortlist(item.id);
            }}
            onClose={() => setDisplayConfirm(false)}
            kindOK="danger"
          >
            {t("show.shortlist.removeStatusOnActivity", {
              status: status.toLowerCase(),
              name: getProfileName(item.profile)
            })}
          </Modal>
        )}
      </div>
    </div>
  );
};

AcceptedButtons.propTypes = {
  item: PropTypes.object,
  task: PropTypes.object,
  isResourcer: PropTypes.bool,
  isReviewer: PropTypes.bool,
  isRole: PropTypes.bool,
  qaPrefix: PropTypes.string,
  hasException: PropTypes.bool,
  isBlindModeOn: PropTypes.bool,
  template: PropTypes.object
};

export default AcceptedButtons;
