import { Button } from "PFComponents/button";
import { Stack } from "PFComponents/containers/flex/stack";
import { LoadingDots } from "PFComponents/loading_dots";
import { useOptions } from "PFComponents/select_v2/use_options"; // TODO: [SP-2251] Move useOptions hook to common directory
import { FilterWithDefaults, Id } from "PFTypes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { createDropdownId } from "../filters.utils";
import { useFiltersOptions } from "../use_filters_options";
import css from "./expanded_filter_wrapper.module.scss";

const COLLAPSED_ITEMS_COUNT = 5;

type Option = {
  id: Id;
  value: string;
};

export type ExpandedFilterParams = {
  filter: FilterWithDefaults;
  onChange: (values: Id | Id[] | null) => void;
  disabled?: boolean;
  options: Option[];
};

type ExpandedFilterWrapperProps = Pick<ExpandedFilterParams, "filter" | "onChange" | "disabled"> & {
  component: React.FC<ExpandedFilterParams>;
  searchId?: number;
};

export const ExpandedFilterWrapper = ({
  filter,
  onChange,
  disabled,
  searchId,
  component: Component
}: ExpandedFilterWrapperProps) => {
  const { t } = useTranslation("core");
  const [showAll, toggleShowAll] = useToggle(false);

  const { options: staticOptions, fetchOptions } = useFiltersOptions({ filter });
  const { data: fetchedOptions, isFetching } = useOptions({
    dropdownId: createDropdownId(filter, searchId),
    fetchOptions
  });

  const fullOptionsList = staticOptions ?? fetchedOptions;

  const options = showAll ? fullOptionsList : fullOptionsList.slice(0, COLLAPSED_ITEMS_COUNT);
  const isExpandable = fullOptionsList.length > COLLAPSED_ITEMS_COUNT;

  return (
    <Stack gap="spacingMd">
      <Component filter={filter} onChange={onChange} disabled={disabled} options={options as Option[]} />
      {!isFetching && isExpandable && (
        <Button
          kind="text"
          text={showAll ? t("showLess") : t("showMore")}
          onClick={toggleShowAll}
          className={css.moreButton}
        />
      )}
      {isFetching && <LoadingDots circleSize="xs" circlesEnabled />}
    </Stack>
  );
};
