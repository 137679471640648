import { Filter } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import { useRef } from "react";

import { SaveAsView } from "../save_as_view";
import { FiltersBaseProps, FiltersList } from "./filters_list";
import css from "./plain_filters.module.scss";

type PlainFiltersProps = {
  filters: Filter[];
  onFiltersChange?: (filters: any) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  viewsKey?: PageTarget | null;
  isRelevantToMeDisabled?: boolean;
} & FiltersBaseProps;

export const PlainFilters = ({
  filters,
  meta,
  onFiltersChange,
  onFilterChange,
  clearRefValue,
  disabled,
  narrow,
  style,
  viewsKey = null,
  fetchOptions,
  isRelevantToMeDisabled
}: PlainFiltersProps): JSX.Element => {
  const lastFocusedFilterNameRef = useRef(null);

  const handleFocus = (filter) => {
    if (lastFocusedFilterNameRef.current !== filter.name) {
      lastFocusedFilterNameRef.current = filter.name;
    }
  };

  const handleBlur = () => {
    if (lastFocusedFilterNameRef.current) {
      lastFocusedFilterNameRef.current = null;
    }
  };

  const filtersElementProps = {
    filters,
    viewsKey,
    clearRefValue,
    fetchOptions,
    lastFocusedFilterNameRef,
    meta,
    narrow,
    disabled,
    onFilterChange,
    onFocus: handleFocus,
    onBlur: handleBlur
  };

  return (
    <div style={style} className={css.root}>
      <FiltersList {...filtersElementProps} />
      {viewsKey && (
        <SaveAsView
          meta={meta}
          clearFilters={onFiltersChange}
          viewsKey={viewsKey}
          isRelevantToMeDisabled={isRelevantToMeDisabled}
        />
      )}
    </div>
  );
};
