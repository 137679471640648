import { useAppContext } from "PFApp/app_context";
import { ACTIVITY_PREVIEW_ACTIONS } from "PFReducers/activity_preview_reducer";
import { Activity, HistorySortBy } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

import { useProfilePreview } from "../../../use_profile_preview";
import { ActivityHistorySidePanel } from "./activity_history_side_panel";

export type ActivityHistoryContextType = {
  activity: Activity | undefined;
  sortBy: HistorySortBy;
  setSortBy: Dispatch<SetStateAction<HistorySortBy>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  openHistory: (activity: Activity | undefined) => void;
  closeHistory: VoidFunction;
};

const ActivityHistoryContext = createContext<ActivityHistoryContextType>({} as ActivityHistoryContextType);

export const ActivityHistoryProvider = ({ children }: PropsWithChildren) => {
  const [sortBy, setSortBy] = useState<HistorySortBy>(HistorySortBy.Latest);
  const [page, setPage] = useState(1);
  const [activityToInspect, setActivityToInspect] = useState<Activity | undefined>(undefined);
  const { dispatch } = useAppContext();
  const { hidePreview } = useProfilePreview();

  const openHistory = useCallback(
    (activity: Activity | undefined) => {
      setActivityToInspect(activity);
      if (!activity) {
        return;
      }

      dispatch({
        type: ACTIVITY_PREVIEW_ACTIONS.ACTIVITY_PREVIEW_HIDE
      });
      hidePreview();
    },
    [dispatch, hidePreview]
  );

  const contextValue = useMemo(
    () => ({
      sortBy,
      setSortBy,
      page,
      setPage,
      activity: activityToInspect,
      openHistory,
      closeHistory: () => setActivityToInspect(undefined)
    }),
    [activityToInspect, openHistory, sortBy, page]
  );

  const show = !!activityToInspect;

  return (
    <ActivityHistoryContext.Provider value={contextValue}>
      <ActivityHistorySidePanel show={show} onClose={() => setActivityToInspect(undefined)} />
      {children}
    </ActivityHistoryContext.Provider>
  );
};

export const useActivityHistoryContext = () => useContext(ActivityHistoryContext);
