import classNames from "classnames";
import { useAppContext } from "PFApp/app_context";
import { ACTIVITY_PREVIEW_ACTIONS } from "PFReducers/activity_preview_reducer";
import PropTypes from "prop-types";

import { useActivityHistoryContext } from "../../activities/parts/history/activity_history_context";
import css from "./activity_preview_link.module.scss";

const ActivityPreviewLink = ({ activity, profile, className, children }) => {
  const { dispatch } = useAppContext();
  const closeHistory = useActivityHistoryContext()?.closeHistory || (() => ({}));

  return (
    <button
      className={classNames(css.previewLink, className)}
      onClick={() => {
        closeHistory?.();
        dispatch({
          type: ACTIVITY_PREVIEW_ACTIONS.ACTIVITY_PREVIEW_SHOW,
          payload: {
            id: activity.id,
            profile
          }
        });
      }}
    >
      {children || activity.name}
    </button>
  );
};

ActivityPreviewLink.propTypes = {
  children: PropTypes.node,
  profile: PropTypes.object,
  activity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string
  }).isRequired,
  className: PropTypes.string
};

export default ActivityPreviewLink;
