import classNames from "classnames";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { Modal } from "PFComponents/modal";
import { isProfileActive } from "PFCore/helpers/profile";
import { useIsShortlistingPermitted } from "PFCore/helpers/shortlists";
import { useActivityInvalidate, useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { Shortlist as ShortlistType } from "PFTypes";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useActivityCurrentUserRole } from "../../../hooks";
import ButtonLabel from "../../../parts/button_label";
import ActivityPageContext from "../../activity_page_context";
import { BookButton } from "../../parts/book_button/book_button";
import { useBookButtonPermissions } from "../../parts/book_button/use_book_button_permissions";
import ExceptionsWarning from "./exceptions_warning";
import css from "./shortlist_item_buttons.module.scss";

type RespondedTagProps = {
  item: ShortlistType;
  qaPrefix?: string;
};

const RespondedTag = ({ item, qaPrefix }: RespondedTagProps): JSX.Element => {
  const { task, isAudit } = useContext(ActivityPageContext);

  const { mutate: resetShortlist } = useShortlistReset();
  const { update: updateActivity } = useActivityUpdate(task?.id);
  const { invalidateVacancies } = useActivityInvalidate();

  const { t } = useTranslation(["activities", "translation"]);

  const growl = useGrowl();
  const history = useHistory();

  const { isReviewer } = useActivityCurrentUserRole(task);
  const canBook = useBookButtonPermissions({
    state: item.state,
    coowned: !!task?.coowned,
    isReviewer
  });
  const isShortlistActive = isProfileActive(item.profile);
  const isBookButtonVisible = isShortlistActive && canBook;

  const isShortlistingPermitted = useIsShortlistingPermitted();

  const isFilledOrDeclined = item.state === "filled" || item.state === "declined";
  const hasException = task?.workflow_state === "exception";
  const [displayUndo, setDisplayUndo] = useState(false);
  const canUndo = task?.coowned; // only owner can undo filled role

  return (
    <div className={classNames(css.respondedButtons, { [css.bookButtonDisabled]: !isBookButtonVisible })}>
      {isBookButtonVisible && <BookButton profileId={item.profile.id} shortlistId={item.id} />}
      <div className={css.filledButtons}>
        {isAudit ? (
          task?.coowned && (
            <>
              {hasException && <ExceptionsWarning />}
              <Button
                text={t("translation:undo")}
                kind="secondary"
                disabled={hasException}
                onClick={() => setDisplayUndo(true)}
                qaId={`${qaPrefix}-undo`}
              />
            </>
          )
        ) : (
          <>
            {canUndo && (
              <Button kind="blank" onClick={() => setDisplayUndo(true)} qaId={`${qaPrefix}-undo`}>
                {t("translation:undo")}
              </Button>
            )}
            {(isShortlistingPermitted(task) || isReviewer) && (
              <ButtonLabel>
                {isFilledOrDeclined && <Icon name="user-filled" size="sm" />}
                {t(`activities:show.shortlist.state.${item.state}`)}
              </ButtonLabel>
            )}
          </>
        )}
      </div>

      {displayUndo && (
        <Modal
          title={
            isAudit
              ? t("activities:show.shortlist.unallocateResource")
              : t("activities:show.shortlist.unfillRole")
          }
          labelOK={t("translation:continue")}
          onOK={() =>
            resetShortlist(item.id, {
              onSuccess: () => {
                /* @ts-ignore */
                updateActivity(); // this is needed to trigger refetching callback on audit planner
                /* @ts-ignore */
                task && invalidateVacancies(task.id);
                growl({
                  kind: "success",
                  message: isAudit
                    ? t("activities:show.shortlist.resourceUnallocated")
                    : t("activities:show.shortlist.roleUnfilled")
                });
                if (isAudit) {
                  history.push("/audit_planner/control_centre");
                }
              },
              onError: () => {
                /* @ts-ignore */
                updateActivity();
              }
            })
          }
          onClose={() => {
            setDisplayUndo(false);
          }}
          kindOK="danger"
        >
          {isAudit
            ? t("activities:show.shortlist.wantUnallocateResource")
            : t("activities:show.shortlist.wantUnfillRole")}
        </Modal>
      )}
    </div>
  );
};

export default RespondedTag;
