import { Button } from "PFComponents/button";
import WarningIcon from "PFIcons/warning.svg";
import { useTranslation } from "react-i18next";

import { getExceptionType } from "../../../helpers";
import css from "../../modals.module.scss";
import { FillRoleContent } from "../../parts/fill_role_content";
import { RequestApprovalModalStepProps } from "../types";

export const RequestApprovalModalStepOne = ({
  close,
  engagement,
  role,
  setStepIndex,
  exception,
  shortlistItem
}: RequestApprovalModalStepProps) => {
  const exceptionType = getExceptionType(exception);
  const { t } = useTranslation(["translation", "auditPlanner"]);

  const { profile } = shortlistItem;
  const shouldShowSoftOverbooking =
    !exception.availability &&
    !!shortlistItem?.scores?.availabilityScore &&
    shortlistItem?.scores?.availabilityScore !== 1;

  return (
    <FillRoleContent
      engagement={engagement}
      role={role}
      profile={profile}
      shouldShowSoftOverbooking={shouldShowSoftOverbooking}
    >
      <div className={css.flexCenter}>
        <div>
          <WarningIcon
            className={css.icon}
            style={{ marginLeft: "unset", width: 90 }}
            width={60}
            height={60}
          />
        </div>

        <span style={{ marginLeft: 10 }}>
          <div className={css.bold}>
            {t("auditPlanner:modals.potentialExceptionDetected", { exceptionType })}
          </div>
          <div> {t("auditPlanner:modals.allActionsRecordedInLog")}</div>
        </span>
      </div>
      <div className={css.buttons}>
        <Button text={t("translation:cancel")} kind="secondary" onClick={close} />
        <Button text={t("translation:continue")} onClick={() => setStepIndex(1)} />
      </div>
    </FillRoleContent>
  );
};
