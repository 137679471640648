import classNames from "classnames";
import { useProfilePreview } from "PFApp/use_profile_preview";
import { ExpandableCard } from "PFComponents/expandable_card";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile } from "PFTypes";
import { forwardRef, useCallback } from "react";
import { TransitionStatus } from "react-transition-group";

import { useMatchCardContext } from "../../match_card_context";
import { MatchCardBody } from "../match_card_body";
import { MatchCardFooter } from "../match_card_footer";
import { MatchCardHeader } from "../match_card_header";
import css from "./animated_match_card.module.scss";

type AnimatedMatchCardProps = {
  state: TransitionStatus;
};

export const AnimatedMatchCard = forwardRef<HTMLDivElement, AnimatedMatchCardProps>(({ state }, ref) => {
  const { showPreview } = useProfilePreview();
  const { profile, animationTrigger, isBlindModeOn, isProfileSelectedToCompare } = useMatchCardContext();

  const profileName = getProfileName(profile);

  const handleOpenProfile = useCallback(() => {
    if (isBlindModeOn) {
      return;
    }

    showPreview({ id: profile.id, profile: profile as Profile });
  }, [profile.id, showPreview, isBlindModeOn]);

  return (
    <ExpandableCard
      ref={ref}
      header={<MatchCardHeader />}
      onClick={handleOpenProfile}
      ariaLabel={profileName}
      className={classNames(css.card, css[state], {
        [css.selectedCard]: isProfileSelectedToCompare
      })}
      animationTrigger={animationTrigger}
      footer={<MatchCardFooter />}
    >
      <MatchCardBody />
    </ExpandableCard>
  );
});

AnimatedMatchCard.displayName = "AnimatedMatchCard";
