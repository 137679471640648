import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes";

import { bookingEngagementsKeys } from "../bookings/engagements/query_keys";
import activityKeys from "./query_keys";

export const useActivityInvalidate = () => {
  const cache = useQueryClient();
  return {
    invalidate: (activityIds?: Id[]) => {
      if (!activityIds) {
        return cache.invalidateQueries(activityKeys.all());
      }
      return Promise.all(
        activityIds.map((activityId) => {
          cache.invalidateQueries({
            queryKey: activityKeys.activity(activityId)
          });
          // When activity is updated, chart data in BM may change
          cache.invalidateQueries({
            queryKey: bookingEngagementsKeys.economicsAll()
          });
        })
      );
    },
    removeCache: (activityIds?: Id[]) => {
      if (!activityIds) {
        cache.removeQueries(activityKeys.all());
        return;
      }
      activityIds.forEach((activityId) => {
        cache.removeQueries({
          queryKey: activityKeys.activity(activityId)
        });
      });
    },
    invalidateWatchers: (activityId) => cache.invalidateQueries(activityKeys.watchers(activityId)),
    invalidateFeedbacks: () => cache.invalidateQueries(activityKeys.feedback()),
    invalidateFeedbackParticipants: (activityId) =>
      cache.invalidateQueries(activityKeys.feedbackParticipants(activityId)),
    invalidateVacancies: (activityId) => cache.invalidateQueries(activityKeys.vacancies(activityId)),
    invalidateActivityWarnings: (activityId) => cache.invalidateQueries(activityKeys.warnings(activityId))
  };
};
