import { differenceBy, uniqBy } from "lodash";
import { Checkbox } from "PFComponents/checkbox";
import { DropdownButton } from "PFComponents/dropdown_button";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import { Id } from "PFTypes/id";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./bulk_select.module.scss";

type Item = {
  id: Id;
};
export type BulkSelectProps = {
  className?: string;
  allItems: Item[];
  selectedItems: Item[];
  setSelectedItems?: Dispatch<SetStateAction<Item[]>>;
  disabled?: boolean;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  showDropdown?: boolean;
  onSelectAll?: () => void;
  onSelectNone?: () => void;
  displayLabel?: boolean;
};

const BulkSelect = ({
  className,
  allItems,
  buttonStyle,
  disabled,
  displayLabel = false,
  onSelectAll,
  onSelectNone,
  selectedItems,
  setSelectedItems,
  style,
  showDropdown = true
}: BulkSelectProps) => {
  const { t } = useTranslation();

  const selectAll = useCallback(() => {
    setSelectedItems?.((selectedItems) => uniqBy([...selectedItems, ...allItems], "id"));
    onSelectAll?.();
  }, [onSelectAll, setSelectedItems, allItems]);

  const selectNone = useCallback(() => {
    setSelectedItems?.([]);
    onSelectNone?.();
  }, [onSelectNone, setSelectedItems]);

  const handleCheckboxClick = useCallback(
    (value: boolean) => {
      value ? selectAll() : selectNone();
    },
    [selectAll, selectNone]
  );

  const { allChecked, partiallyChecked } = useMemo(() => {
    const noneChecked = selectedItems.length === 0;
    const allChecked =
      !noneChecked &&
      selectedItems.length >= allItems.length &&
      differenceBy(allItems, selectedItems, "id").length === 0;
    const partiallyChecked = !noneChecked && !allChecked;

    return { allChecked, partiallyChecked };
  }, [allItems, selectedItems]);

  const actions = useMemo(
    () => [
      {
        id: "select_all",
        global_id: "select_all",
        displayElement: t("all"),
        item: selectAll,
        disabled: false
      },
      {
        id: "select_none",
        global_id: "select_none",
        displayElement: t("none"),
        item: selectNone,
        disabled: false
      }
    ],
    [selectAll, selectNone]
  );

  if (!showDropdown) {
    return (
      <div className={css.noDropdownWrapper}>
        <Checkbox
          checked={allChecked}
          onChange={handleCheckboxClick}
          indeterminate={partiallyChecked}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <div className={css.container}>
      <Checkbox
        checked={allChecked}
        onChange={handleCheckboxClick}
        indeterminate={partiallyChecked}
        disabled={disabled}
      />
      <DropdownButton
        buttonKind="blank"
        disabled={disabled}
        dropDownClassName={className}
        buttonStyle={{ borderRadius: 5, position: "relative", padding: 0, ...buttonStyle }}
        options={actions}
        style={style}
        handleChange={(fn) => fn?.()}
      >
        <DownArrowIcon className={css.arrowIcon} height={20} />
      </DropdownButton>
      {displayLabel && (
        <span>{t("countOfTotal", { count: selectedItems.length, total: allItems.length })}</span>
      )}
    </div>
  );
};

export default BulkSelect;
