import { CustomValueScope, Id } from "PFTypes";

export const customTypeKeys = {
  all: () => ["customTypes"],
  single: (id) => [...customTypeKeys.all(), id]
};

export const customValueKeys = {
  singleUnscoped: (id: Id) => ["customValues", CustomValueScope.Unscoped, id],
  singleInProfileScope: (customValueId: Id, profileId: Id) => [
    "customValues",
    CustomValueScope.Profile,
    customValueId,
    profileId
  ],
  customValueActivityConnection: (customValueId: Id, activityId: Id) => [
    "customValues",
    CustomValueScope.Activity,
    customValueId,
    activityId
  ],
  customValueInsights: (id: Id) => ["customValues", "insights", id]
};
