import classNames from "classnames";
import PropTypes from "prop-types";

import { BasicPill } from "./basic_pill";
import { useDDActionNameLabel } from "./helpers";
import css from "./pill.module.scss";
const RolePill = ({ children, onClick, className, style, state }) => {
  const title = useDDActionNameLabel();

  const roleClassName =
    {
      booked: "roleBooked",
      partially_booked: "rolePartiallyBooked"
    }[state] || "roleNotBooked";

  return (
    <BasicPill
      className={classNames(css[roleClassName], className)}
      style={style}
      onClick={onClick}
      title={title}
    >
      {children}
    </BasicPill>
  );
};

RolePill.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  state: PropTypes.string.isRequired
};

export default RolePill;
