import { ProjectItem } from "PFCore/hooks/queries/bookings/projects/use_projects_entries";
import { WorkforceItem } from "PFCore/hooks/queries/bookings/workforce/use_workforce_entries";
import { Profile } from "PFTypes";

export type Bar = { start: string; end: string; type: BarsType } & Partial<{
  id: number;
  categoryId: number;
  load: number;
  isRelatedToParentRowActivity?: boolean;
  state: string;
  activityId: number | null;
  title: string;
  duration: any;
  calculatedLoad: number;
  barsCount: number;
}>;

export type BarsType = "booking" | "role" | "engagement" | "misalignment";

export enum RowType {
  Engagement = "engagement",
  Role = "role",
  Profile = "profile",
  Category = "category"
}

export type TooltipDetails = {
  categoryName: string;
  bookedHours: number;
};

export type ProfileBasic = Pick<Profile, "id" | "first_name" | "last_name">;
export type CategoryRowItem = {
  id: string;
  hiddenCategory?: boolean;
  bookingCategoryIds: number[];
};

export type RowOriginal = ProjectItem | WorkforceItem | ProfileBasic | CategoryRowItem;

export type WorkforceItemResource = WorkforceItem["profile"];
export type ProjectItemResource = ProjectItem["activity"];

export type RowResource = WorkforceItemResource | ProjectItemResource | ProfileBasic | null;

export type Row = {
  key: string;
  parents: string[];
  type: RowType;
  original: RowOriginal;
};
