import { useExperienceText } from "PFApp/activities/edit/modals/skills_framework_select_modal/use_experience_text";
import { ExperienceLevel } from "PFComponents/experience_level";
import { Typography } from "PFComponents/typography";
import { Experience } from "PFTypes";

import css from "./experience_option.module.scss";

type ExperienceOptionProps = {
  experience: Experience;
  isSelected: boolean;
};

export const ExperienceOption = ({ experience, isSelected }: ExperienceOptionProps) => {
  const getExperienceText = useExperienceText();

  return (
    <div className={css.experienceOption}>
      <ExperienceLevel size="xxs" experience={experience} theme={isSelected ? "dark" : "light"} />
      <Typography variant="bodyRegular" noMargin clipOverflow>
        {getExperienceText(experience)}
      </Typography>
    </div>
  );
};
