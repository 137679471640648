import { useQuery } from "@tanstack/react-query";
import { fetchActivityCustomValueConnection } from "PFCore/services/custom_values";
import { CustomValueActivityConnection, Id } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { customValueKeys } from "./query_keys";

export const useCustomValueActivityConnection = (
  customValueId: Id,
  activityId: Id,
  options?: QueryOptions<CustomValueActivityConnection>
) =>
  useQuery<CustomValueActivityConnection>({
    queryKey: customValueKeys.customValueActivityConnection(customValueId, activityId),
    queryFn: () => fetchActivityCustomValueConnection(customValueId, activityId),
    retry: false,
    ...options
  });
