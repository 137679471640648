import { useQuery } from "@tanstack/react-query";
import { fetchCertificates } from "PFApp/profiles/services/api";
import { PaginatedCollection, QueryOptions, QueryParams } from "PFTypes";
import { Certificate } from "PFTypes/certificate";
import { Id } from "PFTypes/id";

import { certificateKeys } from "./query_keys";

type UseCertificates = {
  profileId: Id;
  customValueIds?: Id[];
  options?: QueryOptions<PaginatedCollection<Certificate[]>>;
} & Pick<QueryParams, "page" | "perPage">;

export const useCertificates = ({
  profileId,
  customValueIds = [],
  page = 1,
  perPage = 10,
  options = {}
}: UseCertificates) =>
  useQuery({
    queryKey: certificateKeys.list(profileId, perPage, page, customValueIds),
    queryFn: () => fetchCertificates(profileId, page, perPage, customValueIds),
    ...options
  });
