import { compact } from "lodash";
import toNumber from "lodash/toNumber";
import toString from "lodash/toString";
import { Filter } from "PFTypes";

// selected filter ids api returns as strings but options call returns ints
// we need to change the value to int if it is a string
export const numberizeValue = (value) => {
  if (typeof value === "string") {
    const intValue = toNumber(value);
    return isNaN(intValue) ? value : intValue;
  }
  return value;
};

export const stringifyValue = (value) => (typeof value === "number" ? toString(value) : value);

export const createDropdownId = (filter: Filter, searchId?: number) =>
  compact([filter.children ? "children" : null, filter.name, searchId]).join("-");
