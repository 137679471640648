import {
  RoleProfileActionButtonsWrapper,
  RoleProfileActionButtonsWrapperProps
} from "PFApp/components/role_profile_action_buttons";

import css from "./table_cells.module.scss";

export const RoleProfileActionsCell = (props: RoleProfileActionButtonsWrapperProps) => (
  <div className={css.alignRight}>
    <RoleProfileActionButtonsWrapper {...props} small />
  </div>
);
