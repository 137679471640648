import { useAppContext } from "PFApp/app_context";
import { LandingLayoutWithLandingDescription } from "PFApp/components/landing_layout";
import { useSession } from "PFApp/use_session";
import { Button } from "PFComponents/button";
import { Checkbox } from "PFComponents/checkbox";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useSignUp } from "PFCore/hooks/queries/auth/use_sign_up";
import { MODALS_ACTIONS } from "PFReducers/modals_reducer";
import { FeatureFlag } from "PFTypes";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { useGrowl } from "../use_growl";
import css from "./sign_up_page.module.scss";

const SignUpPage = () => {
  const { dispatch } = useAppContext();
  const { data: currentAccount } = useCurrentAccount();

  const history = useHistory();
  const session = useSession();
  const growl = useGrowl();
  const { mutate: signUp, error, isError, isLoading } = useSignUp();
  const { t } = useTranslation();
  const isEnabled = useIsFeatureEnabled();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreed, setAgreed] = useState(false);

  const customization = currentAccount.customization || {};
  const showTerms = !!customization.show_terms_and_conditions;
  const isRequestAccessEnabled = isEnabled(FeatureFlag.RequestAccess);

  const { emailError, passwordError } = useMemo(() => {
    if (!isError) {
      return {};
    }

    const { errors } = error.responseJSON;

    return {
      emailError: Array.isArray(errors.email) ? errors.email[0] : errors.email,
      passwordError: Array.isArray(errors.password) ? errors.password[0] : errors.password
    };
  }, [error, isError]);

  const handleSignupSuccess = (resp) => {
    if (resp.require_confirmation) {
      growl({ message: t("signUpSignIn.willReceiveEmail") });
      return history.push("/");
    }

    return session.signIn(resp.email, password, { reload: false, only_success: true });
  };

  const handleSignUpClick = () => {
    if (showTerms && !agreed) {
      return;
    }

    signUp(
      {
        accountId: currentAccount.id,
        email,
        password,
        passwordConfirmation: password
      },
      {
        onSuccess: handleSignupSuccess
      }
    );
  };

  return (
    <LandingLayoutWithLandingDescription>
      <strong data-qa-id="SignUpPage.header">{t("signUpSignIn.signUp")}</strong>

      <div className={css.signUpForm}>
        <InputFieldSet
          kind="white"
          qaId="SignUpPage.email"
          label={t("emailAddress")}
          inputType="email"
          error={emailError ? t(`signUpSignIn.api.${emailError}`, { defaultValue: emailError }) : ""}
          onChange={setEmail}
        />

        <InputFieldSet
          kind="white"
          qaId="SignUpPage.password"
          label={t("password")}
          inputType="password"
          error={passwordError ? passwordError : ""}
          onChange={setPassword}
          showPasswordToggle
        />

        {showTerms && (
          <div className={css.termsAndConditionsContainer} data-qa-id="SignUpPage.agreeToTerms">
            <Checkbox checked={agreed} onChange={setAgreed} />
            <Trans
              i18nKey="signUpSignIn.agreeToTermsAndConditions"
              t={t}
              components={[
                <button
                  className={css.anchorStyledButton}
                  onClick={() => dispatch({ type: MODALS_ACTIONS.TERMS_SHOW })}
                  key="0"
                />
              ]}
            ></Trans>
          </div>
        )}
      </div>

      <Button
        kind="primary"
        style={{ width: "100%" }}
        onClick={handleSignUpClick}
        qaId="SignUpPage.signUpButton"
        disabled={email.trim() === "" || password.trim() === "" || (showTerms && !agreed)}
      >
        {isLoading ? "..." : t("signUpSignIn.signUp")}
      </Button>

      <div style={{ marginTop: 30, marginBottom: 20 }}>
        {t("signUpSignIn.alreadyHaveAccount")}
        <br />
        <Link to="/" data-qa-id="SignUpPage.logInHereLink">
          {t("signUpSignIn.logInHere")}
        </Link>
      </div>

      {isRequestAccessEnabled && (
        <div style={{ marginBottom: 20 }}>
          {t("signUpSignIn.dontHavePermission")}
          <br />
          <Link to="/request_access" data-qa-id="SignUpPage.requestAccessLink">
            {t("signUpSignIn.requestAccess")}
          </Link>
        </div>
      )}

      <div className={css.gdpr} data-qa-id="SignUpPage.gdpr">
        <strong>{t("signUpSignIn.personalInformationTitle")}</strong>
        <p>{t("signUpSignIn.personalInformationFirst")}</p>

        <p>
          <Trans
            i18nKey="signUpSignIn.personalInformationSecond"
            t={t}
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href="https://www.profinda.com/privacy-policy/" target="_blank" rel="noreferrer" key="0" />
            ]}
          />
        </p>

        <p>
          <Trans
            i18nKey="signUpSignIn.personalInformationThird"
            t={t}
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href="https://www.profinda.com/privacy-policy/#your-rights"
                target="_blank"
                rel="noreferrer"
                key="0"
              ></a>
            ]}
          />
        </p>
      </div>
    </LandingLayoutWithLandingDescription>
  );
};

SignUpPage.propTypes = {};

export default SignUpPage;
