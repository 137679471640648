import { flatten } from "lodash";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { useNotificationsPanelContext } from "./contexts/notifications_panel_context";
import { NotificationItem } from "./notification_item";
import css from "./notifications_panel_list.module.scss";

export const NotificationsPanelList = () => {
  const { t } = useTranslation();

  const {
    notifications: { data: notificationPages, hasNextPage, isLoading, fetchNextPage }
  } = useNotificationsPanelContext();

  const entries = flatten(notificationPages?.pages.map(({ entries }) => entries));
  const anyNotifications = entries.length > 0;

  return (
    <div className={isLoading ? css.isLoading : undefined}>
      {anyNotifications ? (
        entries.map((notification) => <NotificationItem key={notification.id} notification={notification} />)
      ) : (
        <Typography variant="h5" className={css.noNotifications}>
          {isLoading ? <LoadingDots circlesEnabled circleSize="sm" /> : t("notifications.upToDate")}
        </Typography>
      )}
      {hasNextPage && (
        <div className={css.showMore}>
          <Button kind="text" onClick={() => fetchNextPage()}>
            {isLoading ? "..." : t("notifications.showOlder")}
          </Button>
        </div>
      )}
    </div>
  );
};
