import { Tab, Tabs } from "PFComponents/tabs";
import { Id } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ActionIcon } from "../../action_icon";
import { Stack } from "../../containers/flex/stack";
import { Typography } from "../../typography";
import css from "./side_panel_header.module.scss";
import { HEADER_SPACING_OFFSET } from "./side_panel_header.utils";

type SidePanelHeaderProps<TAB_ID> = {
  title?: string | React.ReactNode;
  subTitle?: string;
  actions?: React.ReactNode;
  onClose?: () => void;
  tabsConfig: HeaderTabs<TAB_ID> | undefined;
};

export type HeaderTabs<TAB_ID> = {
  tabs: Tab<TAB_ID>[];
  selectedTabId: TAB_ID;
  onTabClick: (id: TAB_ID) => void;
};

export const SidePanelHeader = <TAB_ID extends Id>({
  title,
  subTitle,
  actions,
  onClose,
  tabsConfig
}: SidePanelHeaderProps<TAB_ID>) => {
  const { t } = useTranslation("core", { keyPrefix: "components.sidePanel" });

  return (
    <div
      className={css.headerContainer}
      style={{ gap: HEADER_SPACING_OFFSET, marginBottom: HEADER_SPACING_OFFSET }}
    >
      <div className={css.header}>
        <Stack gap="spacingSm" className={css.titleContainer}>
          {title &&
            (typeof title === "string" ? (
              <Typography noMargin variant="h4">
                {title}
              </Typography>
            ) : (
              title
            ))}
          {subTitle && (
            <Typography className={css.subTitle} variant="bodyRegular" noMargin clipOverflow>
              {subTitle}
            </Typography>
          )}
        </Stack>
        {(onClose || actions) && (
          <div className={css.actions}>
            {actions}
            {onClose && (
              <ActionIcon
                name="cross"
                title={t("ariaClose")}
                color="paletteBlue0"
                size="sm"
                onClick={onClose}
                classes={{ root: css.close }}
              />
            )}
          </div>
        )}
      </div>
      {tabsConfig && (
        <div className={css.pillTabsContainer}>
          <Tabs
            tabs={tabsConfig.tabs}
            activeTab={tabsConfig.selectedTabId}
            onChange={(newId) => tabsConfig.onTabClick(newId)}
          />
        </div>
      )}
    </div>
  );
};
