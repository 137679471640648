import { identity, noop } from "lodash";
import { createContext, useContext } from "react";

import { MultiRangeSliderProps } from "../multirange_slider";
import { SingleRangeSliderProps } from "../single_range_slider";
import { SliderAttributes } from "../sliders.common";

const INITIAL_SLIDER_CONTEXT: SliderContextType = {
  min: 0,
  max: 100,
  step: 1,
  title: "",
  multiRange: false,
  value: 0,
  sliderId: "slider",
  small: false,
  onChange: noop,
  formatMinMax: identity
};

type SliderContextValue = SliderAttributes &
  (SingleRangeSliderProps | MultiRangeSliderProps) & { sliderId: string };
type SliderContextType = SliderContextValue | null;

const SliderContext = createContext<SliderContextType>(null);

export type SliderContextProviderProps = SliderContextValue;

export const SliderContextProvider = ({
  children,
  ...props
}: React.PropsWithChildren<SliderContextProviderProps>) => (
  <SliderContext.Provider value={{ ...props }}>{children}</SliderContext.Provider>
);

export const useSliderContext = <
  SLIDER_PROPS extends SingleRangeSliderProps | MultiRangeSliderProps
>(): SliderContextValue & SLIDER_PROPS => {
  const sliderContext = useContext<SliderContextType>(SliderContext);

  if (!sliderContext) {
    throw new Error("useSliderContext must be used within a SliderContextProvider");
  }

  return (sliderContext || INITIAL_SLIDER_CONTEXT) as SliderContextValue & SLIDER_PROPS;
};
