import { useQuery } from "@tanstack/react-query";
import { fetchCustomValueInsights } from "PFCore/services/custom_values";
import { CustomValueInsights, Id } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { customValueKeys } from "./query_keys";

export const useCustomValueInsights = (id: Id, options?: QueryOptions<CustomValueInsights>) =>
  useQuery<CustomValueInsights>({
    queryKey: customValueKeys.customValueInsights(id),
    queryFn: () => fetchCustomValueInsights(id),
    retry: false,
    ...options
  });
