import classNames from "classnames";
import colors from "PFTheme/tokens/colors";
import { CustomValue, Experience } from "PFTypes";
import { ReactNode, useEffect, useState } from "react";

import { CIRC_1_CENTER, CIRC_2_CENTER, CIRC_3_CENTER, LINE_1, LINE_2, VIEW_BOX } from "../../constants";
import { Line } from "../line";
import { Point } from "../point";
import css from "./rating_dots.module.scss";

type CSSSize = `${number}px` | `${number}em` | `${number}rem` | `${number}%` | `${number}vh` | `${number}vw`;

export type RatingDotsProps = {
  customValue?: CustomValue;
  experience?: Experience | null;
  size?: CSSSize | number;
  viewBox?: string;
  className?: string;
  onChange?: (experience: Experience) => void;
  color?: string;
  colorSecondary?: string;
  shape?: "circle" | "rect";
  renderTooltip?: (experience: Experience) => ReactNode;
};

export const RatingDots = ({
  experience = null,
  size = "1em",
  viewBox = VIEW_BOX,
  className,
  onChange,
  color = colors.paletteBlue1,
  colorSecondary = colors.paletteNeutral0,
  shape = "circle",
  renderTooltip
}: RatingDotsProps) => {
  const [experienceToShow, setExperienceToShow] = useState<Experience>(experience || Experience.None);
  useEffect(() => {
    setExperienceToShow(experience || 0);
  }, [experience]);

  const isCircle = shape === "circle";

  const handleMouseOut = () => setExperienceToShow(experience || 0);
  const handleMouseOver = (event) => {
    event.stopPropagation();
    setExperienceToShow(event.target.getAttribute("data-level"));
  };

  return (
    <svg
      className={classNames(css.ratingDots, className)}
      data-level={experience}
      height={size}
      focusable="false"
      role="presentation"
      viewBox={viewBox}
      onMouseOut={onChange ? handleMouseOut : undefined}
      onMouseOver={onChange ? handleMouseOver : undefined}
    >
      <Point
        shape={shape}
        cx={CIRC_1_CENTER.x}
        cy={CIRC_1_CENTER.y}
        experience={Experience.Basic}
        onChange={experience !== Experience.Basic ? onChange : undefined}
        color={experienceToShow >= Experience.Basic ? color : colorSecondary}
        tooltipContent={renderTooltip?.(Experience.Basic)}
      />
      {isCircle && <Line experience={Experience.Basic} x1={LINE_1.start} x2={LINE_1.end} color={color} />}
      <Point
        shape={shape}
        cx={CIRC_2_CENTER.x}
        cy={CIRC_2_CENTER.y}
        experience={Experience.Intermediate}
        onChange={experience !== Experience.Intermediate ? onChange : undefined}
        color={experienceToShow >= Experience.Intermediate ? color : colorSecondary}
        tooltipContent={renderTooltip?.(Experience.Intermediate)}
      />
      {isCircle && (
        <Line experience={Experience.Intermediate} x1={LINE_2.start} x2={LINE_2.end} color={color} />
      )}
      <Point
        shape={shape}
        cx={CIRC_3_CENTER.x}
        cy={CIRC_3_CENTER.y}
        experience={Experience.Advanced}
        onChange={experience !== Experience.Advanced ? onChange : undefined}
        color={experienceToShow >= Experience.Advanced ? color : colorSecondary}
        tooltipContent={renderTooltip?.(Experience.Advanced)}
      />
    </svg>
  );
};
