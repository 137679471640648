import Rows from "PFApp/components/table/rows";
import { UTM_SRC_QUERY_PARAM } from "PFApp/workflow/workflow_controller.utils";
import { useInfiniteScroll } from "PFCore/hooks/use_infinite_scroll";

import useInterestedCellRenderer from "./hooks/use_interested_cell_renderer";
import { useInterestedContext } from "./interested_context_provider";
import css from "./interested_rows.module.scss";
const InterestedRows = () => {
  const {
    filteredSortedWatchers,
    watchersQuery,
    selectedColumns: columns,
    selectedProfiles,
    setSelectedProfiles
  } = useInterestedContext();

  const { fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = watchersQuery;
  const renderCell = useInterestedCellRenderer();

  const lastItemRef = useInfiniteScroll<HTMLDivElement>({
    fetchMore: fetchNextPage,
    hasMore: hasNextPage,
    loading: isLoading
  });

  return (
    <Rows
      items={filteredSortedWatchers}
      columns={columns}
      isLoading={isLoading || isFetchingNextPage}
      isSelectMode
      renderCell={renderCell}
      selectedIds={selectedProfiles.map(({ id }) => id)}
      setSelectedItems={setSelectedProfiles}
      classes={{ unselected: css.row }}
      search={UTM_SRC_QUERY_PARAM}
      ref={lastItemRef}
    />
  );
};

export default InterestedRows;
