import classNames from "classnames";
import moment from "moment";
import { useAppContext } from "PFApp/app_context";
import { InlineCalendar } from "PFComponents/calendar/inline_calendar";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import InfoIcon from "PFIcons/info.svg";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { END_ERROR_KEY, START_ERROR_KEY } from "../../constants";
import { getFieldError } from "../../errors_helper";
import css from "./availability_range_calendar.module.scss";

const MONDAY = 1;
const SUNDAY = 7;

export const AvailabilityRangeCalendar = forwardRef((props, ref) => {
  const { t } = useTranslation("core");
  const {
    store: { themeVars }
  } = useAppContext();
  const { mode, minDate, maxDate, matchMaxDate, onChange, errors, wrapped } = props;
  const { formatDate, formatISODate } = useDateFormatter();

  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.end);
  const [hoverEndDay, setHoverEndDay] = useState(null);
  const [hoverStartDay, setHoverStartDay] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(null);

  useImperativeHandle(ref, () => ({
    clear: () => {
      setStart(null);
      setEnd(null);
    }
  }));

  const inlineCalendarStartRef = useRef();
  const inlineCalendarEndRef = useRef();
  const didMount = useRef(false);

  const isAfterMatchMonths = (date) => date && date.isAfter(moment.utc(matchMaxDate));
  useEffect(() => {
    setShowDisclaimer(isAfterMatchMonths(hoverStartDay) || isAfterMatchMonths(hoverEndDay));
  }, [hoverStartDay, hoverEndDay]);

  useEffect(() => {
    if (didMount.current) {
      onChange && onChange(start && formatISODate(getStartForMode()), end && formatISODate(getEndForMode()));
    } else {
      didMount.current = true;
    }
  }, [start, end, mode]);

  const getStartForMode = () => {
    const startTime = moment(start);

    if (mode === "per_week") {
      if (startTime.isoWeekday() === MONDAY) {
        return startTime;
      }

      const startDateForMode = startTime.clone().startOf("week");
      return startDateForMode.isBefore(moment.utc(minDate?.start))
        ? moment.utc(minDate?.start)
        : startDateForMode;
    }

    return startTime;
  };

  const getEndForMode = () => {
    const endTime = moment(end);

    if (mode === "per_week") {
      if (endTime.isoWeekday() === SUNDAY) {
        return endTime;
      }

      const endDateForMode = endTime.clone().endOf("week");
      return endDateForMode.isAfter(moment.utc(maxDate)) ? moment.utc(maxDate) : endDateForMode;
    }

    return endTime;
  };

  const checkHighlighted = (date) => {
    if (!start || !end) {
      return false;
    }

    let effectiveStart = start;
    let effectiveEnd = end;

    if (mode === "per_week") {
      effectiveStart = formatISODate(getStartForMode());
      effectiveEnd = formatISODate(getEndForMode());
    }

    const fmt = formatISODate(date);
    return effectiveStart <= fmt && fmt <= effectiveEnd;
  };

  const checkHoverStart = (date) => {
    if (end && hoverStartDay) {
      const fmt = formatISODate(date);
      return formatISODate(hoverStartDay) <= fmt && fmt <= formatISODate(end);
    }

    return false;
  };

  const checkHoverEnd = (date) => {
    if (start && hoverEndDay) {
      const fmt = formatISODate(date);
      return formatISODate(start) <= fmt && fmt <= formatISODate(hoverEndDay);
    }

    return false;
  };

  const footerMessage = showDisclaimer ? (
    <div>
      <InfoIcon
        width={20}
        height={20}
        style={{ position: "relative", top: 5, marginRight: 3, fill: themeVars["--Palette-warning"] }}
      />
      The availability system will ignore dates selected further than {matchMaxDate}
    </div>
  ) : null;

  return (
    <div className={classNames(css.root, { [css.wrap]: wrapped })}>
      <InlineCalendar
        label={t("timePeriod")}
        placeholder={t("startDate")}
        style={{ width: 160 }}
        ref={inlineCalendarStartRef}
        qaId="calendar-start-date"
        selectedDate={props.start ? formatISODate(props.start) : null}
        displayValues={start ? formatDate(getStartForMode()) : undefined}
        minDate={props?.minDate?.start || formatISODate()}
        maxDate={end ? formatISODate(end) : maxDate}
        handleChange={(value) => {
          setStart(formatISODate(value));
        }}
        error={getFieldError(errors, START_ERROR_KEY)}
        calendarProps={{
          handleMouseEnter: (day) => setHoverStartDay(day),
          handleMouseLeave: () => setHoverStartDay(null),
          checkHighlighted,
          checkHover: checkHoverStart,
          footerMessage,
          matchMaxDate
        }}
        popperOptions={{
          placement: "bottom-start"
        }}
        portalRef={props.portalRef}
      />
      <InlineCalendar
        placeholder={t("endDate")}
        style={{ width: 160 }}
        ref={inlineCalendarEndRef}
        qaId="calendar-end-date"
        selectedDate={props.end ? formatISODate(props.end) : null}
        displayValue={end ? formatDate(getEndForMode()) : undefined}
        minDate={start ? formatISODate(start) : props?.minDate?.end}
        maxDate={maxDate}
        handleChange={(value) => setEnd(value)}
        error={getFieldError(errors, END_ERROR_KEY)}
        calendarProps={{
          handleMouseEnter: (day) => setHoverEndDay(day),
          handleMouseLeave: () => setHoverEndDay(null),
          checkHover: checkHoverEnd,
          checkHighlighted,
          footerMessage,
          matchMaxDate
        }}
        popperOptions={{
          placement: "bottom-start"
        }}
        portalRef={props.portalRef}
      />
    </div>
  );
});

AvailabilityRangeCalendar.displayName = "AvailabilityRangeCalendar";
AvailabilityRangeCalendar.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["within", "per_week", "per_month"]),
  maxDate: PropTypes.string,
  matchMaxDate: PropTypes.string,
  errors: PropTypes.shape({}),
  wrapped: PropTypes.bool,
  minDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }),
  portalRef: PropTypes.object
};
