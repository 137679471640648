import { CORE_SKILLS_LIMIT, DEVELOPMENTAL_SKILLS_LIMIT } from "PFApp/constants/skills_limits";
import { Icon, IconProps } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { ProfileCustomValue } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./skill.module.scss";

type UseSkillActionsOptions = {
  skill: Partial<ProfileCustomValue>;
  addToLog?: (...changes: any[]) => void;
  coreSkillsCount: number;
  developmentalSkillsCount: number;
};

type ActionOptionProps = {
  label: string;
  iconProps: IconProps;
};

const ActionOption = ({ label, iconProps }: ActionOptionProps) => (
  <div className={css.skillAction}>
    <Icon {...iconProps} />
    <Typography variant="bodyRegular" noMargin>
      {label}
    </Typography>
  </div>
);

export const useSkillActionsOptions = ({
  skill,
  addToLog,
  coreSkillsCount,
  developmentalSkillsCount
}: UseSkillActionsOptions) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.skillsModal" });

  const actionOptions = useMemo(
    () => [
      {
        id: "tag_as_core",
        disabled: !skill.experience || (coreSkillsCount >= CORE_SKILLS_LIMIT && !skill.top),
        item: () =>
          skill.experience && addToLog?.({ id: skill.id, data: { top: !skill.top }, type: "ranked" }),
        displayElement: (
          <ActionOption
            iconProps={{ name: "core" }}
            label={skill.top ? t("untagAsCoreSkill") : t("tagAsCoreSkill")}
          />
        )
      },
      {
        id: "tag_as_developmental",
        disabled:
          !skill.experience ||
          (developmentalSkillsCount >= DEVELOPMENTAL_SKILLS_LIMIT && !skill.developmental),
        item: () =>
          skill.experience &&
          addToLog?.({
            id: skill.id,
            data: {
              developmental: !skill.developmental
            },
            type: "ranked"
          }),
        displayElement: (
          <ActionOption
            iconProps={{ name: "development" }}
            label={skill.developmental ? t("untagAsDevelopmentSkill") : t("tagAsDevelopmentSkill")}
          />
        )
      },
      {
        id: "delete",
        item: () =>
          addToLog?.({
            id: skill.id,
            data: { experience: 0, interest: false, top: false, developmental: false },
            type: "delete"
          }),
        displayElement: (
          <ActionOption
            iconProps={{ name: "cross", size: "xs", color: "paletteRed0" }}
            label={t("deleteSkill")}
          />
        )
      }
    ],
    [
      addToLog,
      coreSkillsCount,
      developmentalSkillsCount,
      skill.developmental,
      skill.experience,
      skill.id,
      skill.top,
      t
    ]
  );

  return { actionOptions };
};
