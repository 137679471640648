import { useActivityTemplateType } from "PFApp/activities/hooks";
import { InviteButton } from "PFApp/activities/parts/invite_buttons";
import { useRoleProfileActionButtonsContext } from "PFApp/components/role_profile_action_buttons/context/role_profile_action_buttons_context";
import React from "react";

import css from "./audit_matches_buttons.module.scss";
import { MatchesItemRoleButtons } from "./matches_item_role_buttons";

export const AuditMatchesButtons = () => {
  const { role, profile } = useRoleProfileActionButtonsContext();
  const { isRole } = useActivityTemplateType(role);
  const isDraft = role.state === "draft";
  const showInviteButton = !isDraft && role.coowned && !isRole && role.private;

  return (
    <div className={css.actions}>
      {isRole && <MatchesItemRoleButtons profile={profile} task={role} />}
      {showInviteButton && <InviteButton profile={profile} task={role} />}
    </div>
  );
};
