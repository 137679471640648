import { useAppContext } from "PFApp/app_context";
import { CHAT_ACTIONS } from "PFCore/reducers/chat_reducer";

const useChat = () => {
  const { dispatch } = useAppContext();

  const renderChat = (id, options) => {
    dispatch({ type: CHAT_ACTIONS.CHAT_RENDER, payload: { id, ...options } });
  };

  const unrenderChat = (id, options) => {
    dispatch({ type: CHAT_ACTIONS.CHAT_UNRENDER, payload: { id, ...options } });
  };

  return { renderChat, unrenderChat };
};

export default useChat;
