import { Tab } from "PFComponents/tabs";
import { Engagement, Id } from "PFTypes";
import { Dispatch, HTMLAttributes, SetStateAction } from "react";

import { UseFilteredCollectionReturn } from "../use_filtered_collection";
import { AuditPlannerTabProps } from "./parts/tabs";

export type AudiPlannerTabTheme = HTMLAttributes<HTMLDivElement>["style"];

export type AuditPlannerDataEntity = {
  id: Id;
  activity?: Engagement;
};

export enum AuditPlannerTabId {
  ControlCentre = "control_centre",
  Exceptions = "exceptions",
  ManualInterventions = "manual_interventions"
}

export type AuditPlannerTab = Tab<AuditPlannerTabId> & {
  Component: (params: AuditPlannerTabProps) => JSX.Element;
  data: UseFilteredCollectionReturn<AuditPlannerDataEntity>;
  profiles?: UseFilteredCollectionReturn<AuditPlannerDataEntity>;
  showPlanner?: boolean | undefined;
  setShowPlanner?: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  theme?: AudiPlannerTabTheme;
};

export type Counter = { count: number; style: AudiPlannerTabTheme };

export type Counters = Record<Id, Counter>;
