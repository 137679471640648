import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { Tile } from "PFComponents/tile";

import { useActivityPageContext } from "../../activity_page_context";
import { OwnerFlow } from "./owner_flow";
import { ReviewerFlow } from "./reviewer_flow";

export const ActivityReviewSection = () => {
  const { task, currentProfileIsReviewer } = useActivityPageContext();
  const activityType = useTemplate(task?.template_id);
  const isAuditRole = activityType?.key === TemplateKey.AuditRole;

  if ((!currentProfileIsReviewer && !task.coowned) || isAuditRole) {
    return null;
  }

  return (
    <Tile paddingVariant="lg" light>
      <div>
        {currentProfileIsReviewer && <ReviewerFlow />}
        {!currentProfileIsReviewer && task.coowned && <OwnerFlow />}
      </div>
    </Tile>
  );
};
