/* eslint id-length: 0 */
import classNames from "classnames";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Experience } from "PFTypes";
import { EventKey } from "PFTypes/event_key";
import { KeyboardEventHandler, ReactNode } from "react";

import { RADIUS, RECT_BORDER_RADIUS, RECT_HT, RECT_WT } from "../../constants";
import { RatingDotsProps } from "../rating_dots/rating_dots";
import css from "./point.module.scss";

type PointProps = {
  experience: Experience;
  cx: number;
  cy: number;
  tooltipContent?: ReactNode;
} & Pick<RatingDotsProps, "color" | "onChange" | "shape">;

export const Point = ({
  experience,
  cx,
  cy,
  color,
  shape: Shape = "circle",
  onChange,
  tooltipContent
}: PointProps) => {
  const isCircle = Shape === "circle";
  const handleKeyDown: KeyboardEventHandler<SVGElement> = (event) =>
    event.key === EventKey.Enter && onChange?.(experience);

  const circPositionProps = { cx, cy, r: RADIUS };
  const rectPositionProps = {
    x: cx - RADIUS,
    y: cy - RECT_HT / 2,
    width: RECT_WT,
    height: RECT_HT,
    rx: RECT_BORDER_RADIUS
  };
  const positionProps = isCircle ? circPositionProps : rectPositionProps;

  return (
    <Tooltip theme="pf-dark-blue" disabled={!tooltipContent} content={tooltipContent}>
      <Shape
        className={classNames(css.circle, { [css.editMode]: onChange })}
        role={onChange ? "button" : "presentation"}
        tabIndex={!onChange ? -1 : undefined}
        data-level={experience}
        fill={color}
        onClick={onChange ? () => onChange?.(experience) : undefined}
        onKeyDown={onChange ? handleKeyDown : undefined}
        {...positionProps}
      />
    </Tooltip>
  );
};
