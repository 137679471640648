import { useQueryClient } from "@tanstack/react-query";
import { FetchNotificationsPayload } from "PFCore/services/notifications/fetch_notifications";

import { notificationsKeys } from "./query_keys";

export const useNotificationsInvalidate = () => {
  const cache = useQueryClient();
  return {
    invalidate: (params?: FetchNotificationsPayload) =>
      cache.invalidateQueries(params ? notificationsKeys.list(params) : notificationsKeys.all())
  };
};
