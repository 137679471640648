import { useAppContext } from "PFApp/app_context";
import { notificationsNavItemOpenDispatchOptions } from "PFApp/components/navbar/items/notifications_nav_item";
import { NotificationsPanelContextProvider } from "PFApp/notifications/contexts/notifications_panel_context";
import NotificationsPanelActions from "PFApp/notifications/notifications_panel_actions";
import { NotificationsPanelFilters } from "PFApp/notifications/notifications_panel_filters";
import { NotificationsPanelList } from "PFApp/notifications/notifications_panel_list";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography/typography";
import { Inline } from "PFCore/components/containers/flex/inline";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import css from "./notifications_panel.module.scss";

export const NotificationsPanel = () => {
  const { t } = useTranslation("translation", { keyPrefix: "notifications" });

  const { dispatch } = useAppContext();

  const close = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(notificationsNavItemOpenDispatchOptions({ collapsed: true, userDriven: true }));
  };

  return (
    <NotificationsPanelContextProvider>
      <Inline spread className={css.header}>
        <Typography variant="h4" qaId="LeftActionPanel.title" noMargin>
          {t("actions")}
        </Typography>
        <div className={css.actions}>
          <NotificationsPanelActions />
        </div>
        <ActionIcon size="sm" name="chevron-left" onClick={close} disableHover />
      </Inline>
      <div className={css.filters}>
        <NotificationsPanelFilters />
      </div>
      <div className={css.content}>
        <div className={css.inner}>
          <NotificationsPanelList />
        </div>
      </div>
    </NotificationsPanelContextProvider>
  );
};
