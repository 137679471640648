import React, { ReactNode } from "react";

import { AuditMatchesButtons } from "./audit_matches_buttons";
import { AuditShortlistButtons } from "./audit_shortlist_buttons";

type AuditRoleProfileActionButtonsProps = {
  isShortlistTabActive: boolean;
  customButton?: ReactNode;
};

export const AuditRoleProfileActionButtons = ({
  isShortlistTabActive,
  customButton
}: AuditRoleProfileActionButtonsProps) => {
  if (!isShortlistTabActive) {
    return <AuditMatchesButtons />;
  }
  return <AuditShortlistButtons customButton={customButton} />;
};
