import { useAppContext } from "PFApp/app_context";
import { useNotifications } from "PFCore/hooks/queries/notifications/use_notifications";
import NotificationsIcon from "PFIcons/bell.svg";
import { APP_LAYOUT_ACTIONS } from "PFReducers/app_layout_reducer";
import { useTranslation } from "react-i18next";

import { CommonNavItemProps, NavItem } from "../parts/nav_item";

export const NOTIFICATIONS_PANEL_COLLAPSED = "notifications-collapsed";

export const NotificationsNavItem = (props: CommonNavItemProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  const {
    dispatch,
    store: {
      appLayout: { leftActionPanel }
    }
  } = useAppContext();

  const { data: notifications } = useNotifications({
    perPage: 0,
    excludedTargetTypes: ["Chat::Conversation"]
  });

  const totalUnread = notifications?.meta.totalUnread || 0;

  const isOpen = !leftActionPanel.collapsed && leftActionPanel.id === "notifications_panel";

  return (
    <NavItem
      icon={<NotificationsIcon width={20} height={20} />}
      name={t("notifications")}
      id="notifications"
      onClick={() => {
        if (isOpen) {
          dispatch(notificationsNavItemOpenDispatchOptions({ collapsed: true, userDriven: true }));
        } else {
          dispatch(notificationsNavItemOpenDispatchOptions({ collapsed: false, userDriven: true }));
        }
      }}
      forceOpen={isOpen}
      counter={totalUnread}
      {...props}
    />
  );
};

export const notificationsNavItemOpenDispatchOptions = ({ collapsed, userDriven = false }) => {
  if (userDriven) {
    storage.setItem(NOTIFICATIONS_PANEL_COLLAPSED, collapsed);
  }

  return {
    type: APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_LEFT_ACTIONS_PANEL,
    payload: { id: "notifications_panel", collapsed: !!collapsed }
  };
};
