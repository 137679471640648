import { useAppContext } from "PFApp/app_context";
import { LoadingDots } from "PFComponents/loading_dots";
import { sanitizeActivityToClone } from "PFCore/helpers/activities";
import { GROWL_ACTIONS } from "PFCore/reducers/growl_reducer";
import { fetchActivity } from "PFCore/services/activities";
import { Activity, Template } from "PFTypes";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { ActivityEditPage } from "./activity_edit_page";

type ActivityEditControllerProps = {
  pageType: "clone" | "new" | "edit";
};

type ActivityEditPageParams = {
  id?: string;
  templateType?: Template["key"];
  parentId?: string;
};

const ActivityEditController = ({ pageType }: ActivityEditControllerProps) => {
  const history = useHistory();
  const location = useLocation();
  const fromSearch = Boolean(new URLSearchParams(location.search).get("fromSearch"));
  const { id, templateType, parentId } = useParams<ActivityEditPageParams>();
  const [activityClone, setActivityClone] = useState<Partial<Activity> | null>(null);
  const [modelErrored, setModelErrored] = useState(false);
  const { dispatch } = useAppContext();
  const { t } = useTranslation("activities");

  useEffect(() => {
    if (pageType === "clone" && id) {
      fetchActivity(id)
        .then((activity) => {
          setActivityClone(sanitizeActivityToClone(activity, fromSearch));
        })
        .catch(() => {
          if (!modelErrored) {
            dispatch({
              type: GROWL_ACTIONS.GROWL_RENDER,
              payload: {
                message: t("edit.errorOccuredTryAgainLater"),
                kind: "error"
              }
            });
            history.push("/");
          }
          setModelErrored(true);
        });
    }
  }, []);

  switch (pageType) {
    case "new": {
      return (
        <ActivityEditPage
          type={templateType}
          attributes={{ parent_activity_id: parentId ? Number(parentId) : null }}
        />
      );
    }
    case "edit": {
      return <ActivityEditPage id={id} />;
    }
    case "clone": {
      if (activityClone) {
        return <ActivityEditPage type={templateType} attributes={activityClone} isClone />;
      } else {
        return <LoadingDots circlesEnabled pageViewEnabled />;
      }
    }
  }
};

ActivityEditController.propTypes = {
  pageType: PropTypes.string
};

export default ActivityEditController;
