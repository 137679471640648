import { useTemplate } from "PFApp/hooks";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { useSidePanelClose } from "PFComponents/side_panel/use_side_panel_close";
import { isRankable } from "PFCore/helpers/custom_type";
import { activityCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { useActivity, useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useCustomValue } from "PFCore/hooks/queries/custom_fields/use_custom_value";
import { useCustomValueActivityConnection } from "PFCore/hooks/queries/custom_fields/use_custom_value_activity_connection";
import { useCustomValueInsights } from "PFCore/hooks/queries/custom_fields/use_custom_value_insights";
import { useCustomValuesCreate } from "PFCore/hooks/queries/custom_values/use_custom_values_create";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useFirstLoad } from "PFCore/hooks/use_first_load";
import { AccessLevel, Experience, Importance } from "PFTypes";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useCustomValuePreviewContext } from "../context/custom_value_preview_context";
import css from "../custom_value_preview.module.scss";
import { CustomValueSelect } from "../parts/custom_value_select";
import { ActivityCustomValueFooter } from "../parts/footer/activity_custom_value_footer";
import { ActivityCustomValueContent } from "./parts/activity_custom_value_content";

export const ActivityCustomValuePreview = () => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const { t: tActivities } = useTranslation("activities");
  const { pathname } = useLocation();
  const isFirstLoad = useFirstLoad();

  const { data: currentProfile } = useCurrentProfile();
  const { unmountPreview } = useCustomValuePreviewActions();
  const {
    show,
    allowSearch,
    customType,
    activityId,
    canEdit,
    isNew,
    isClosing: isClosingPreviewState,
    customValueId,
    setSelectedCustomValueId
  } = useCustomValuePreviewContext();

  // TODO: [SP-2573] Consider moving it to Sidepanel component
  const { isClosing, onSidePanelClose: closeWithAnimation } = useSidePanelClose({
    isSidePanelClosing: isClosingPreviewState,
    onClose: unmountPreview
  });

  useEffect(() => {
    // Close sidepanel when page is changed
    if (isFirstLoad) {
      return;
    }

    closeWithAnimation();
  }, [pathname, closeWithAnimation]);

  const { data: activity, isFetching: isFetchingActivity } = useActivity(activityId!, {
    enabled: show && !isClosing
  });

  const { data: customValue, isFetching: isFetchingCustomValue } = useCustomValue(customValueId!, {
    enabled: Boolean(show && customValueId && !isClosing)
  });

  const connectionQueryEnabled = Boolean(show && customValueId && !isNew && !isClosing);

  const { data: activityConnectionData, isFetching: isFetchingActivityConnection } =
    useCustomValueActivityConnection(customValueId!, activityId!, {
      enabled: connectionQueryEnabled
    });

  const activityConnection = connectionQueryEnabled ? activityConnectionData : undefined;

  const { data: insights, isFetching: isFetchingInsights } = useCustomValueInsights(customValueId!, {
    enabled: Boolean(show && customValueId && !isClosing)
  });

  const { mutateAsync: createCustomValue, isLoading: isCreatingCustomValue } = useCustomValuesCreate();
  const { update: updateActivity, isUpdating } = useActivityUpdate(activity?.id);

  const template = useTemplate(activity?.template_id);

  const [importance, setImportance] = useState<Importance | null>(null);
  const [requiredExperience, setRequiredExperience] = useState<Experience | null>(null);

  useEffect(() => {
    setImportance(activityConnection?.importance ?? null);
    setRequiredExperience(activityConnection?.requiredExperience ?? null);
  }, [activityConnection]);

  const onResetForm = useCallback(() => {
    setSelectedCustomValueId(null);
    setImportance(null);
    setRequiredExperience(null);
  }, [setSelectedCustomValueId]);

  useEffect(() => {
    if (allowSearch) {
      onResetForm();
    }
  }, [allowSearch, onResetForm]);

  const rankable = isRankable(customType);
  const isLoading =
    isFetchingActivity ||
    isFetchingCustomValue ||
    isFetchingActivityConnection ||
    isFetchingInsights ||
    isCreatingCustomValue;
  const isAllDataFetched = !!activity && !!customValue && (isNew || !!activityConnection) && !!insights;

  const title = isNew && !customValue ? t("addCustomValue") : customValue?.text;
  const showSubtitle = !!title && (!isNew || !!customValue);
  const showCustomValueSelect = !isLoading && isNew && !customValue && allowSearch;
  const showContent = !isLoading && isAllDataFetched;
  const allowEdit =
    !!canEdit && activityCustomTypesAccessLevels(currentProfile)[customType.id] === AccessLevel.ReadWrite;
  const isFormFilled = importance !== null && (!rankable || requiredExperience !== null);
  const templateName = template
    ? tActivities(`templates.${template?.key}` as unknown as TemplateStringsArray)
    : null;

  const renderFooter = useCallback(
    ({ onSidePanelClose }) => (
      <ActivityCustomValueFooter
        activity={activity}
        customValue={customValue}
        importance={importance}
        requiredExperience={requiredExperience}
        allowEdit={allowEdit}
        isRemoveDisabled={isLoading}
        isSaveDisabled={isLoading || !isAllDataFetched || !isFormFilled}
        isSaveTooltipVisible={isAllDataFetched && !isFormFilled}
        templateName={templateName}
        updateActivity={updateActivity}
        onClose={onSidePanelClose}
        closeWithAnimation={closeWithAnimation}
      />
    ),
    [
      activity,
      allowEdit,
      closeWithAnimation,
      customValue,
      isAllDataFetched,
      importance,
      isFormFilled,
      isLoading,
      requiredExperience,
      templateName,
      updateActivity
    ]
  );

  return (
    <SidePanel
      show={show}
      title={title}
      subTitle={showSubtitle ? customType?.display_as : undefined}
      isSidePanelClosing={isClosing}
      footerRenderer={renderFooter}
      onClose={unmountPreview}
    >
      {!!isUpdating && (
        <div className={css.savingOverlay}>
          <LoadingDots circlesEnabled circleSize="xl" />
        </div>
      )}
      <div className={css.sidePanelRoot}>
        {isLoading && <LoadingDots centered circlesEnabled circleSize="sm" />}
        {showCustomValueSelect && (
          <CustomValueSelect customType={customType} createCustomValue={createCustomValue} />
        )}
        {showContent && (
          <ActivityCustomValueContent
            importance={importance}
            requiredExperience={requiredExperience}
            allowEdit={allowEdit}
            customValue={customValue}
            insights={insights}
            templateName={templateName}
            onRequiredExperienceChange={setRequiredExperience}
            onImportanceChange={setImportance}
            onResetForm={onResetForm}
          />
        )}
      </div>
    </SidePanel>
  );
};
