import { compact, flatten, map } from "lodash";
import { CustomField, CustomType, ParsedSchemaProperty } from "PFTypes";

type FieldType = {
  id?: number;
  name: string;
};

const findCustomType = (item, customTypes: ParsedSchemaProperty[] | CustomType[]): FieldType | null => {
  const type = (customTypes as FieldType[]).find(
    ({ id, name }) =>
      name === item.type ||
      (item.type && id === item.type.id) ||
      (item.type && name === item.type.name) ||
      false
  );

  if (!type) {
    return null;
  }

  return {
    id: type.id,
    name: type.name
  };
};

export const normalize = (
  item,
  customTypes: CustomType[],
  matchCustomTypes: ParsedSchemaProperty[],
  filterCustomTypes: ParsedSchemaProperty[]
) => {
  const customType = findCustomType(item, customTypes);

  if (!customType) {
    return null;
  }

  return {
    ...item,
    type: customType,
    match: !!findCustomType(item, matchCustomTypes),
    filterKey: findCustomType(item, filterCustomTypes)?.name
  };
};

export const normalizeList = (
  customFields: CustomField[],
  customTypes: CustomType[],
  matchCustomTypes: ParsedSchemaProperty[],
  filterCustomTypes: ParsedSchemaProperty[]
) => {
  const items = flatten(
    customFields.map(({ values, type }) => (values || []).map((val) => ({ ...val, type })))
  );

  return compact(map(items, (item) => normalize(item, customTypes, matchCustomTypes, filterCustomTypes)));
};

export const isFilter = (item) => !!item.filterKey;
export const isMatch = (item) => item.match;
