import api from "../../api";

export type MarkNotificationAsReadPayload = {
  id: number;
  status?: "read" | "unread";
};

export const markNotificationAsRead = ({ id, status = "read" }: MarkNotificationAsReadPayload) =>
  api({
    url: `notifications/${id}`,
    method: "PUT",
    params: {
      status
    }
  });
