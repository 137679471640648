import Color from "color";
import { isEmpty, max } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Accordion } from "PFComponents/accordion";
import { Inline } from "PFComponents/containers/flex/inline";
import { Stack } from "PFComponents/containers/flex/stack";
import { Icon } from "PFComponents/icon";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import { Typography } from "PFComponents/typography";
import EchartsWrapper, { ECOption } from "PFCore/helpers/echarts_wrapper";
import colors from "PFTheme/tokens/colors";
import spacing from "PFTheme/tokens/spacing";
import { DateWithRangesValue } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./matches_chart.module.scss";
import { AXIS_LABEL_MARGIN, matchesChartConfig } from "./matches_chart_config";
import { MatchesChartXAxisTitle } from "./matches_chart_x_axis_title";
import { MatchesChartYAxisTitle } from "./matches_chart_y_axis_title";

const LABEL_DIGIT_WIDTH = 6;
const Y_AXIS_TITLE_WIDTH = 12;
const AXIS_TITLE_SPACING_KEY = "spacingSm";

export const MatchesChart = () => {
  const {
    matchesMeta: { total, distribution, filters },
    matchesFetchState
  } = useActivityPageContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.chart" });

  const isLoading = !matchesFetchState || matchesFetchState === "pending";
  const config = useMemo(
    () =>
      matchesChartConfig(
        !isEmpty((filters?.availability?.value as unknown as DateWithRangesValue)?.ranges),
        distribution
      ),
    [filters?.availability, distribution]
  );

  const renderHeader = () => (
    <Inline spread alignItems="center">
      {t("title")}
      <div className={css.iconWithText}>
        <Icon name="profile" />
        <Typography variant="bodyRegular" noMargin>
          {distribution?.counters.matched.total || total || 0}
        </Typography>
        <TooltipIcon content={t("matchesChartTooltip")} IconComponent={() => <Icon name="info" />} />
      </div>
    </Inline>
  );

  const maxYValue = max((config as ECOption)?.series?.[0]?.data || []);
  const yValueMaxDigits = String(maxYValue).length;

  return (
    <Accordion
      header={renderHeader()}
      headerFullWidth
      defaultState="closed"
      headerVariant="bodyBold"
      iconSet="chevron"
    >
      {isEmpty(distribution) || !config ? (
        <div>
          <Typography variant="bodyRegular">{t("noDataWarning")}</Typography>
        </div>
      ) : (
        <Stack gap={AXIS_TITLE_SPACING_KEY} className={css.chartWrapper}>
          <Inline gap={AXIS_TITLE_SPACING_KEY}>
            <MatchesChartXAxisTitle style={{ width: Y_AXIS_TITLE_WIDTH }} />
            <EchartsWrapper
              loading={isLoading}
              options={config}
              height={164}
              width={264 - Y_AXIS_TITLE_WIDTH}
              echartsLoadingOptions={{
                maskColor: Color(colors.paletteNeutral1).alpha(0.5).toString()
              }}
            />
          </Inline>
          <MatchesChartYAxisTitle
            // real digits count may be greater by 1, so we add 0.5 to average the value
            style={{
              marginLeft: `calc(
                ${Y_AXIS_TITLE_WIDTH}px +
                ${spacing[AXIS_TITLE_SPACING_KEY]} +
                (${yValueMaxDigits} + 0.5) * ${LABEL_DIGIT_WIDTH}px +
                ${AXIS_LABEL_MARGIN}px
              )`
            }}
          />
        </Stack>
      )}
    </Accordion>
  );
};
