import { ElementType, forwardRef, Ref } from "react";

import { Flex, FlexProps } from "./flex";

type InlineProps<T extends ElementType = "div"> = FlexProps<T> & {
  direction?: never;
  reverse?: boolean;
  spread?: boolean;
};

export const Inline = forwardRef(
  <T extends ElementType = "div">({ reverse, spread, ...props }: InlineProps<T>, ref: Ref<any>) => (
    <Flex
      ref={ref}
      direction={reverse ? "row-reverse" : "row"}
      justifyContent={spread ? "space-between" : undefined}
      {...props}
    />
  )
);

Inline.displayName = "Inline";
