import { Typography } from "PFComponents/typography";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { getContrastingTextColor } from "PFCore/utilities/color";
import { Booking } from "PFTypes";

import BookingPill from "../../../../components/pills/booking_pill";
import css from "./booking_category_column.module.scss";

const COLLAPSED_MODE_WIDTH = 60;
const EXPANDED_MODE_WIDTH = 120;

type BookingCategoryColumnProps = {
  booking: Booking;
  isExpanded: boolean;
};

export const BookingCategoryColumn = ({ booking, isExpanded }: BookingCategoryColumnProps): JSX.Element => {
  const { getCategory } = useBookingCategories();
  const category = getCategory(booking);

  const textColor = category?.color ? getContrastingTextColor(category?.color, 0.7) : undefined;

  return (
    <BookingPill
      title={booking.title}
      category={category}
      className={css.root}
      style={{
        color: textColor,
        maxWidth: isExpanded ? EXPANDED_MODE_WIDTH : COLLAPSED_MODE_WIDTH,
        width: "80%",
        borderWidth: 1
      }}
    >
      <Typography variant="labelRegular">{category?.display_as}</Typography>
    </BookingPill>
  );
};
