import orderBy from "lodash/orderBy";
import moment from "moment";
import { getProfileName } from "PFCore/helpers/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import {
  Activity,
  AuditPlannerException,
  AuditPlannerExceptionsDictionary,
  AuditRole,
  BasicProfile
} from "PFTypes";
import { useTranslation } from "react-i18next";
import { humanize, titleize } from "underscore.string";

export const workflowStatesByTabs = {
  exceptions: ["exception"],
  manual_interventions: ["shortlisting"]
};

export const getExceptionType = (exception: AuditPlannerExceptionsDictionary) => {
  const isMultiple = exception.availability && exception.compliance;
  return isMultiple
    ? "Multiple"
    : Object.keys(Object.values(exception)[0] || {})
        .map((type) => titleize(humanize(type)))
        .join(", ");
};

export const exceptionsArrayToException = (auditPlannerExceptions: AuditPlannerException[]) => {
  const exceptionsArray = auditPlannerExceptions.map(({ exceptionType, exceptions }) => ({
    [exceptionType]: exceptions
  }));
  const exceptions = orderBy(auditPlannerExceptions, ["id"], ["desc"]); // sort newest first to search for requester, approver and reason in the proper order

  const requester =
    exceptions.find(({ requester }) => requester)?.requester ||
    ((exceptions.find(({ requesterId }) => requesterId) && {
      id: exceptions.find(({ requesterId }) => requesterId)?.requesterId
    }) as BasicProfile);

  const approver =
    exceptions.find(({ approver }) => approver)?.approver ||
    ((exceptions.find(({ approverId }) => approverId) && {
      id: exceptions.find(({ approverId }) => approverId)?.approverId
    }) as BasicProfile);

  return {
    exceptions: exceptionsArray.reduce((acc, curr) => ({ ...curr, ...acc }), {}),
    requester,
    approver,
    requestReason: exceptions.find(({ requestReason }) => requestReason)?.requestReason
  };
};

export const getExceptionFromRole = (role: AuditRole) => {
  const exceptions = role.shortlists?.find(({ state }) => ["filled", "booked"].includes(state))?.exceptions;
  return exceptions && exceptions.length > 0 ? exceptionsArrayToException(exceptions) : null;
};

export const mapWorkflowStatesToUI = (status: AuditRole["workflowState"]) => {
  if (status === "shortlisting") {
    return "not_filled";
  }

  if (["auto_shortlisting", "auto_filling"].includes(status)) {
    return "pending";
  }

  return status;
};

export const isAuditRoleDisabled = (role: AuditRole | Activity) =>
  ["pending", "auto_filling", "auto_shortlisting"].includes(
    (role as AuditRole).workflowState || (role as Activity).workflow_state
  );

export const isOverbooked = (role: AuditRole) => {
  const profile =
    role.shortlists && role.shortlists.find(({ state }) => ["filled", "booked"].includes(state))?.profile;
  const bookings = profile?.availability?.bookings?.filter(({ activityId }) => activityId === role.id) || [];

  return bookings.some(({ availableMinutes }) => (availableMinutes || 0) < 0);
};

export const useOverbookingMessage = (role: AuditRole) => {
  const { formatDate } = useDateFormatter();
  const { t } = useTranslation("auditPlanner");

  const profile =
    role.shortlists && role.shortlists.find(({ state }) => ["filled", "booked"].includes(state))?.profile;

  const bookings = profile?.availability?.bookings.filter(
    ({ activityId, availableMinutes }) => activityId === role.id && (availableMinutes || 0) < 0
  );

  if (!profile || !(bookings?.length === 0)) {
    return null;
  }

  const availableMinutes = bookings.reduce(
    (acc, curr) => ((curr.availableMinutes || 0) < 0 ? acc + (curr.availableMinutes || 0) : acc),
    0
  );

  const startDate = bookings
    .slice(1)
    .reduce(
      (acc, curr) => (moment(curr.startDate).isBefore(acc) ? curr.startDate : acc),
      bookings[0].startDate
    );

  const endDate = bookings
    .slice(1)
    .reduce((acc, curr) => (moment(curr.endDate).isAfter(acc) ? curr.endDate : acc), bookings[0].endDate);

  return t("parts.userIsOverbooked", {
    name: getProfileName(profile),
    hours: -availableMinutes / 60,
    startDate: formatDate(moment(startDate).utc()),
    endDate: formatDate(moment(endDate).utc())
  });
};
