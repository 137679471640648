import uniqueId from "lodash/uniqueId";
import { FocusEvent, useCallback, useEffect, useRef, useState } from "react";

import { useActionIcons } from "./action_icons";
import { Dropdown, useDropdown } from "./dropdown";
import { Header } from "./header";
import { Popover } from "./popover";
import css from "./select_v2.module.scss";
import { OptionOriginal, SearchableVariant, SelectV2Props } from "./select_v2.types";
import { getHighlightedOptionId } from "./select_v2.utils";
import { ValueBox } from "./value_box";
import { getKeyDownHandler } from "./value_box/get_key_down_handler";
import { ValueContainer } from "./value_box/value_container";

export const SelectV2 = <T extends OptionOriginal = OptionOriginal>({
  label,
  labelTooltip,
  value,
  onRestore,
  onClear,
  onChange,
  onCreateOption,
  onDropdownClose,
  options,
  fetchOptions,
  multiple,
  searchableVariant = SearchableVariant.Dynamic,
  renderDisplayValue,
  cacheTime = 300000,
  disabled,
  locked,
  required,
  displayValueBelow,
  dropdownId: externalDropdownId
}: SelectV2Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const valueBoxRef = useRef<HTMLDivElement>(null);
  const localDropdownIdRef = useRef<string>(uniqueId("select-dropdown"));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const actionIcons = useActionIcons({ onRestore, onClear, label, locked, disabled });

  const dropdownId = externalDropdownId ?? localDropdownIdRef.current;

  const handleOpen = useCallback(
    (value: boolean) => {
      setIsOpen(value);
      value === false && onDropdownClose?.();
    },
    [onDropdownClose]
  );

  const dropdownData = useDropdown({
    dropdownId,
    isOpen,
    setIsOpen: handleOpen,
    value,
    triggerRef: valueBoxRef,
    options,
    fetchOptions,
    multiple,
    onChange,
    onCreateOption,
    searchableVariant,
    cacheTime
  });

  useEffect(() => {
    if (isOpen && disabled) {
      handleOpen(false);
    }
  }, [disabled, handleOpen, isOpen]);

  const { highlightedIndex, resultOptions, selectedOptions, isFetchingSelectedOptions, handleKeyDown } =
    dropdownData;

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    const { currentTarget, relatedTarget } = event;
    if (
      relatedTarget &&
      (currentTarget.contains(relatedTarget) || (ref.current && ref.current.contains(relatedTarget)))
    ) {
      if (relatedTarget.getAttribute("role") === "option") {
        currentTarget.focus({ preventScroll: true });
      }
      return;
    }
    handleOpen(false);
  };

  const { onKeyDown: handleDropdownOpenStateOnKeyDown } = getKeyDownHandler({ setIsOpen: handleOpen });
  const onValueBoxKeyDown = isOpen ? handleKeyDown : handleDropdownOpenStateOnKeyDown;

  const handleValueBoxClick = () => {
    valueBoxRef.current?.focus();
    handleOpen(!isOpen);
  };

  return (
    <div ref={ref} className={css.root}>
      <div className={css.container}>
        <Header label={label} labelTooltip={labelTooltip} actionIcons={actionIcons} required={required} />
        <ValueBox<T>
          ref={valueBoxRef}
          selectedOptions={selectedOptions}
          isFetching={isFetchingSelectedOptions}
          label={label}
          multiple={multiple}
          renderDisplayValue={renderDisplayValue}
          isOpen={isOpen}
          onKeyDown={onValueBoxKeyDown}
          onClick={handleValueBoxClick}
          onChange={onChange}
          onBlur={handleBlur}
          actionIcons={!label ? actionIcons : undefined}
          dropdownId={dropdownId}
          highlightedId={getHighlightedOptionId(highlightedIndex, resultOptions, dropdownId)}
          locked={locked}
          disabled={disabled}
          displayValueBelow={displayValueBelow}
        />
      </div>
      {displayValueBelow && (
        <ValueContainer
          onChange={onChange}
          renderDisplayValue={renderDisplayValue}
          multiple={multiple}
          selectedOptions={selectedOptions}
          className={css.valueContainer}
          isFetching={isFetchingSelectedOptions}
          locked={locked}
          disabled={disabled}
        />
      )}
      {isOpen && !disabled && !locked && (
        <Popover triggerUpdate={String(value)} reference={ref} onClickOutside={() => handleOpen(false)}>
          <Dropdown<T>
            style={{ width: ref.current?.offsetWidth }}
            dropdownId={dropdownId}
            value={value}
            multiple={multiple}
            onBlur={handleBlur}
            dropdownData={dropdownData}
          />
        </Popover>
      )}
    </div>
  );
};
