import { Id } from "./id";

export enum CustomValueScope {
  Unscoped,
  Profile,
  Activity
}

type CustomValuePreviewBase = {
  show: boolean;
  isClosing: boolean;
  customTypeId: Id;
  canEdit?: boolean;
  canRemove?: boolean;
};

type CustomValueParamsConditional =
  | {
      isNew?: false;
      allowSearch?: false;
      customValueId: Id;
      customValuesIdsBlacklist?: never;
    }
  | {
      isNew: true;
      allowSearch: true;
      customValuesIdsBlacklist?: Id[];
      customValueId?: never;
    }
  | {
      isNew: true;
      allowSearch?: false;
      customValuesIdsBlacklist?: never;
      customValueId: Id;
    };

type ScopeParamsConditional =
  | {
      scope: CustomValueScope.Activity;
      activityId: Id;
      profileId?: never;
      isBlindModeOn?: never;
    }
  | {
      scope: CustomValueScope.Profile;
      profileId: Id;
      activityId?: never;
      isBlindModeOn?: boolean;
    };

export type CustomValuePreview = CustomValuePreviewBase &
  CustomValueParamsConditional &
  ScopeParamsConditional;
