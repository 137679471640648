import { useState } from "react";
import { useDebounce } from "react-use";

const DEBOUNCE_TIME = 800;

export const useDebouncedHours = (hours: number[], onChange, isFilter) => {
  const debounceTime = isFilter ? DEBOUNCE_TIME : 0;
  const [internalHours, setInternalHours] = useState<number[]>(hours);

  const handleHoursChange = (value: string, index: number) => {
    const valueInMinutes = Math.round((+value.replace(",", ".") || 0) * 60);
    const newDayOfWeek = [...hours];
    newDayOfWeek[index] = Math.max(0, Math.min(valueInMinutes, 1440));
    setInternalHours(newDayOfWeek);
  };

  useDebounce(() => onChange({ day_of_week: internalHours }), debounceTime, [internalHours]);

  return [internalHours, handleHoursChange] as const;
};
