import { useGrowl } from "PFApp/use_growl";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import css from "./mark_read_button.module.scss";

const MarkReadButton = ({ rowRef, onClick }) => {
  const { t } = useTranslation("workflow");
  const markReadRef = useRef(null);

  const growl = useGrowl();
  const ttl = 7000;

  const handleUndo = () => {
    rowRef.current.style.display = "flex";
    clearTimeout(markReadRef.current);
  };

  const handleClick = () => {
    rowRef.current.style.display = "none";
    markReadRef.current = setTimeout(onClick, ttl);

    growl({
      message: (
        <div className={css.growl}>
          {t("parts.markedAsRead")}
          <Button onClick={handleUndo}>{t("translation:undo")}</Button>
        </div>
      ),
      kind: "success",
      ttl
    });
  };

  return (
    <div className={css.container}>
      <ActionIcon name="unseen" color="palettePrimary2" onClick={handleClick} />
    </div>
  );
};

MarkReadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  rowRef: PropTypes.object.isRequired
};

export default MarkReadButton;
