import { useActivityTemplateType } from "PFApp/activities/hooks";
import { useDetailsPanelApiContext } from "PFApp/booking/components/details_panel";
import { Button } from "PFComponents/button";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useIsExternalUsage } from "PFCore/hooks/queries/bookings/use_is_external_usage";
import { Activity, Booking, PermissionRule } from "PFTypes";

type ReassignButtonProps = {
  booking: Booking;
  activity: Activity | undefined;
  isEngagement: boolean;
  title?: string;
};

export const ReassignButton = ({ booking, activity, isEngagement, title }: ReassignButtonProps) => {
  const { openDetailsPanel } = useDetailsPanelApiContext();
  const isExternal = useIsExternalUsage();

  const canReassignBookingsMatches = useIsCurrentUserPermittedTo(PermissionRule.ReassignBookingsMatches);
  const canReassignBookingsNamedResource = useIsCurrentUserPermittedTo(
    PermissionRule.ReassignBookingsNamedResource
  );
  const { isAudit, isRole } = useActivityTemplateType(activity);

  const canReassign = canReassignBookingsMatches || canReassignBookingsNamedResource;
  const isAuditRoleRelated = isAudit && isRole;
  const isNotPermitted =
    !canReassign || isAuditRoleRelated || (isEngagement && !canReassignBookingsNamedResource); // reassigning engagement bookings requires ReassignBookingsNamedResource permission as engagements don't have matches

  if (!activity || isNotPermitted || isExternal) {
    return null;
  }

  return (
    <Button
      title={title}
      icon="reassign"
      onClick={() => {
        openDetailsPanel(
          {
            type: "reassign",
            id: booking.id,
            reassignDetails: {
              activityName: booking.activity?.name,
              activity,
              sourceProfileId: booking.profile_id,
              booking,
              isEngagement
            }
          },
          true
        );
      }}
      kind="secondary"
    />
  );
};
