import { useAppContext } from "PFApp/app_context";
import { NotificationsPanel } from "PFApp/notifications/notifications_panel";
import { ReactNode } from "react";

import css from "./left_action_panel.module.scss";

const LeftActionPanel = () => {
  const {
    store: {
      appLayout: { leftActionPanel }
    }
  } = useAppContext();

  const content = getContent(leftActionPanel.id);

  return (
    <div className={leftActionPanel.collapsed ? css.collapsed : undefined} id="left_action_panel">
      <div className={css.spacer}>{/* spacer is so we can simply use this in a display: flex wrap */}</div>
      <div className={css.root}>{content}</div>
    </div>
  );
};

const getContent = (id: string): ReactNode => {
  /* eslint-disable react/jsx-key */
  switch (id) {
    case "notifications_panel":
      return <NotificationsPanel />;
    default:
      return null;
  }
};

export default LeftActionPanel;
