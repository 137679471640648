import { useActivityPermissions } from "PFApp/activities/hooks";
import ButtonGroup from "PFComponents/button_group/button_group";
import { isProfileActive } from "PFCore/helpers/profile";
import { Activity, Shortlist } from "PFTypes";

import { AcceptDeclineButton } from "../../../parts/shortlist_buttons";
import FillAuditRoleButton from "../../parts/fill_audit_role_button";

type PendingButtonsProps = {
  isAudit: boolean;
  task: Activity;
  qaPrefix?: string;
  parentActivity: Activity;
  item: Shortlist;
};

const PendingButtons = ({ isAudit, item, task, parentActivity }: PendingButtonsProps) => {
  const { canReviewShortlists } = useActivityPermissions(task);

  if (!canReviewShortlists || !isProfileActive(item.profile)) {
    return null;
  }

  return isAudit ? (
    <FillAuditRoleButton shortlistItem={item} engagement={parentActivity} role={task} />
  ) : (
    <ButtonGroup style={{ display: "flex" }}>
      <AcceptDeclineButton
        kind="secondary"
        action="accept"
        activity={task}
        shortlist={item}
        qaPrefix="shortlist"
      />
      <AcceptDeclineButton
        kind="danger"
        action="decline"
        activity={task}
        shortlist={item}
        qaPrefix="shortlist"
      />
    </ButtonGroup>
  );
};

export default PendingButtons;
