import { isNumber } from "lodash";
import { getGrowlId, useGrowl } from "PFApp/use_growl";
import { LegacyModal } from "PFComponents/modal/legacy_modal";
import { Button } from "PFCore/components/button";
import { getProfileName } from "PFCore/helpers/profile";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { fillAndBookShortlist } from "PFCore/services/shortlist";
import { Match } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./modals.module.scss";
import { FillRoleContent } from "./parts/fill_role_content";
import { AuditPlannerModalProps } from "./types";

type FillRoleModalProps = AuditPlannerModalProps & {
  match: Match;
};

export const FillRoleModal = ({
  close,
  engagement,
  role,
  match,
  shortlistItem,
  onSuccess
}: FillRoleModalProps) => {
  const growl = useGrowl();
  const growlErrors = useErrorsGrowl();
  const { t } = useTranslation(["translation", "auditPlanner"]);
  const { isErrorUnauthorized } = useResponseErrors();

  const { profile } = shortlistItem;
  const isSoftOverbooking =
    isNumber(match?.scores.availability_score) && match.scores.availability_score !== 1;

  const confirm = () => {
    fillAndBookShortlist(shortlistItem.id)
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        growl({
          id: getGrowlId("create-temporary-booking"),
          message: t("auditPlanner:modals.temporaryBookingCreated", {
            name: getProfileName(profile)
          }),
          kind: "success",
          hidePreviousByTypeAndId: true,
          ttl: 5000
        });
      })
      .catch(({ response }) => {
        if (isErrorUnauthorized(response)) {
          growl({
            kind: "error",
            message: t("auditPlanner:modals.cannotFillRole")
          });
        } else {
          growlErrors(response);
        }
      });
  };

  return (
    <LegacyModal classes={{ modal: css.modal }} onClose={close}>
      <FillRoleContent
        engagement={engagement}
        role={role}
        profile={profile}
        shouldShowSoftOverbooking={isSoftOverbooking}
      >
        <div className={css.buttons}>
          <Button text={t("translation:close")} kind="secondary" onClick={close} />
          <Button text={t("translation:continue")} onClick={confirm} />
        </div>
      </FillRoleContent>
    </LegacyModal>
  );
};
