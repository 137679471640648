/* eslint-disable camelcase */
import { RowType } from "PFApp/booking/types/row";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useProjectsInvalidate } from "PFCore/hooks/queries/bookings/projects/use_projects_invalidate";
import { useWorkforceInvalidate } from "PFCore/hooks/queries/bookings/workforce/use_workforce_invalidate";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile";
import { useCallback } from "react";

import { CalendarMode } from "../../types";
import useRowChildrenInvalidate from "./side_panel_row/queries/use_row_children_invalidate";

/**
 * Returns function to update rows after booking has been added/removed/updated
 */

export const useSyncRows = ({ syncWorkforce = true, syncProjects = true } = {}) => {
  const { invalidate: invalidateRowChildren, invalidateByActivityIds: invalidateRowChildrenByActivities } =
    useRowChildrenInvalidate();
  const { invalidate: invalidateProjects } = useProjectsInvalidate();
  const { invalidate: invalidateWorkforce } = useWorkforceInvalidate();
  const { invalidateProfileBookings } = useProfileInvalidate();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  return useCallback(
    async ({
      activityId,
      profileId,
      parentActivityIds = []
    }: {
      activityId?: number;
      profileId?: number;
      parentActivityIds: number[];
    }) => {
      if (activityId || activityId === 0) {
        const activityIdWithParentId = [...new Set([activityId, ...parentActivityIds])];
        invalidateProjects();
        invalidateRowChildrenByActivities(activityIdWithParentId);
        invalidateActivities(activityIdWithParentId);
      }

      if (!profileId) {
        return;
      }

      invalidateWorkforce();
      invalidateRowChildren(CalendarMode.Workforce, RowType.Profile, profileId);
      invalidateProfileBookings({ id: profileId });
      invalidateProfileBookings({ id: profileId, activityId: false });
      invalidateProfileBookings({ id: profileId, activityId });
    },
    [syncWorkforce, syncProjects]
  );
};
