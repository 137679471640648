import classNames from "classnames";
import { getFilterDefault, getFilterQuery } from "PFApp/use_filtered_collection";
import { Filter } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import { RefObject } from "react";

import AnyFilter from "../any_filter";
import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import { FiltersBaseProps } from "./filters_list";
import css from "./plain_filters.module.scss";

type FiltersElementProps = {
  filter: Filter;
  onFocus: (filter: object) => void;
  onBlur: () => void;
  lastFocusedFilterNameRef: RefObject<string | null>;
  viewsKey: PageTarget | null;
  classes?: {
    list?: string;
  };
  disabled?: boolean;
} & FiltersBaseProps;

export const FilterElement = ({
  filter,
  fetchOptions,
  lastFocusedFilterNameRef,
  meta,
  onFilterChange,
  onFocus,
  onBlur,
  viewsKey,
  disabled
}: FiltersElementProps) => {
  const scope = meta?.counters?.filtered_profiles_count;
  const { setSelectedViewId } = useSavedViewsContext(viewsKey);

  const fetchOptionsWrapper = fetchOptions
    ? (props?: Record<string, any>) => fetchOptions(filter, props)
    : null;
  const query = fetchOptionsWrapper || filter.query || getFilterQuery(filter, meta);

  const defaultValue = getFilterDefault(filter, meta);

  return (
    <li
      className={classNames(css.item, filter.name && css[filter.name])}
      data-qa-id={`filters--${filter.name}`}
    >
      <AnyFilter
        scope={scope}
        /* @ts-ignore */
        filter={{ ...filter, query, defaultValue }}
        handleChange={(value, valueObject) => {
          setSelectedViewId(null);
          onFilterChange(filter, value, valueObject);
        }}
        autofocus={lastFocusedFilterNameRef.current === filter.name}
        searchId={meta?.searchId || meta?.search_id}
        handleFocus={() => onFocus(filter)}
        handleBlur={onBlur}
        disabled={disabled}
      />
    </li>
  );
};
