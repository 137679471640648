import { isEqual, map } from "lodash";
import { Option, SelectV2 } from "PFComponents/select_v2";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { humanize } from "underscore.string";

import { useNotificationsPanelContext } from "./contexts/notifications_panel_context";

type TargetOption = Option<string | string[]>;

export const NotificationsPanelFilters = () => {
  const { t } = useTranslation();

  const {
    notifications: { data },
    target,
    setTarget,
    setShowUnreadOnly
  } = useNotificationsPanelContext();

  const targetTypes = useMemo(() => data?.pages[0].meta?.targetTypes || {}, [data]);

  const handleChange = useCallback(
    (item: TargetOption) => {
      setTarget(item.value === "all" ? undefined : (item.original as unknown as string[]));
      setShowUnreadOnly(false);
    },
    [setTarget, setShowUnreadOnly]
  );

  const value = Object.keys(targetTypes).find((key) => isEqual(targetTypes[key], target)) || "all";

  const dropdownOptions: TargetOption[] = useMemo(
    () => [
      {
        id: "all",
        value: "all",
        displayElement: t("notifications.all")
      },
      ...map(targetTypes, (value, key) => ({
        id: key,
        value: key,
        displayElement: humanize(key),
        original: value
      }))
    ],
    [t, targetTypes]
  );

  return (
    <SelectV2<string | string[]>
      value={value}
      options={dropdownOptions}
      onChange={handleChange}
      multiple={false}
    />
  );
};
