import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { ProfilesViewMode } from "PFApp/activities/types/profiles_view_mode";
import { useMemo } from "react";

import { MatchTableEntry } from "../../activity_tabs.utils";
import { MatchesEmptyState } from "./matches_empty_state";
import { MatchesHeader } from "./matches_header";
import { MatchesCardsView } from "./views/matches_cards_view";
import { MatchesTableView } from "./views/matches_table_view";

export const MatchesPanel = () => {
  const { matches, allShortlists, matchesFetchState, profilesViewMode } = useActivityPageContext();

  const isLoading = !matchesFetchState || matchesFetchState === "pending";
  const hasResults = matches && matches.length > 0;

  const matchesEntries = useMemo(
    () =>
      (matches || []).map<MatchTableEntry>((match) => {
        const shortlist = allShortlists.find((shortlist) => shortlist.profile.id === match.profile.id);

        return {
          match,
          shortlist
        };
      }),
    [allShortlists, matches]
  );

  const MatchesDataComponent =
    profilesViewMode === ProfilesViewMode.Cards ? MatchesCardsView : MatchesTableView;

  return (
    <div className={commonCss.pageCardMainPanel}>
      <MatchesHeader />
      {hasResults ? (
        <MatchesDataComponent matchesEntries={matchesEntries} isLoading={isLoading} />
      ) : (
        <MatchesEmptyState isLoading={isLoading} />
      )}
    </div>
  );
};
