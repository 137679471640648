import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { ProfilesViewMode } from "PFApp/activities/types/profiles_view_mode";
import { useMemo } from "react";

import { ShortlistTableEntry } from "../../../activity_tabs.utils";
import { ShortlistEmptyState } from "./shortlist_empty_state";
import { ShortlistHeader } from "./shortlist_header";
import { useClearShortlist } from "./use_clear_shortlist";
import { ShortlistsCardsView } from "./views/shortlists_cards_view";
import { ShortlistsTableView } from "./views/shortlists_table_view";

export const ShortlistMainPanel = () => {
  const { allShortlists, rankedShortlists, isLoadingShortlists, profilesViewMode } = useActivityPageContext();
  const { handleClearShortlist, isLoading: isClearShortlistLoading } = useClearShortlist();

  const isLoading = isLoadingShortlists || isClearShortlistLoading;
  const noResults = !isLoading && !allShortlists.length;

  const shortlistsEntries = useMemo(
    () =>
      allShortlists.map<ShortlistTableEntry>((shortlist) => {
        const match = (rankedShortlists || []).find(
          (rankedShortlist) => rankedShortlist.profile.id === shortlist.profile.id
        );

        return {
          match,
          shortlist
        };
      }),
    [allShortlists, rankedShortlists]
  );

  const ShortlistDataComponent =
    profilesViewMode === ProfilesViewMode.Cards ? ShortlistsCardsView : ShortlistsTableView;

  return (
    <div className={commonCss.pageCardMainPanel}>
      <ShortlistHeader handleClearShortlist={handleClearShortlist} />
      {noResults ? (
        <ShortlistEmptyState />
      ) : (
        <ShortlistDataComponent shortlistsEntries={shortlistsEntries} isLoading={isLoading} />
      )}
    </div>
  );
};
