import { useAppContext } from "PFApp/app_context";
import { Button } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistAssign } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useActivityPageContext } from "../activity_page_context";

const AssignButton = ({ item, item: { profile, state }, isBlindModeOn }) => {
  const { t } = useTranslation("activities");

  // items.state == "assign" corresponds to "Invite" action (see: PF-3540, PF-4138)
  const hasBeenInvited = state === "assigned";
  const text = hasBeenInvited ? t("invited") : t("invite");
  const kind = hasBeenInvited ? "secondary" : "primary";
  const { dispatch } = useAppContext();
  const { task } = useActivityPageContext();
  const profileName = getProfileName(profile);
  const { mutate: assignShortlist } = useShortlistAssign();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const reviewing = task.review && task.review.state !== "complete";

  const notificationName = isBlindModeOn
    ? t("show.parts.candidateHash", { profileHash: profileHash(profile.id) })
    : profileName;

  return (
    <Button
      text={text}
      kind={kind}
      disabled={reviewing}
      qaId="shortlist-assign"
      onClick={() => {
        assignShortlist(item.id, {
          onSuccess: () => {
            invalidateActivities([task.id]);
            dispatch({
              type: GROWL_ACTIONS.GROWL_RENDER,
              payload: {
                message: t("show.parts.roleAssignedNotification", { notificationName: notificationName }),
                kind: "success"
              }
            });
          }
        });
      }}
    />
  );
};

export default AssignButton;

AssignButton.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    profile: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      id: PropTypes.number
    })
  }).isRequired,
  isBlindModeOn: PropTypes.bool
};
