import { getProfileName } from "PFCore/helpers/profile";
import { AuditRole, BasicProfile, CamelizedShortlistProfile, Engagement } from "PFTypes";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { YellowWarningIcon } from "../../yellow_warning_icon";
import css from "../modals.module.scss";

type FillRoleContentProps = PropsWithChildren<{
  engagement: Engagement;
  role: AuditRole;
  profile: BasicProfile | CamelizedShortlistProfile;
  shouldShowSoftOverbooking: boolean;
}>;

export const FillRoleContent = ({
  engagement,
  role,
  profile,
  shouldShowSoftOverbooking,
  children
}: FillRoleContentProps) => {
  const { t } = useTranslation(["translation", "auditPlanner"]);

  return (
    <div className={css.modalContent}>
      <h3 className={css.title}>{t("auditPlanner:modals.fillRole")}</h3>
      <hr className={css.separator} />
      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "nowrap" }}>
        <div>
          <div>{t("translation:engagement")}</div>
          <div className={css.bold}>{engagement.name}</div>
        </div>
        <div>
          <div>{t("translation:role")}</div>
          <div className={css.bold}>{role.name}</div>
        </div>
        <div>
          <div>{t("auditPlanner:modals.resource")}</div>
          <div className={css.bold}>{getProfileName(profile)}</div>
        </div>
      </div>
      <hr className={css.separator} />
      <div className={css.text}>
        {t("auditPlanner:modals.creatingBookingForName", { name: getProfileName(profile) })}
      </div>
      {shouldShowSoftOverbooking && (
        <>
          <hr className={css.separator} />
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <YellowWarningIcon />
            <span style={{ marginLeft: 10 }}>
              <div className={css.bold}> {t("auditPlanner:modals.overbookingDetected")}</div>
              <div className={css.text}>
                {t("auditPlanner:modals.thisWillAffectBookings", {
                  name: getProfileName(profile)
                })}
              </div>
            </span>
          </div>
        </>
      )}
      {children}
    </div>
  );
};
